import * as React from 'react';
import { GroupedData } from '../interfaces/GroupedData';
import { deleteCombinedDataById, uploadCombinedData } from '../helpers/PostToRestApi';
import { GetAllCombinedData } from '../helpers/GetFromRestApi';
import { TenantContext } from '../contexts/ContextWrapper';
import { AuthContext } from '../contexts/AuthContext';

export const useCombinedData = () => {
    const [combinedDataItems, setCombinedDataItems] = React.useState<GroupedData[]>([]);
    const [selectedCombinedData, setSelectedCombinedData] = React.useState<GroupedData | null>(null);
    const [isLoadingCombinedData, setIsLoadingCombinedData] = React.useState<boolean>(false);

    const { accessToken } = React.useContext(AuthContext)
    const { tenantContext } = React.useContext(TenantContext)

    React.useEffect(() => {
        if (accessToken && tenantContext.api) {
            getCombinedDataItems()
        }
    }, [accessToken, tenantContext.tenantName, tenantContext.api])

    const getCombinedDataItems = React.useCallback(() => {
        setIsLoadingCombinedData(true)
        GetAllCombinedData(
            accessToken!,
            tenantContext.tenantName,
            tenantContext.api,
        )
            .then((data) => {
                setCombinedDataItems(data || [])
                if (selectedCombinedData!?.ID === '') { setSelectedCombinedData(null) }
            })
            .catch(() => {
                setCombinedDataItems([])
            })
            .finally(() => {
                setIsLoadingCombinedData(false)
            })
    }, [accessToken, tenantContext.tenantName, tenantContext.api])

    const deleteCombinedDataItem = React.useCallback((id: string) => {
        setIsLoadingCombinedData(true)
        deleteCombinedDataById(
            accessToken!,
            id,
            tenantContext.tenantName,
            tenantContext.api,
        )
            .finally(() => {
                getCombinedDataItems()
                setIsLoadingCombinedData(false)
            })
    }, [accessToken, tenantContext.tenantName, tenantContext.api])

    const updateCombinedDataItem = React.useCallback((item: GroupedData) => {
        setIsLoadingCombinedData(true)
        uploadCombinedData(accessToken!, tenantContext.tenantName, tenantContext.api, item)
            .then((newItem) => {
                setSelectedCombinedData(newItem.data as GroupedData)
                getCombinedDataItems()
            })
            .catch(() => {
                getCombinedDataItems()
            })
            .finally(() => {
                setIsLoadingCombinedData(false)
            })
    }, [accessToken, tenantContext.tenantName, tenantContext.api])


    return {
        combinedDataItems,
        setCombinedDataItems,
        selectedCombinedData,
        setSelectedCombinedData,
        isLoadingCombinedData,
        getCombinedDataItems,
        deleteCombinedDataItem,
        updateCombinedDataItem,
    }

}
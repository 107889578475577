import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox, DefaultButton, Stack, TextField, Toggle } from '@fluentui/react';
import Axios from 'axios';
import { GetManifestFile, GetManifestItems, GetOutlookManifestFile } from '../../helpers/GetFromRestApi';
import { uploadManifestIcon } from '../../helpers/PostToRestApi';
import { ExcelTheme, PowerPointTheme, WordTheme } from '../../helpers/Themes';
import { DialogUploadManifestIcon } from '../dialogs/DialogUploadManifestIcon';
import SettingsCompany from '../../helpers/SettingsCompany';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import { ManifestItem } from '../../interfaces/ManifestItem';
import { UploadFile } from '../../interfaces/UploadFile';
import SettingsManifestPreview from './SettingsManifestPreview';

export interface SettingsManifestProps {
	updateSettings: () => Promise<void>;
	setEditedSettings: (x: SettingsCompany) => void;
	editedSettings: SettingsCompany;
	userData: UserAdminItem;
	accessToken: string;
	domain: string;
	api: string;
	isSaved: boolean;
	outlook: boolean;
}

interface ManifestPrograms {
	excel: { include: boolean; enabled: boolean };
	word: { include: boolean; enabled: boolean };
	powerpoint: { include: boolean; enabled: boolean };
}

const SettingsManifest: React.FC<SettingsManifestProps> = ({
	updateSettings,
	setEditedSettings,
	editedSettings,
	accessToken,
	domain,
	api,
	isSaved,
	outlook: isOutlook,
}) => {
	const [manifestItems, setManifestItems] = useState<ManifestItem[]>([]);
	const [updateVersion, setUpdateVersion] = useState<boolean>(false);
	const [hideUploadDialog, setHideUploadDialog] = useState<boolean>(true);
	const [uploadConfirmed, setUploadConfirmed] = useState<boolean>(false);
	const [uploadItems, setUploadItems] = useState<UploadFile[]>([]);
	const [programSettings, setProgramSettings] = useState<ManifestPrograms>({
		excel: { include: false, enabled: false },
		word: { include: false, enabled: false },
		powerpoint: { include: false, enabled: false },
	});

	const ourRequest = Axios.CancelToken.source();

	const getItems = useCallback(async () => {
		try {
			const items = await GetManifestItems(accessToken, domain, api, isOutlook, ourRequest.token);
			setManifestItems(items);
			setEditedSettings({ ...editedSettings, ...(isOutlook ? { ManifestOutlook: items } : { Manifest: items }) });
		} catch {
			setManifestItems([{ Title: 'No items', Text: '', CheckText: '', Mandatory: false, Locked: true }]);
		}
	}, [accessToken, domain, api, isOutlook]);

	const checkProgramsInSettings = useCallback(() => {
		const programStates = {
			excel: { include: false, enabled: false },
			word: { include: false, enabled: false },
			powerpoint: { include: false, enabled: false },
		};

		editedSettings?.Extensions?.forEach((extension) => {
			if (extension.ExcelDesktop || extension.ExcelOnline) programStates.excel.include = true;
			if (extension.WordDesktop || extension.WordOnline) programStates.word.include = true;
			if (extension.PowerPointDesktop || extension.PowerPointOnline) programStates.powerpoint.include = true;
			if (extension.ActivateExcel) programStates.excel.enabled = true;
			if (extension.ActivateWord) programStates.word.enabled = true;
			if (extension.ActivatePowerPoint) programStates.powerpoint.enabled = true;
		});

		setProgramSettings(programStates);
	}, [editedSettings]);

	useEffect(() => {
		if (!manifestItems || manifestItems!?.length === 0) {
			getItems().then(checkProgramsInSettings)
		}
		else {
			setManifestItems(isOutlook ? editedSettings.ManifestOutlook : editedSettings.Manifest);
		if (!isOutlook) checkProgramsInSettings()
		}		
		return () => ourRequest.cancel();
	}, []);

	useEffect(() => {
		if (uploadConfirmed) {
			uploadFile();
		}
	}, [uploadConfirmed]);

	const getManifest = async () => {
		const xmlData: string =
			isOutlook ?
				await GetOutlookManifestFile(
					accessToken,
					domain,
					api,
					updateVersion,
					ourRequest.token
				) :
				await GetManifestFile(
					accessToken,
					domain,
					api,
					programSettings.excel.include,
					programSettings.powerpoint.include,
					programSettings.word.include,
					updateVersion,
					ourRequest.token
				);

		const parser = new DOMParser();
		const xmlDoc = parser.parseFromString(xmlData, "application/xml");
		const versionElement = xmlDoc.getElementsByTagName("Version")[0];
		const versionValue = versionElement ? versionElement.textContent : "";

		const versionItem = manifestItems.find((item) => item.Title === 'Version');
		if (versionItem) {
			versionItem.Text = versionValue!;
			setManifestItems([...manifestItems, versionItem]);
			setEditedSettings({ ...editedSettings, ...(isOutlook ? { ManifestOutlook: manifestItems } : { Manifest: manifestItems }) });
		}

		const anchor = document.createElement('a');
		anchor.href = 'data:text/xml;charset=utf-8,' + encodeURIComponent(xmlData);
		anchor.download = `365te-${editedSettings.CompanyName.replace(/ /g, '-').toLowerCase()}-${isOutlook ? 'outlook' : 'office'}${versionValue ? '-' + versionValue : ''}-manifest.xml`
		anchor.click();
	};

	const downloadEnabled = useCallback(() => {
		if (!isSaved) return false;
		return manifestItems.every((item) => !item.Mandatory || item.Text !== '');
	}, [isSaved, manifestItems]);

	const onChangeManifestField = useCallback((item: ManifestItem) => {
		const updatedItems = [...manifestItems];
		console.log('manifestItems', manifestItems);
		console.log('updatedItems', updatedItems);
		const itemIndex = updatedItems.findIndex((val) => val.Title === item.Title);
		if (itemIndex !== -1) {
			updatedItems[itemIndex] = item;
			setManifestItems(updatedItems);
			console.log('updatedItems', { ...editedSettings, ...(isOutlook ? { ManifestOutlook: updatedItems } : { Manifest: updatedItems }) });
			setEditedSettings({ ...editedSettings, ...(isOutlook ? { ManifestOutlook: updatedItems } : { Manifest: updatedItems }) });
		}
	}, [manifestItems, setEditedSettings, editedSettings, isOutlook]);

	const uploadFile = async () => {
		for (const uploadItem of uploadItems) {
			try {
				// const response = await uploadManifestIcon(accessToken, domain, api, uploadItem.data, isOutlook);
				// if (response.status === 200) {
				// 	setUploadConfirmed(false);
				// 	await updateSettings();
				// }
				uploadManifestIcon(accessToken, domain, api, uploadItem.data, isOutlook)
					.then(async (response) => {
						if (response.status === 200) {
							updateSettings();
							setEditedSettings({ ...editedSettings, ...response.data });
						}
					})
					.finally(() => {
						setUploadConfirmed(false)
					});
			} catch (error) {
				console.error('File upload failed', error);
			}
		}
	};

	return (
		manifestItems!?.length > 0 ?
			<>
				<Stack tokens={{ childrenGap: 10 }} >
					<Stack.Item>
						{isOutlook ?
							<Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='center'>
								<img
									src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/outlook_32x1.svg"
									width="32"
									height="32"
									alt="Outlook product icon"
								/>
							</Stack>
							:
							<Stack horizontal tokens={{ childrenGap: 20 }} verticalAlign='center'>
								<img
									src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/word_32x1.svg"
									width="32"
									height="32"
									alt="Word product icon"
								/>
								<Checkbox
									ariaLabel='Include in Word'
									theme={WordTheme}
									disabled={!programSettings.word.enabled}
									checked={programSettings.word.include}
									onChange={(_ev, checked) =>
										setProgramSettings({ ...programSettings, word: { ...programSettings.word, include: checked! } })
									}
								/>
								<div style={{ width: '10px' }} />
								<img
									src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/powerpoint_32x1.svg"
									width="32"
									height="32"
									alt="PowerPoint product icon"
								/>
								<Checkbox
									ariaLabel='Include in PowerPoint'
									theme={PowerPointTheme}
									disabled={!programSettings.powerpoint.enabled}
									checked={programSettings.powerpoint.include}
									onChange={(_ev, checked) =>
										setProgramSettings({ ...programSettings, powerpoint: { ...programSettings.powerpoint, include: checked! } })
									}
								/>
								<div style={{ width: '10px' }} />
								<img
									src="https://res-1.cdn.office.net/files/fabric-cdn-prod_20230815.002/assets/brand-icons/product/svg/excel_32x1.svg"
									width="32"
									height="32"
									alt="Excel product icon"
								/>
								<Checkbox
									ariaLabel='Include in Excel'
									theme={ExcelTheme}
									disabled={!programSettings.excel.enabled}
									checked={programSettings.excel.include}
									onChange={(_ev, checked) =>
										setProgramSettings({ ...programSettings, excel: { ...programSettings.excel, include: checked! } })
									}
								/>
							</Stack>}
					</Stack.Item>
					<Stack.Item>
						<Toggle
							label='Update manifest version'
							inlineLabel
							onText="Yes"
							offText="No"
							checked={updateVersion}
							onChange={(_ev, checked) => setUpdateVersion(checked!)}
						/>
					</Stack.Item>
					<Stack.Item>
						<DefaultButton
							key='download'
							text={isOutlook ? 'Download Outlook manifest' : 'Download manifest'}
							primary
							iconProps={{ iconName: 'Download' }}
							disabled={!downloadEnabled()}
							onClick={() => {
								getManifest()
							}}
						/>
					</Stack.Item>
				</Stack>
				<Stack horizontal tokens={{ childrenGap: 20 }} >
					<Stack.Item grow styles={{ root: { maxWidth: '500px' } }} >
						<Stack tokens={{ childrenGap: 5 }} >

							{manifestItems
								.map((item: ManifestItem, idx: number, _arr: ManifestItem[]) => {
									return (
										<Stack.Item key={idx}>
											<TextField
												label={item.Title}
												value={item.Text}
												required={item.Mandatory}
												disabled={item.Locked}
												onChange={(_ev, value) => {
													onChangeManifestField({ ...item, Text: value! })
												}}
												errorMessage={item.Mandatory && !item.Text ? 'Field must be filled out' : ''}
											/>
										</Stack.Item>
									)
								})}
							<Stack.Item align='start' styles={{ root: { paddingTop: '20px' } }}>
								<DefaultButton
									key='upload'
									text='Upload Custom Icon'
									primary
									iconProps={{ iconName: 'Upload' }}
									onClick={() => { setHideUploadDialog(false) }}
								/>
							</Stack.Item>
						</Stack>
					</Stack.Item>
					<Stack.Item>
						<SettingsManifestPreview
							// iconSrc='https://365templateextender.azurewebsites.net/assets/icon-32.png'
							iconSrc={(isOutlook ? editedSettings.RibbonOutlookIcon32 : editedSettings.RibbonIcon32) + '?random=' + new Date().getTime()}
							manifestItems={manifestItems}
						/>
					</Stack.Item>
				</Stack >

				<DialogUploadManifestIcon
					setParentState={({ hideUploadDialog, uploadConfirmed, uploadItems }) => {
						setHideUploadDialog(hideUploadDialog);
						setUploadConfirmed(uploadConfirmed);
						setUploadItems(uploadItems);
					}}
					hidden={hideUploadDialog}
					hideState="hideUploadDialog"
					confirmState="uploadConfirmed"
					uploadItemState="uploadItems"
				/>
			</>
			: null
	)
};

export default SettingsManifest;

import * as React from 'react';
import { Stack, Text } from '@fluentui/react';
import { ContentFrame } from '../common/ContentFrame';
import NewsHome from './NewsHome';
import ExtensionsOverview from './ExtensionsOverview';
import { TenantContext } from '../../contexts/ContextWrapper';
import HomeStatistics from './HomeStatistics';
import FunctionsOverview from './FunctionsOverview';
import DiscoverMore from './DiscoverMore';



const HomeTenant: React.FC = () => {

	const { tenantContext } = React.useContext(TenantContext);

	if (!tenantContext.tenantName || !tenantContext.api) {
		return null
	}

	return (
		<Stack >
			<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
				<Text variant="xxLarge">
					Home
				</Text>
			</Stack.Item>
			<ContentFrame>
				<Stack>
					<Stack horizontal wrap>
						<HomeStatistics />
						<ExtensionsOverview />
						<FunctionsOverview />
					</Stack>
					<NewsHome />
					<DiscoverMore />
					{/* <Stack.Item styles={{ root: { padding: 20, maxHeight: 350 } }}>
						<Stack tokens={{ childrenGap: '5px' }}>
							<Text variant="xLarge">Available extensions</Text>
							<ExtensionsHome />
						</Stack>
					</Stack.Item>
					<Stack.Item styles={{ root: { padding: 20, maxHeight: 350 } }}>
						<Stack tokens={{ childrenGap: '5px' }}>
							<Text variant="xLarge">Available functions</Text>
							<FunctionsHome />
						</Stack>
					</Stack.Item> */}
				</Stack>
			</ContentFrame>
		</Stack>
	)
}

export default HomeTenant;
import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { ContentFolder } from '../interfaces/ContentFolder';
import { TemplateApiHeaders } from '../interfaces/TemplateApiHeaders';
import { apiUris } from './SettingsMisc';
import { TemplateListItem } from '../interfaces/TemplateListItem';
import { UserInfoItem } from './SettingsCompany';
import { GroupedData } from '../interfaces/GroupedData';
import { SectionsDocument } from '../interfaces/SectionsDocument';
import { AcademyItem } from '../interfaces/AcademyItem';

const postStringDataToApi = async (url: string, method: AxiosRequestConfig["method"], accessToken: string, domain: string, api: string, data: string, cancelToken?: CancelToken, isNew?: boolean, header?: any) => {
	return postJsonDataToApi(url, method, accessToken, domain, api, JSON.stringify(data), cancelToken!, isNew, header)
}

const postJsonDataToApi = async (url: string, method: AxiosRequestConfig["method"], accessToken: string, domain: string, api: string, data: any, cancelToken?: CancelToken, isNew?: boolean, header?: any) => {
	let stringData = JSON.stringify(data);
	console.log(`UploadData: Uploading ${stringData.length} byte(s)..`);

	const tokenObject = isNew ? { 'NEW-TOKEN': accessToken } : { 'MY-TOKEN': accessToken }
	const response = await axios({
		url: url,
		method: method,
		headers: {
			...header,
			...tokenObject,
			'Authorization': 'Bearer ' + accessToken,
			'Domain': domain,
			'API': api,
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
		// data: stringData,
		data: data,
		maxBodyLength: 1073741824,
		maxContentLength: 1073741824,
		cancelToken: cancelToken!,
	});
	return response;
};

const apiOperation = async (url: string, method: AxiosRequestConfig["method"], accessToken: string, domain: string, api: string, cancelToken?: CancelToken, data?: string, header?: any) => {
	console.log(`SendOperation: ${method} UrlLength: ${url.length}`);
	const response = await axios({
		url: url,
		method: method,
		headers: {
			...header,
			'Authorization': 'Bearer ' + accessToken,
			'Domain': domain,
			'API': api,
		},
		data: JSON.stringify(data),
		cancelToken: cancelToken!,
	});
	return response;
};

// export const newDomainSettings = async (accessToken: string, domain: string, api: string, myData: string, cancelToken?: CancelToken) => {

// 	//`{"Domain":"${domain}","CompanyName":"${companyName}","ExcelSettings":{},"PowerPointSettings":{},"WordSettings":{},"UserInformation":{}}`
// 	return postDataToApi(`${apiUris(api)}admin_products`, 'POST', accessToken, domain, api, myData, cancelToken!, true)
// 		.then(async (response) => {
// 			return (response)
// 		})
// 		.catch((requestError) => {
// 			return (requestError.response)
// 		});
// };

export const updateDomainSettings = async (accessToken: string, domain: string, api: string, myData: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_products`, 'PUT', accessToken, domain, api, myData, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const updateOldSettings = async (accessToken: string, domain: string, api: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_products?CheckUpdate=True`, 'PUT', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const uploadManifestIcon = async (accessToken: string, domain: string, api: string, myData: string, isOutlookManifest: boolean, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_products?RibbonIcon=True`, 'POST', accessToken, domain, api, myData, cancelToken!, false, { 'IS-OUTLOOK-MANIFEST':  isOutlookManifest})
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

// export async function GetSettingsUpdateAvailable(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<Extension[]> {
// 	let extensions: Extension[] = await GetData(`${apiUris(api)}admin_products?CheckUpdate=True`, { 'MY-TOKEN': accessToken }, true, cancelToken!) || [];
// 	return extensions;
// }


export const updatePictureLibraries = async (accessToken: string, domain: string, api: string, myData: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_pictures`, 'POST', accessToken, domain, api, myData, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//Old Templates
// export const uploadTemplate = async (accessToken: string, domain: string, api: string, fileName: string, data: string, cancelToken?: CancelToken) => {

// 	return postDataToApi( `${apiUris(api)}admin_templates?filename=${fileName}`, 'POST', accessToken, domain, api, data, cancelToken!)
// 		.then(async (response) => {
// 			return(response)
// 		})
// 		.catch ( (requestError) => {
// 			return(requestError.response)
// 		});
// };

// export const deleteTemplate = async (accessToken: string, domain: string, api: string, id: string, cancelToken?: CancelToken) => {

// 	return apiOperation( `${apiUris(api)}admin_templates/${id}`, 'DELETE', accessToken, domain, api, cancelToken!)
// 		.then(async (response) => {
// 			return(response)
// 		})
// 		.catch ( (requestError) => {
// 			return(requestError.response)
// 		});
// };
// //End of Old Templates

//New Templates
export const createTemplateFolder = async (accessToken: string, domain: string, api: string, data: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_templates`, 'POST', accessToken, domain, api, data, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteTemplateFolder = async (accessToken: string, domain: string, api: string, path: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_templates?path=${path}`, 'DELETE', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const updateTemplates = async (accessToken: string, domain: string, api: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_templates`, 'PUT', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const publishTemplates = async (accessToken: string, domain: string, api: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_templates?publish=true`, 'PUT', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const putTemplateSettings = async (accessToken: string, domain: string, api: string, filePath: string, data: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_templates?filepath=${filePath}`, 'PUT', accessToken, domain, api, cancelToken!, data)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const uploadTemplate = async (accessToken: string, domain: string, api: string, fileName: string, path: string, data: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_templates?filename=${fileName}&path=${path}`, 'POST', accessToken, domain, api, data, cancelToken!, false, { 'TEMPLATES-VERSION': 'TEST' })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const updateTemplate = async (accessToken: string, domain: string, api: string, template: TemplateListItem, newRoot: string, updatePub: boolean, cancelToken?: CancelToken) => {

	return postJsonDataToApi(`${apiUris(api)}admin_templates?updateTemplate=true`, 'PUT', accessToken, domain, api, template, cancelToken!, false,
		{
			'TEMPLATES-VERSION': 'TEST',
			'UPDATE-PUB': updatePub ? 'TRUE' : 'FALSE',
			'NEW-ROOT': newRoot
		})
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const publishTemplate = async (accessToken: string, domain: string, api: string, template: TemplateListItem, cancelToken?: CancelToken) => {

	return postJsonDataToApi(`${apiUris(api)}admin_templates?publishTemplate=true`, 'PUT', accessToken, domain, api, template, cancelToken!, false, { 'TEMPLATES-VERSION': 'TEST' })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteTemplate = async (accessToken: string, domain: string, api: string, id: string, headers: TemplateApiHeaders, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_templates?id=${id}`, 'DELETE', accessToken, domain, api, cancelToken!, '',
		{
			'TEMPLATES-VERSION': headers.templateVersion,
			'TEMPLATES-TYPE': headers.templateType,
			'TEMPLATES-ENVIRON': headers.templateEnviron,
			'MY-GROUPS': headers.myGroups,
			'GROUP-SELECTION': headers.groupSelection,
		}
	)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//Pictures
export const uploadPicture = async (accessToken: string, domain: string, api: string, fileName: string, data: string, imgSrcPath: string, libraryPath: string, previewWidth: number, previewHeight: number, previewBigWidth: number, previewBigHeight: number, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_pictures?FileName=${fileName}&ImgSrc=${imgSrcPath}&libraryName=${libraryPath}&previewWidth=${previewWidth}&previewHeight=${previewHeight}&previewBigWidth=${previewBigWidth}&previewBigHeight=${previewBigHeight}`, 'POST', accessToken, domain, api, data, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const updatePictureMeta = async (accessToken: string, domain: string, api: string, imgPath: string, myData: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_pictures?imgPath=${imgPath}`, 'PUT', accessToken, domain, api, myData, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deletePicture = async (accessToken: string, domain: string, api: string, imgPath: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_pictures?imgPath=${imgPath}`, 'DELETE', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deletePictureFolder = async (accessToken: string, domain: string, api: string, path: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_pictures?Path=${path}`, 'DELETE', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const updatePictures = async (accessToken: string, domain: string, api: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_pictures?UpdatePics=true`, 'PUT', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//Logo
export const uploadLogo = async (accessToken: string, domain: string, api: string, data: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_products?Logo=true`, 'POST', accessToken, domain, api, data, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//UserDetails
export const uploadUserdetails = async (accessToken: string, domain: string, api: string, userDataList: UserInfoItem[], cancelToken?: CancelToken) => {

	return postJsonDataToApi(`${apiUris(api)}admin_userdata`, 'POST', accessToken, domain, api, userDataList, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteUserdetails = async (accessToken: string, domain: string, api: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_userdata`, 'DELETE', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//CombinedFields
export const uploadCombinedData = async (accessToken: string, domain: string, api: string, groupedDataItem: GroupedData, cancelToken?: CancelToken) => {

	return postJsonDataToApi(`${apiUris(api)}admin_userdata?groupedData=true`, 'POST', accessToken, domain, api, groupedDataItem, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteCombinedDataById = async (accessToken: string, id: string, domain: string, api: string, cancelToken?: CancelToken) => {

	return postJsonDataToApi(`${apiUris(api)}admin_userdata?groupedData=true&id=${id}`, 'DELETE', accessToken, domain, api, '', cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};


//Icons
export const uploadIconsFile = async (accessToken: string, domain: string, api: string, fileName: string, data: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_icons?filename=${fileName}`, 'POST', accessToken, domain, api, data, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteIconsFile = async (accessToken: string, domain: string, api: string, fileName: string, templateVersion: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_icons?filename=${fileName}`, 'DELETE', accessToken, domain, api, cancelToken!, '', { 'TEMPLATES-VERSION': templateVersion })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const publishIconsFiles = async (accessToken: string, domain: string, api: string, fileName: string, cancelToken?: CancelToken, publishAll?: boolean) => {

	if (publishAll) { fileName = 'ALL' }
	return apiOperation(`${apiUris(api)}admin_icons?fileName=${fileName}`, 'PUT', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const updateOldIconsFiles = async (accessToken: string, domain: string, api: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_icons?Upgrade=true`, 'PUT', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//Slides
export const uploadPresentationFile = async (accessToken: string, domain: string, api: string, fileName: string, data: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_slides?filename=${fileName}`, 'POST', accessToken, domain, api, data, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const updatePresentationFile = async (accessToken: string, domain: string, api: string, presentationName: string, templateVersion: string, data: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_slides?presentation=${presentationName}`, 'PUT', accessToken, domain, api, data, cancelToken!, false, { 'TEMPLATES-VERSION': templateVersion })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const updatePresentationPreviews = async (accessToken: string, domain: string, api: string, presentationName: string, templateVersion: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_slides?updateSlides=true&filename=${presentationName}`, 'PUT', accessToken, domain, api, '', cancelToken!, false, { 'TEMPLATES-VERSION': templateVersion })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deletePresentationFile = async (accessToken: string, domain: string, api: string, fileName: string, templateVersion: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_slides?filename=${fileName}`, 'DELETE', accessToken, domain, api, cancelToken!, '', { 'TEMPLATES-VERSION': templateVersion })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const publishPresentationFiles = async (accessToken: string, domain: string, api: string, fileName: string, cancelToken?: CancelToken, publishAll?: boolean) => {

	if (publishAll) { fileName = 'ALL' }
	return apiOperation(`${apiUris(api)}admin_slides?fileName=${fileName}`, 'PUT', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//Word Content
export const uploadWordContentFolder = async (accessToken: string, domain: string, api: string, data: ContentFolder, templateVersion: string, hashedUser: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_wordcontents?folder=true`, 'POST', accessToken, domain, api, JSON.stringify(data), cancelToken!, false, { 'MY-USER': hashedUser, 'MY-TOKEN': accessToken, 'TEMPLATES-VERSION': templateVersion, })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteWordContentFolder = async (accessToken: string, domain: string, api: string, pathId: string, templateVersion: string, hashedUser: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_wordcontents?pathID=${pathId}`, 'DELETE', accessToken, domain, api, cancelToken!, '', { 'MY-USER': hashedUser, 'MY-TOKEN': accessToken, 'TEMPLATES-VERSION': templateVersion, })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const uploadWordContent = async (accessToken: string, domain: string, api: string, contentId: string, fileName: string, data: string, folder: string, templateVersion: string, hashedUser: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_wordcontents?ContentID=${contentId}&Name=${fileName}&PathID=${folder}`, 'POST', accessToken, domain, api, data, cancelToken!, false, { 'MY-USER': hashedUser, 'MY-TOKEN': accessToken, 'TEMPLATES-VERSION': templateVersion, })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteWordContent = async (accessToken: string, domain: string, api: string, contentId: string, templateVersion: string, hashedUser: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_wordcontents?ContentID=${contentId}`, 'DELETE', accessToken, domain, api, cancelToken!, '', { 'MY-USER': hashedUser, 'MY-TOKEN': accessToken, 'TEMPLATES-VERSION': templateVersion, })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const publishWordContent = async (accessToken: string, domain: string, api: string, contentId: string, templateVersion: string, hashedUser: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_wordcontents?ContentID=${contentId}`, 'PUT', accessToken, domain, api, cancelToken!, '', { 'MY-USER': hashedUser, 'MY-TOKEN': accessToken, 'TEMPLATES-VERSION': templateVersion, })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const publishAllWordContent = async (accessToken: string, domain: string, api: string, templateVersion: string, hashedUser: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_wordcontents`, 'PUT', accessToken, domain, api, cancelToken!, '', { 'MY-USER': hashedUser, 'MY-TOKEN': accessToken, 'TEMPLATES-VERSION': templateVersion, })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//Groups
export const uploadGroup = async (accessToken: string, domain: string, api: string, myData: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_groups`, 'POST', accessToken, domain, api, myData, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const updateGroups = async (accessToken: string, domain: string, api: string, myData: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_groups`, 'PUT', accessToken, domain, api, myData, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteGroups = async (accessToken: string, domain: string, api: string, id: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_groups?groupId=${id}`, 'DELETE', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//CustomData
export const uploadCustomData = async (accessToken: string, domain: string, api: string, myData: string, parentId: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_customdata?parentguid=${parentId}`, 'POST', accessToken, domain, api, myData, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const uploadCustomDataDef = async (accessToken: string, domain: string, api: string, myData: string, cancelToken?: CancelToken) => {

	return postStringDataToApi(`${apiUris(api)}admin_customdata?def=true`, 'POST', accessToken, domain, api, myData, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteCustomDataField = async (accessToken: string, domain: string, api: string, id: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_customdata?dataid=${id}`, 'DELETE', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//Sections
export const uploadSectionsDocument = async (accessToken: string, filename: string, domain: string, api: string, myData: string, templateVersion: string, headingLevels: string, checkAll: boolean, hideIndex: boolean, ignoreHierarchy: boolean, cancelToken?: CancelToken) => {
	return postStringDataToApi(`${apiUris(api)}admin_docbuilder?filename=${filename}`, 'POST', accessToken, domain, api, myData, cancelToken!, false,
		{
			'TEMPLATES-VERSION': templateVersion,
			'USEDHEADINGLEVELS': headingLevels,
			'IGNOREHIERARCHY': ignoreHierarchy ? 'TRUE' : 'FALSE',
			'HIDEINDEX': hideIndex ? 'TRUE' : 'FALSE',
			'CHECKALL': checkAll ? 'TRUE' : 'FALSE',
		})
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const updateSectionsDocument = async (accessToken: string, domain: string, api: string, templateVersion: string, item: SectionsDocument, cancelToken?: CancelToken): Promise<SectionsDocument> => {
	const doc: Promise<SectionsDocument> = apiOperation(`${apiUris(api)}admin_docbuilder?filename=${item.DocName}&updateDocument=true`, 'PUT', accessToken, domain, api, cancelToken!, '',
		{
			'TEMPLATES-VERSION': templateVersion,
			'USEDHEADINGLEVELS': item.UsedHeadingLevels.join(','),
			'IGNOREHIERARCHY': item.IgnoreHierarchy ? 'TRUE' : 'FALSE',
			'HIDEINDEX': item.HideIndex ? 'TRUE' : 'FALSE',
			'CHECKALL': item.CheckAll ? 'TRUE' : 'FALSE',
		})
		.then(async (response) => {
			return (response.data)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
	return doc;
};

export const publishSectionsAllDocument = async (accessToken: string, domain: string, api: string, cancelToken?: CancelToken) => {
	return publishSectionsDocument(accessToken, 'ALL', domain, api, cancelToken)
}

export const publishSectionsDocument = async (accessToken: string, filename: string, domain: string, api: string, cancelToken?: CancelToken) => {
	return apiOperation(`${apiUris(api)}admin_docbuilder?filename=${filename}`, 'PUT', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteSectionsDocument = async (accessToken: string, filename: string, domain: string, api: string, templateVersion: string, cancelToken?: CancelToken) => {
	return apiOperation(`${apiUris(api)}admin_docbuilder?filename=${filename}`, 'DELETE', accessToken, domain, api, cancelToken!, '', { 'TEMPLATES-VERSION': templateVersion })
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//Emailsignatures
export const uploadEmailsignature = async (accessToken: string, domain: string, api: string, myData: string, cancelToken?: CancelToken) => {
	return postStringDataToApi(`${apiUris(api)}admin_emailsignatures`, 'POST', accessToken, domain, api, myData, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const publishEmailsignature = async (accessToken: string, domain: string, api: string, publishAll: boolean, signatureName?: string, cancelToken?: CancelToken) => {
	const newName = publishAll ? 'ALL' : signatureName
	return apiOperation(`${apiUris(api)}admin_emailsignatures?emailsignaturename=${newName}`, 'PUT', accessToken, domain, api, cancelToken!, '', {})
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteEmailsignature = async (accessToken: string, domain: string, api: string, signatureName: string, deleteUnusedPictures: boolean, signatureVersion: string, cancelToken?: CancelToken) => {
	return apiOperation(`${apiUris(api)}admin_emailsignatures?emailsignaturename=${signatureName}`, 'DELETE', accessToken, domain, api, cancelToken!, '',
		{
			'EMAIL-SIGNATURE-VERSION': signatureVersion,
			'DELETE-UNUSED-PICTURES': deleteUnusedPictures
		})
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const uploadEmailsignaturePicture = async (accessToken: string, domain: string, api: string, myData: string, cancelToken?: CancelToken) => {
	//EmbeddedPictureItem
	return postStringDataToApi(`${apiUris(api)}admin_emailsignatures?picture=true`, 'POST', accessToken, domain, api, myData, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteEmailsignaturePicture = async (accessToken: string, domain: string, api: string, PictureName: string, signatureVersion: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_emailsignatures?picture=true&PictureName=${PictureName}`, 'DELETE', accessToken, domain, api, cancelToken!, '', {
		'EMAIL-SIGNATURE-VERSION': signatureVersion,
	})
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

//Academy
export const uploadAcademyItem = async (accessToken: string, domain: string, api: string, myData: AcademyItem, cancelToken?: CancelToken) => {
	//EmbeddedPictureItem
	return postJsonDataToApi(`${apiUris(api)}admin_academy`, 'POST', accessToken, domain, api, myData, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const uploadAcademyImage = async (accessToken: string, domain: string, api: string, AcademyId: string, language: string, myData: string, cancelToken?: CancelToken) => {
	//EmbeddedPictureItem
	return postStringDataToApi(`${apiUris(api)}admin_academy?image=true&academyid=${AcademyId}&language=${language}`, 'POST', accessToken, domain, api, myData, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteAcademyItem = async (accessToken: string, domain: string, api: string, AcademyId: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_academy?academyId=${AcademyId}`, 'DELETE', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};

export const deleteAcademyImage = async (accessToken: string, domain: string, api: string, AcademyId: string, language: string, cancelToken?: CancelToken) => {

	return apiOperation(`${apiUris(api)}admin_academy?image=true&academyid=${AcademyId}&language=${language}`, 'DELETE', accessToken, domain, api, cancelToken!)
		.then(async (response) => {
			return (response)
		})
		.catch((requestError) => {
			return (requestError.response)
		});
};
import React 	from 'react';
import { Checkbox, IconButton, Stack, TextField, Toggle } from '@fluentui/react';
import SettingsCompany from '../../helpers/SettingsCompany';
import { ExtensionItem } from '../../interfaces/ExtensionItem';
import { ExtraField } from '../../interfaces/ExtraField';
import { ExcelTheme, OutlookTheme, PowerPointTheme, WordTheme } from '../../helpers/Themes';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import { getTooltipFromString } from '../../helpers/Descriptions';

export interface SettingsExtensionsProps {
	setEditedSettings: (x: SettingsCompany) => void;
	editedSettings: SettingsCompany;
	userData: UserAdminItem;
}

const SettingsExtensions: React.FC<SettingsExtensionsProps> = (props) => {

	const onChangeSettings = (item: ExtensionItem, positionChange: number = 0): void => {
		console.log('onChangeSettings')
		let extensions: ExtensionItem[] = props.editedSettings.Extensions

		extensions = extensions.map((val, idx) => {
			val.ExtensionOrder = idx + 1
			return val
		})

		if (positionChange !== 0) {
			const oldOrder: number = item.ExtensionOrder
			let swapItem: ExtensionItem | undefined = extensions.find(val => val.ExtensionOrder === oldOrder + positionChange)
			if (swapItem) {
				// swapItem = Object.assign(swapItem, { ExtensionOrder: oldOrder, AnimationMotion: 'ms-motion-slideUpIn' })
				// item = Object.assign(item, { ExtensionOrder: oldOrder + positionChange, AnimationMotion: 'ms-motion-slideDownIn' })
				swapItem = Object.assign(swapItem, { ExtensionOrder: oldOrder })
				item = Object.assign(item, { ExtensionOrder: oldOrder + positionChange })
			}
		}

		extensions = extensions.sort((a, b) => a.ExtensionOrder < b.ExtensionOrder ? -1 : a.ExtensionOrder > b.ExtensionOrder ? 1 : 0)

		props.setEditedSettings(Object.assign(props.editedSettings, { Extensions: extensions }))
	}

	const onChangeField = (item: ExtensionItem, field: ExtraField): void => {
		let fields: ExtraField[] = item.ExtraFields
		fields[fields.findIndex(val => val.Title === field.Title)] = field

		let extensions: ExtensionItem[] = props.editedSettings.Extensions
		extensions[extensions.findIndex(val => val.ExtensionName === item.ExtensionName)] = Object.assign(item, { ExtraFields: fields })
		props.setEditedSettings(Object.assign(props.editedSettings, { Extensions: extensions }))
	}

	return (
		props.editedSettings.Extensions!.length > 0 ? (
			<Stack >
				<table className='SettingsTable'>
					<thead>
						<tr style={{ borderBottom: '0px' }}>
							<th colSpan={2}></th>
							<th colSpan={2}>
								<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }} styles={{ root: { justifyContent: 'center', paddingBottom: 5, borderBottom: '2px solid #2b579a' } }}>
									<div className="ms-BrandIcon--icon48 ms-BrandIcon--word ProgramIcon48"></div>
									<Stack.Item style={{ color: '#2b579a', fontSize: '16px' }}>Word</Stack.Item>
								</Stack>
							</th>
							<th />
							<th colSpan={2}>
								<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }} styles={{ root: { justifyContent: 'center', paddingBottom: 5, borderBottom: '2px solid #b7472a' } }}>
									<div className="ms-BrandIcon--icon48 ms-BrandIcon--powerpoint ProgramIcon48"></div>
									<Stack.Item style={{ color: '#b7472a', fontSize: '16px' }}>Power&shy;Point</Stack.Item>
								</Stack>
							</th>
							<th />
							<th colSpan={2}>
								<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }} styles={{ root: { justifyContent: 'center', paddingBottom: 5, borderBottom: '2px solid #217346' } }}>
									<div className="ms-BrandIcon--icon48 ms-BrandIcon--excel ProgramIcon48"></div>
									<Stack.Item style={{ color: '#217346', fontSize: '16px' }}>Excel</Stack.Item>
								</Stack>
							</th>
							<th />
							<th colSpan={2}>
								<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }} styles={{ root: { justifyContent: 'center', paddingBottom: 5, borderBottom: '2px solid #0078d4' } }}>
									<div className="ms-BrandIcon--icon48 ms-BrandIcon--outlook ProgramIcon48"></div>
									<Stack.Item style={{ color: '#0078d4', fontSize: '16px' }}>Outlook</Stack.Item>
								</Stack>
							</th>
						</tr>
						<tr style={{ borderBottom: '1px solid #8a8886' }}>
							<th />
							{[1, 2, 3, 4].map((i) => {
								return (
									<React.Fragment key={i}>
										<th />
										<th>Desktop</th>
										<th>Online</th>
									</React.Fragment>
								)
							})}
						</tr>
					</thead>
					<tbody>
						{props.editedSettings.Extensions
							// .sort((a, b) => a.ExtensionOrder < b.ExtensionOrder ? -1 : a.ExtensionOrder > b.ExtensionOrder ? 1 : 0)
							.map((item: ExtensionItem, idx: number, _arr: ExtensionItem[]) => {
								// console.log(item)
								return (
									<React.Fragment key={item.ExtensionName}>
										<tr key={idx}>
											<td>
												<Stack>
													{idx > 0 ? <IconButton
														key='moveUp'
														text='Move up'
														disabled={idx === 0}
														iconProps={{ iconName: 'ChevronUpSmall' }}
														styles={{ root: { width: 16, height: 16 }, icon: { fontSize: 10 } }}
														onClick={(_ev) => onChangeSettings(item, -1)}
													/> : <div className='Size16' />}
													{idx < _arr.length - 1 ? <IconButton
														key='moveDown'
														text='Move down'
														disabled={idx >= _arr.length - 1}
														iconProps={{ iconName: 'ChevronDownSmall' }}
														styles={{ root: { width: 16, height: 16 }, icon: { fontSize: 10 } }}
														onClick={(_ev) => onChangeSettings(item, +1)}
													/> : <div className='Size16' />}
												</Stack>
											</td>
											<td>{item.ExtensionName}</td>
											<td>
												{item.ExistInWord ?
													<Checkbox
														// className={item.AnimationMotion}
														aria-label={`Enable ${item.ExtensionName} in Word Desktop`}
														styles={{ root: { justifyContent: 'center' } }}
														checked={item.WordDesktop}
														disabled={!item.ActivateWord}
														theme={WordTheme}
														onChange={(_ev, checked) => onChangeSettings(Object.assign(item, item.WordDesktop = checked!))}
													/>
													: null}
											</td>
											<td>
												{item.ExistInWord ?
													<Checkbox
														// className={item.AnimationMotion}
														aria-label={`Enable ${item.ExtensionName} in Word Online`}
														styles={{ root: { justifyContent: 'center' } }}
														checked={item.WordOnline}
														disabled={!item.ActivateWord}
														theme={WordTheme}
														onChange={(_ev, checked) => onChangeSettings(Object.assign(item, item.WordOnline = checked!))}
													/>
													: null}
											</td>
											<td></td>
											<td>
												{item.ExistInPowerPoint ?
													<Checkbox
														aria-label={`Enable ${item.ExtensionName} in PowerPoint Desktop`}
														styles={{ root: { justifyContent: 'center' } }}
														checked={item.PowerPointDesktop}
														disabled={!item.ActivatePowerPoint}
														theme={PowerPointTheme}
														onChange={(_ev, checked) => onChangeSettings(Object.assign(item, item.PowerPointDesktop = checked!))}
													/>
													: null}
											</td>
											<td>
												{item.ExistInPowerPoint ?
													<Checkbox
														aria-label={`Enable ${item.ExtensionName} in PowerPoint Online`}
														styles={{ root: { justifyContent: 'center' } }}
														checked={item.PowerPointOnline}
														disabled={!item.ActivatePowerPoint}
														theme={PowerPointTheme}
														onChange={(_ev, checked) => onChangeSettings(Object.assign(item, item.PowerPointOnline = checked!))}
													/>
													: null}
											</td>
											<td></td>
											<td>
												{item.ExistInExcel ?
													<Checkbox
														aria-label={`Enable ${item.ExtensionName} in Excel Desktop`}
														styles={{ root: { justifyContent: 'center' } }}
														checked={item.ExcelDesktop}
														disabled={!item.ActivateExcel}
														theme={ExcelTheme}
														onChange={(_ev, checked) => onChangeSettings(Object.assign(item, item.ExcelDesktop = checked!))}
													/>
													: null}
											</td>
											<td>
												{item.ExistInExcel ?
													<Checkbox
														aria-label={`Enable ${item.ExtensionName} in Excel Online`}
														styles={{ root: { justifyContent: 'center' } }}
														checked={item.ExcelOnline}
														disabled={!item.ActivateExcel}
														theme={ExcelTheme}
														onChange={(_ev, checked) => onChangeSettings(Object.assign(item, item.ExcelOnline = checked!))}
													/>
													: null}
											</td>
											<td></td>
											<td>
												{item.ExistInOutlook ?
													<Checkbox
														aria-label={`Enable ${item.ExtensionName} in Outlook Desktop`}
														styles={{ root: { justifyContent: 'center' } }}
														checked={item.OutlookDesktop}
														disabled={!item.ActivateOutlook}
														theme={OutlookTheme}
														onChange={(_ev, checked) => onChangeSettings(Object.assign(item, item.OutlookDesktop = checked!))}
													/>
													: null}
											</td>
											<td>
												{item.ExistInOutlook ?
													<Checkbox
														aria-label={`Enable ${item.ExtensionName} in Outlook Online`}
														styles={{ root: { justifyContent: 'center' } }}
														checked={item.OutlookOnline}
														disabled={!item.ActivateOutlook}
														theme={OutlookTheme}
														onChange={(_ev, checked) => onChangeSettings(Object.assign(item, item.OutlookOnline = checked!))}
													/>
													: null}
											</td>
										</tr>
										{item.ExtraFields?.length > 0 && item.ExtraFields.filter(value => value.Visible).length > 0 ?
											<tr style={{ borderTop: '2px solid #fff' }}>
												<td colSpan={2}></td>
												<td colSpan={10}>
													{item.ExtraFields.map((field: ExtraField) => {
														// console.log(item)
														if (field.Visible) {
															const passwordfields = field.HiddenInput ?
																{
																	type: "password"
																} : null
															const passwordreveal = props.userData.isAdmin ?
																{
																	canRevealPassword: true,
																	revealPasswordAriaLabel: "Show password"
																} : null
															// console.log(passwordfields)
															if (field.Value!?.toUpperCase() === 'TRUE' || field.Value!?.toUpperCase() === 'FALSE') {
																return (
																	<Toggle
																		key={field.Title}
																		defaultChecked={field.Value!?.toUpperCase() === 'TRUE'}
																		label={(
																			<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '3px' }}>
																				<span>{field.Title}</span>
																				{getTooltipFromString(field.Description)}
																			</Stack>
																		)}
																		// inlineLabel
																		disabled={!item.ActivateWord && !item.ActivatePowerPoint && !item.ActivateExcel}
																		onChange={(_ev, newValue) => { onChangeField(item, Object.assign(field, field.Value = newValue! ? 'TRUE' : 'FALSE')) }}
																	/>
																)
															}
															else {
																return (
																	<TextField
																		key={field.Title}
																		styles={{ fieldGroup: { maxWidth: 400 } }}
																		label={field.Title}
																		defaultValue={field.Value?.toString()}
																		disabled={!item.ActivateWord && !item.ActivatePowerPoint && !item.ActivateExcel}
																		onRenderLabel={(props, defaultRender) => {
																			return (
																				<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '3px' }}>
																					{defaultRender!(props!)}
																					{getTooltipFromString(field.Description)}
																				</Stack>
																			)
																		}}
																		onChange={(_ev, newValue) => { onChangeField(item, Object.assign(field, field.Value = newValue!)) }}
																		{...passwordfields}
																		{...passwordreveal}
																	/>
																)
															}
														}
														else { return null }
													})}
												</td>
											</tr>
											: null}
									</React.Fragment>
								)
							})}
					</tbody>
				</table>
			</Stack>
		) : null
	);
}

export default SettingsExtensions;
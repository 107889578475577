import { DefaultButton, Stack, Text } from '@fluentui/react';
import Parser from 'html-react-parser';
import * as React from 'react';
import { FunctionItem } from '../../interfaces/FunctionItem';
import { GraphContext, TenantContext } from '../../contexts/ContextWrapper';

interface FunctionComponentProps {
    item: FunctionItem;
    status: string;
    added: boolean;
    allFunctions: FunctionItem[]; // Assuming you have this prop
}


const FunctionComponent: React.FC<FunctionComponentProps> = (props) => {

    const { tenantContext } = React.useContext(TenantContext)
    const { graphContext } = React.useContext(GraphContext)

    return (
        <div key={props.item.FunctionName} style={{ position: 'relative' }}>
            <Stack key={props.item.FunctionName} verticalAlign="start" tokens={{ childrenGap: '5px' }} styles={{ root: { width: '80%', minWidth: '250px', height: '100%', padding: '10px', border: '1px solid #e1dfdd', backgroundColor: '#faf9f8' } }}>
                <Stack.Item align="center" >
                    <Stack horizontal tokens={{ childrenGap: '5px' }}>
                        <Text className="ms-fontSize-18 ms-fontWeight-semibold">{props.item.FunctionName}</Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item align="center" styles={{ root: { height: '184px', lineHeight: '110%' } }}>
                    <Text variant='small' >
                        {Parser(`${props.allFunctions?.find(v => v.FunctionName === props.item.FunctionName)?.Description}`)}
                    </Text>
                </Stack.Item>
                <Stack.Item align="center" >
                    {props.status === 'new' && <div style={{ position: 'absolute', top: '10px', left: '10px', borderRadius: '10px', backgroundColor: '#eec73a', padding: '3px 15px 3px 15px', fontSize: '10px', fontWeight: 'bold' }}>NEW!</div>}
                    {props.status === 'comingsoon' && <div style={{ position: 'absolute', top: '10px', left: '10px', borderRadius: '10px', backgroundColor: '#a8d4ba', padding: '3px 10px 3px 10px', fontSize: '10px', fontWeight: 'bold' }}>COMING SOON!</div>}
                </Stack.Item>
                <Stack.Item align="center" >
                    <Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }}>
                        <DefaultButton
                            text={"Contact me"}
                            iconProps={{ iconName: "Mail" }}
                            onClick={() => {
                                let anchor: HTMLAnchorElement = document.createElement("a");
                                anchor.href = 'mailto:info@rehngruppen.se?subject=TemplateExtender - ' + tenantContext.settings.CompanyName + ' - ' + props.item.FunctionName + '&body=Hello, I\'m interested in ' + props.item.FunctionName + ' for TemplateExtender. Please contact me for further information. %0A%0ABest regards,%0A' + graphContext.userDetails.displayName;
                                anchor.click(); //Downloaded file
                            }}
                        />
                    </Stack>
                </Stack.Item>
            </Stack>
        </div>
    );
};

export default FunctionComponent;
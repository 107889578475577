
import * as React from 'react';
import { DetailsList, DetailsListLayoutMode, Dropdown, FontIcon, getTheme, IColumn, Icon, Link, Spinner, SpinnerSize, Stack, TooltipHost } from '@fluentui/react';
import { GetDomainExtensions } from '../../helpers/GetFromRestApi';
import Axios, { CancelTokenSource } from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import SettingsShort from '../../interfaces/SettingsShort';
import { TenantContext, UserContext } from '../../contexts/ContextWrapper';
import { useNavigate } from 'react-router-dom';

export interface CustomersFeaturesProps {

}

const theme = getTheme();

const CustomersFeatures: React.FC<CustomersFeaturesProps> = () => {
    const [settings, setSettings] = React.useState<SettingsShort[]>([]);
    // const [myApi, setMyApi] = React.useState<'PROD' | 'TEST' | 'DEV'>('PROD');
    const [filteredCustomer, setFilteredCustomer] = React.useState<string>('all');
    const [filterExtension, setFilterExtension] = React.useState<string>('all');
    const [filterFunction, setFilterFunction] = React.useState<string>('all');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const ourRequest = React.useRef<CancelTokenSource>(Axios.CancelToken.source());

    const { accessToken } = React.useContext(AuthContext)
    const { tenantContext } = React.useContext(TenantContext)
    const { userContext } = React.useContext(UserContext)

    const navigate = useNavigate();

    const updateUsers = async () => {
        ourRequest.current = Axios.CancelToken.source();
        setIsLoading(true);
        const allDomains = await GetDomainExtensions(accessToken!, tenantContext.api, ourRequest.current.token);
        setSettings(allDomains)
        setIsLoading(false);
    };

    React.useEffect(() => {
        if (accessToken && tenantContext.api) {
            updateUsers();
        }
        return () => {
            try {
                ourRequest.current.cancel();
            } catch (error) {
                // Handle cancellation error if necessary
            }
        };
    }, [accessToken, tenantContext.api]);

    const uniqueExtensions = (settings: SettingsShort[]): string[] => {
        return settings.map((setting) => setting.Extensions).flat().map((extension) => extension.ExtensionName).filter((value, index, self) => self.indexOf(value) === index)
    }

    const uniqueFunctions = (settings: SettingsShort[]): string[] => {
        return settings.map((setting) => setting.Functions).flat().map((func) => func.FunctionName).filter((value, index, self) => self.indexOf(value) === index)
    }

    const onRenderExtensions = (item: SettingsShort) => {
        return (
            <Stack>
                <table width='310px' style={{ borderCollapse: 'collapse' }}>
                    <tbody>
                        <tr>
                            <td width='150px'></td>
                            <td width='40px'><div className="ms-BrandIcon--icon16 ms-BrandIcon--powerpoint"></div></td>
                            <td width='40px'><div className="ms-BrandIcon--icon16 ms-BrandIcon--word"></div></td>
                            <td width='40px'><div className="ms-BrandIcon--icon16 ms-BrandIcon--excel"></div></td>
                            <td width='40px'><div className="ms-BrandIcon--icon16 ms-BrandIcon--outlook"></div></td>
                        </tr>
                        {item.Extensions.map((extension) => {
                            if (!extension.ActivateExcel && !extension.ActivateOutlook && !extension.ActivatePowerPoint && !extension.ActivateWord) { return <></> }
                            return (
                                <tr key={extension.ExtensionName} style={{ borderTop: '1px solid #ccc', padding: '2px' }}>
                                    <td>{extension.ExtensionName}</td>
                                    <td>
                                        {extension.PowerPointDesktop && <FontIcon iconName="ThisPC" />}
                                        {extension.PowerPointOnline && <FontIcon iconName="Cloud" />}
                                        {!extension.ActivatePowerPoint && extension.ExistInPowerPoint && <FontIcon iconName="Blocked" />}
                                        {extension.ActivatePowerPoint && !extension.PowerPointDesktop && !extension.PowerPointOnline && <FontIcon iconName="CirclePause" />}
                                    </td>
                                    <td>
                                        {extension.WordDesktop && <FontIcon iconName="ThisPC" />}
                                        {extension.WordOnline && <FontIcon iconName="Cloud" />}
                                        {!extension.ActivateWord && extension.ExistInWord && <FontIcon iconName="Blocked" />}
                                        {extension.ActivateWord && !extension.WordDesktop && !extension.WordOnline && <FontIcon iconName="CirclePause" />}
                                    </td>
                                    <td>
                                        {extension.ExcelDesktop && <FontIcon iconName="ThisPC" />}
                                        {extension.ExcelOnline && <FontIcon iconName="Cloud" />}
                                        {!extension.ActivateExcel && extension.ExistInExcel && <FontIcon iconName="Blocked" />}
                                        {extension.ActivateExcel && !extension.ExcelDesktop && !extension.ExcelOnline && <FontIcon iconName="CirclePause" />}
                                    </td>
                                    <td>
                                        {extension.OutlookDesktop && <FontIcon iconName="ThisPC" />}
                                        {extension.OutlookOnline && <FontIcon iconName="Cloud" />}
                                        {!extension.ActivateOutlook && extension.ExistInOutlook && <FontIcon iconName="Blocked" />}
                                        {extension.ActivateOutlook && !extension.OutlookDesktop && !extension.OutlookOnline && <FontIcon iconName="CirclePause" />}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Stack>
        )
    }

    const onRenderFunctions = (item: SettingsShort) => {
        return (
            <Stack>
                <table width='190px' style={{ borderCollapse: 'collapse' }}>
                    <tr>
                        <td width='150px' height='16px'>&nbsp;</td>
                        <td width='40px'>&nbsp;</td>
                    </tr>
                    <tbody>
                        {item.Functions.map((func) => {
                            if (!func.ActivateFunction) { return <></> }
                            return (
                                <tr key={func.FunctionName} style={{ borderTop: '1px solid #ccc', padding: '2px' }}>
                                    <td>{func.FunctionName}</td>
                                    <td>
                                        {func.EnableFunction && <FontIcon iconName="CheckMark" />}
                                        {!func.EnableFunction && <FontIcon iconName="CirclePause" />}
                                        {!func.ActivateFunction && <FontIcon iconName="Blocked" />}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Stack>
        )
    }

    const onRenderCompany = (item: SettingsShort) => {
        const domain: string = item.CompanyName;
        const domainList: string[] = item.Domain.split(';');
        console.log(userContext.userData.Domains)
        const showNavigate: boolean = userContext.userData?.Domains.filter((d) => d.APIName.toLowerCase() === tenantContext.api.toLowerCase() && domainList.includes(d.DomainName)).length > 0;
        return (
            <Stack wrap horizontal tokens={{ childrenGap: 10 }} verticalAlign='start'>
                <span>{domain}</span>
                {showNavigate && (
                    <Link
                        title={`Goto ${domain}`}
                        onClick={() => {
                            navigate(`/${tenantContext.api}/${domainList[0]}/settings`)
                        }}
                    >
                        <Icon styles={{ root: { fontSize: 16 } }} iconName='OpenInNewWindow' />
                    </Link>
                )}
            </Stack>
        )
    }

    const onRenderDomain = (item: SettingsShort) => {
        const domainList: string[] = item.Domain.split(';');
        return (
            <Stack>
                {domainList.map((domain, index) => {
                    return (
                        <span style={{ fontStyle: index > 0 ? 'italic' : 'normal' }} key={domain}>{domain}<br /></span>
                    )
                })}
            </Stack>
        )
    }

    const onRenderUniqueManifest = (item: SettingsShort) => {
        if (item.UniqueManifestID) {
            return (
                <Stack horizontalAlign='center'>
                    <TooltipHost content='Denna kund har ett eget manifest - Kontakta utvecklingsavdelningen för mer hjälp' >
                        <FontIcon iconName="DocumentManagement" />
                    </TooltipHost>
                </Stack>
            )
        }
        else { return <></> }
    }

    const columns: IColumn[] = React.useMemo(() => {
        return [
            { key: 'column1', name: 'Company', fieldName: 'CompanyName', minWidth: 150, maxWidth: 300, isResizable: true, onRender: onRenderCompany },
            { key: 'column2', name: 'Domain', fieldName: 'Domain', minWidth: 150, maxWidth: 300, isResizable: true, onRender: onRenderDomain },
            { key: 'column3', name: 'Unique manifest', fieldName: 'UniqueManifestID', minWidth: 110, maxWidth: 110, isResizable: true, onRender: onRenderUniqueManifest },
            { key: 'column4', name: 'Extensions', fieldName: 'Extensions', minWidth: 320, maxWidth: 320, isResizable: false, onRender: onRenderExtensions },
            { key: 'column5', name: 'Functions', fieldName: 'Functions', minWidth: 200, maxWidth: 200, isResizable: false, onRender: onRenderFunctions }
        ]
    }, []);

    const filteredItems = (
        settings.filter((setting) => (
            (filteredCustomer === 'all' ||
                setting.CompanyName === filteredCustomer) &&
            (filterExtension === 'all' ||
                setting.Extensions.find((ext) => ext.ExtensionName === filterExtension && (ext.ActivateExcel || ext.ActivateOutlook || ext.ActivatePowerPoint || ext.ActivateWord)))) &&
            (filterFunction === 'all' ||
                setting.Functions.find((func) => func.FunctionName === filterFunction && func.ActivateFunction)))
    )

    if (isLoading) { return <Stack styles={{ root: { padding: '30px' } }} ><Spinner size={SpinnerSize.large} label="Loading..." /></Stack>; }

    return (
        <Stack  >
            <Stack horizontal styles={{ root: { padding: '20px' } }} tokens={{ childrenGap: '10px' }}>
                <Dropdown
                    label='Filter by customer'
                    styles={{ root: { width: '250px' } }}
                    options={[{ key: 'all', text: 'All customers' }].concat(settings.map((setting) => { return { key: setting.CompanyName, text: setting.CompanyName } }))}
                    selectedKey={filteredCustomer}
                    onChange={(_ev, option) => {
                        setFilterExtension('all');
                        setFilterFunction('all');
                        setFilteredCustomer(option!.key as string)
                    }}
                />
                <Dropdown
                    label='Filter by extension'
                    styles={{ root: { width: '250px' } }}
                    options={[{ key: 'all', text: 'All extensions' }].concat(uniqueExtensions(settings).map((ext) => { return { key: ext, text: ext } }))}
                    selectedKey={filterExtension}
                    disabled={filteredCustomer !== 'all'}
                    onChange={(_ev, option) => {
                        setFilteredCustomer('all');
                        setFilterExtension(option!.key as string)
                    }}
                />
                <Dropdown
                    label='Filter by function'
                    styles={{ root: { width: '250px' } }}
                    options={[{ key: 'all', text: 'All functions' }].concat(uniqueFunctions(settings).map((func) => { return { key: func, text: func } }))}
                    selectedKey={filterFunction}
                    disabled={filteredCustomer !== 'all'}
                    onChange={(_ev, option) => {
                        setFilteredCustomer('all');
                        setFilterFunction(option!.key as string)
                    }}
                />
            </Stack>
            <Stack horizontal styles={{ root: { padding: '20px', color: theme.palette.neutralSecondary } }} tokens={{ childrenGap: '10px' }}>
                <Stack.Item><FontIcon iconName="ThisPC" /> Desktop</Stack.Item>
                <Stack.Item><FontIcon iconName="Cloud" /> Online</Stack.Item>
                <Stack.Item><FontIcon iconName="CirclePause" /> Disabled by customer</Stack.Item>
                <Stack.Item><FontIcon iconName="Blocked" /> Not ordered</Stack.Item>
                <Stack.Item><TooltipHost content='Denna kund har ett eget manifest - Kontakta utvecklingsavdelningen för mer hjälp' ><FontIcon iconName="DocumentManagement" /> Unique manifest </TooltipHost></Stack.Item>
            </Stack>
            <DetailsList
                compact={true}
                items={filteredItems}
                columns={columns}
                setKey="set"
                layoutMode={DetailsListLayoutMode.justified}
                selectionPreservedOnEmptyClick={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
            />
        </Stack>
    )
};

export default CustomersFeatures;

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import { initializeIcons, loadTheme, mergeStyles } from '@fluentui/react';
import { initializeFileTypeIcons } from '@fluentui/react-file-type-icons';
import * as serviceWorker from './serviceWorker';
import ContextWrapper from './contexts/ContextWrapper';
import MsalAuthProvider from './contexts/MsalContext';
import { AuthProvider } from './contexts/AuthContext';
import { CoboltTheme } from './helpers/Themes';

// loadEnv();
(require('./index.css'))

// Inject some global styles
mergeStyles({
	selectors: {
		':global(body), :global(html), :global(#root)': {
			margin: 0,
			padding: 0,
			height: '100%'
		}
	}
});


initializeIcons();
initializeFileTypeIcons()
loadTheme(CoboltTheme);

ReactDOM.render(
	<React.StrictMode>
		<MsalAuthProvider>
			<AuthProvider>
				<ContextWrapper>
					<App />
				</ContextWrapper>
			</AuthProvider>
		</MsalAuthProvider>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import * as React from 'react';
import { CommandBar, ICommandBarItemProps, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import SettingsCompany from '../../helpers/SettingsCompany';
import SettingsMenu from './SettingsMenu';
import { updateDomainSettings } from '../../helpers/PostToRestApi';
import { CancelTokenSource } from 'axios';
import { GetAllExtensions, GetAllFunctions, GetAvailableExtensions, GetAvailableFunctions } from '../../helpers/GetFromRestApi';
import SettingsAdmin from './SettingsAdmin';
import SettingsBranding from './SettingsBranding';
import SettingsExtensions from './SettingsExtensions';
import SettingsFunctions from './SettingsFunctions';
import SettingsMore from './SettingsMore';
// import { DialogOkAbort } from '../dialogs/OldDialogOkAbort';
import { ExtensionItem } from '../../interfaces/ExtensionItem';
import SettingsLogo from './SettingsLogo';
import { FunctionItem } from '../../interfaces/FunctionItem';
import SettingsManifest from './SettingsManifest';
import { ContentFrame } from '../common/ContentFrame';
import { useEffect } from 'react';
import { GraphContext, TenantContext, UserContext } from '../../contexts/ContextWrapper';
import { useSettingsFetch } from '../../hooks/useSettingsFetch';
import { DialogOkAbort } from '../dialogs/DialogOkAbort';
import { checkValidToken } from '../../helpers/LogginHelper';
import SettingsRedirect from './SettingsRedirect';
import FloatingButton from '../common/FloatingButton';
import { AuthContext } from '../../contexts/AuthContext';

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

const Settings: React.FC = () => {

	const { tenantContext, setTenantContext } = React.useContext(TenantContext);
	// const { messageContext, setMessageContext } = React.useContext(MessageContext);
	const { userContext } = React.useContext(UserContext);
	const { UpdateSettings } = useSettingsFetch()
	const { accessToken, refreshToken } = React.useContext(AuthContext);
	const {graphContext} = React.useContext(GraphContext)

	const [isLoading, setIsLoading] = React.useState<boolean>(false)
	const [editedSettings, setEditedSettings] = React.useState<SettingsCompany>(JSON.parse(JSON.stringify(tenantContext.settings)))
	const [selectedPivotItem, setSelectedPivotItem] = React.useState<string>('branding')
	const [hideSaveDialog, setHideSaveDialog] = React.useState<boolean>(true)

	const [allExtensions, setAllExtensions] = React.useState<ExtensionItem[]>([])
	const [availableExtensions, setAvailableExtensions] = React.useState<ExtensionItem[]>([])
	const [allFunctions, setAllFunctions] = React.useState<FunctionItem[]>([])
	const [availableFunctions, setAvailableFunctions] = React.useState<FunctionItem[]>([])

	useEffect(() => {
		setIsLoading(true)
		resetSettings()
			.then(() => { setIsLoading(false) })
	}, []);

	useEffect(() => {
		setIsLoading(true)
		resetSettings()
			.then(() => { setIsLoading(false) })
	}, [accessToken, tenantContext.tenantName, tenantContext.api, tenantContext.settings]);

	useEffect(() => {
		if (editedSettings.Domain!?.toLowerCase() !== tenantContext.tenantName!?.toLowerCase()) {
			UpdateSettings(tenantContext.api, tenantContext.tenantName)
		}
	}, [UpdateSettings, editedSettings.Domain, tenantContext.api, tenantContext.tenantName])


	const handleNewSettings = (newSettings: SettingsCompany) => {
		setEditedSettings({ ...editedSettings, ...newSettings })
	}

	const isDirty: boolean = JSON.stringify(editedSettings) !== JSON.stringify(tenantContext.settings)

	const resetSettings = async () => {

		const [myAvailableExtensions, myAllExtensions, myAvailableFunctions, myAllFunctions] = await Promise.all([
			GetAvailableExtensions(accessToken!, tenantContext.tenantName, tenantContext.api, ourRequest.token),
			GetAllExtensions(accessToken!, tenantContext.tenantName, tenantContext.api, ourRequest.token),
			GetAvailableFunctions(accessToken!, tenantContext.tenantName, tenantContext.api, ourRequest.token),
			GetAllFunctions(accessToken!, tenantContext.tenantName, tenantContext.api, ourRequest.token),
		])

		setAvailableExtensions(myAvailableExtensions)
		setAllExtensions(myAllExtensions)
		setAvailableFunctions(myAvailableFunctions)
		setAllFunctions(myAllFunctions)
	}

	const saveSettings = () => {
		let mySettings: SettingsCompany = editedSettings
		updateDomainSettings(accessToken!!, tenantContext.tenantName, tenantContext.api, JSON.stringify(mySettings))
			.then(() => {
				setTimeout(() => {
					UpdateSettings(tenantContext.api, tenantContext.tenantName)
				}, 500);
			})
	}

	const commandItems = (): ICommandBarItemProps[] => [
		{
			key: 'save',
			text: 'Save',
			disabled: !isDirty,
			iconProps: { iconName: 'Save' },
			// onClick: () => { setState({ ...state, hideSaveDialog: false }) },
			onClick: () => { setHideSaveDialog(false) },
		},
		{
			key: 'reset',
			text: 'Reset',
			disabled: !isDirty,
			iconProps: { iconName: 'Undo' },
			onClick: () => { setEditedSettings(JSON.parse(JSON.stringify(tenantContext.settings))) },
		},
	];

	// Command items, saveSettings, resetSettings, onUpdateToNewSettings, etc. remain unchanged
	console.log(allFunctions)

	console.log('MainPage.tsx: useEffect: checkValidToken')
	if (!checkValidToken(accessToken!)) { refreshToken() }

	if (isLoading) {
		return (
			<Stack horizontalAlign='center' verticalAlign='center' styles={{ root: { margin: '30px', height: '100%' } }}>
				<Spinner className='spinner' size={SpinnerSize.large} label='Loading settings...' />
			</Stack>
		)
	}

	if (isNaN(availableExtensions?.length!)) {
		return (
			<></>
		)
	}

	let main: JSX.Element = <></>

	switch (selectedPivotItem) {
		case 'admin':
			main = (
				<SettingsAdmin
					setEditedSettings={handleNewSettings}
					editedSettings={editedSettings!}
					availableExtensions={availableExtensions}
					availableFunctions={availableFunctions}
				/>
			)
			break;
		case 'branding':
			main = (
				<>
					<SettingsBranding
						setEditedSettings={handleNewSettings}
						editedSettings={editedSettings!}
						companyLogo={tenantContext.logo}
					/>
					<SettingsLogo
						setNewLogo={(logo) => { setTenantContext({ ...tenantContext, logo: logo }) }}
						updateSettings={() => UpdateSettings(tenantContext.api, tenantContext.tenantName)}
						editedSettings={editedSettings!}
						companyLogo={tenantContext.logo}
						domain={tenantContext.tenantName}
						api={tenantContext.api}
						accessToken={accessToken!}
					/>
				</>
			)
			break;
		case 'extensions':
			main = (
				<SettingsExtensions
					setEditedSettings={handleNewSettings}
					editedSettings={editedSettings!}
					userData={userContext.userData}
				/>
			)
			break;
		case 'functions':
			main = (
				<SettingsFunctions
					setEditedSettings={handleNewSettings}
					editedSettings={editedSettings!}
					userData={userContext.userData}
				/>
			)
			break;
		case 'manifest':
			main = (
				<SettingsManifest
					updateSettings={() => UpdateSettings(tenantContext.api, tenantContext.tenantName)}
					setEditedSettings={handleNewSettings}
					editedSettings={editedSettings!}
					userData={userContext.userData}
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
					isSaved={JSON.stringify(editedSettings) === JSON.stringify(tenantContext.settings)}
					outlook={false}
				/>
			)
			break;
		case 'manifestoutlook':
			main = (
				<SettingsManifest
					updateSettings={() => UpdateSettings(tenantContext.api, tenantContext.tenantName)}
					setEditedSettings={handleNewSettings}
					editedSettings={editedSettings!}
					userData={userContext.userData}
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
					isSaved={JSON.stringify(editedSettings) === JSON.stringify(tenantContext.settings)}
					outlook={true}
				/>
			)
			break;
		case 'redirect':
			main = (
				<SettingsRedirect
				/>
			)
			break;
		case 'more':
			main = (
				<SettingsMore
					editedSettings={editedSettings!}
					availableExtensions={availableExtensions}
					allExtensions={allExtensions}
					displayName={graphContext.userDetails.displayName}
				/>
			)
			break;
	}

	return (
		<Stack >
			<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
				<Text variant="xxLarge">
					Settings
				</Text>
			</Stack.Item>
			{typeof availableExtensions?.length! === 'number' && typeof editedSettings?.Extensions?.length! === 'number' ?
				<ContentFrame>
					<Stack styles={{ root: { padding: '15px', textIndent: '0px' } }} tokens={{ childrenGap: '10px' }}>
						<CommandBar
							items={commandItems()}
							ariaLabel="Use left and right arrow keys to navigate between commands"
						/>
						<SettingsMenu
							setSelectedPivotItem={setSelectedPivotItem}
							selectedPivotItem={selectedPivotItem!}
							userData={userContext.userData}
							settings={editedSettings!}
						/>
						{main}
					</Stack>
				</ContentFrame>
				:
				<ContentFrame>
					<Text variant="medium">Settings could not load!</Text>
				</ContentFrame>
			}
			<DialogOkAbort
				callbackOk={() => { saveSettings(); setHideSaveDialog(true) }}
				callbackAbort={() => { setHideSaveDialog(true) }}
				hidden={hideSaveDialog!}
				title='Save settings'
				description={`Do you want to overwrite existing settings for the domain ${tenantContext.tenantName}?`}
			/>
			<FloatingButton
				hidden={!isDirty}
				tooltip='Save'
				iconName='Save'
				right='120px'
				onClick={() => { setHideSaveDialog(false) }}
			/>
			<FloatingButton
				hidden={!isDirty}
				tooltip='Reset'
				iconName='Undo'
				right='40px'
				onClick={() => { setEditedSettings(JSON.parse(JSON.stringify(tenantContext.settings))) }}
			/>
		</Stack>
	)
}

export default Settings
import { Image, Link, registerIcons, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import { CenterComponent } from '../common/CenterComponent';
import { GetAllExtensions, GetAllFunctions, GetAvailableExtensions, GetAvailableFunctions } from '../../helpers/GetFromRestApi';
import { CancelTokenSource } from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import { TenantContext } from '../../contexts/ContextWrapper';
import { ExtensionItem } from '../../interfaces/ExtensionItem';
import ExtensionComponent from './ExtensionComponent';
import moment from 'moment';
import { FunctionItem } from '../../interfaces/FunctionItem';
import FunctionComponent from './FunctionComponent';

interface DiscoverMoreProps {

}

const ImageVaultLogo = require('../../assets/imagevault.svg');
const UnsplashLogo = require('../../assets/unsplash.svg');

registerIcons({
    icons: {
        'ImageVault': (
            <Image alt='ImageVault Logo' src={ImageVaultLogo} />
        ),
        'Unsplash': (
            <Image alt='Unsplash Logo' src={UnsplashLogo} />
        ),
    },
});

const DiscoverMore: React.FC<DiscoverMoreProps> = () => {

    const [ourRequest] = React.useState<CancelTokenSource>({} as CancelTokenSource);
    const [availableExtensions, setAvailableExtensions] = React.useState<ExtensionItem[]>([]);
    const [allExtensions, setAllExtensions] = React.useState<ExtensionItem[]>([]);
    const [availableFunctions, setAvailableFunctions] = React.useState<FunctionItem[]>([]);
    const [allFunctions, setAllFunctions] = React.useState<FunctionItem[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [showMore, setShowMore] = React.useState<boolean>(false);

    const { tenantContext } = React.useContext(TenantContext);
    const { accessToken } = React.useContext(AuthContext);

    React.useEffect(() => {
        return () => {
            try { ourRequest.cancel(); }
            catch (error) { }
        };
    }, []);

    React.useEffect(() => {
        let mounted = true;
        const fetchExtensions = async () => {
            setIsLoading(true);
            const [availableExtensions, allExtensions, availableFunctions, allFunctions] = await Promise.all([
                GetAvailableExtensions(accessToken!!, tenantContext.tenantName, tenantContext.api!, ourRequest.token),
                GetAllExtensions(accessToken!!, tenantContext.tenantName, tenantContext.api!, ourRequest.token),
                GetAvailableFunctions(accessToken!!, tenantContext.tenantName, tenantContext.api!, ourRequest.token),
                GetAllFunctions(accessToken!!, tenantContext.tenantName, tenantContext.api!, ourRequest.token),
            ])
            if (!mounted) return;
            setAvailableExtensions(availableExtensions);
            setAllExtensions(allExtensions);
            setAvailableFunctions(availableFunctions);
            setAllFunctions(allFunctions);
            setIsLoading(false);
        }
        fetchExtensions();
        return () => { mounted = false; }
    }, [accessToken, tenantContext.tenantName, tenantContext.api]);

    if (isLoading) {
        return (
            <CenterComponent>
                <Spinner className='spinner' size={SpinnerSize.large} label='Loading extensions...' />
            </CenterComponent>
        )
    }

    if (availableExtensions.length + availableFunctions.length === 0) { return null; }

    return (
        <Stack.Item styles={{ root: { padding: 20 } }}>
            <Stack tokens={{ childrenGap: '5px' }}>
                <Text variant="xLarge">Discover more</Text>
                <Stack wrap={showMore} horizontal tokens={{ childrenGap: '10px' }} styles={{ root: { paddingTop: '10px', overflow: showMore || availableExtensions.length + availableFunctions.length < 4 ? 'visible' : 'auto', maxWidth: '95%' } }} >
                    {availableExtensions?.map((item) => {
                        const dateDiff: number = item.ProductionDate ? (new Date(moment().format('YYYY-MM-DD')).getTime() - new Date(moment(item.ProductionDate).format('YYYY-MM')).getTime()) / 86400000 || -1000 : 1000;
                        if (item.Description && dateDiff > -100) {
                            return (
                                <Stack.Item
                                    key={item.ExtensionName}
                                    styles={{ root: { marginRight: 20, minWidth: 250, width: 250, } }}
                                >
                                    <ExtensionComponent
                                        key={item.ExtensionName}
                                        item={item}
                                        status={dateDiff >= 0 ? dateDiff > 60 ? '' : 'new' : 'comingsoon'}
                                        added={false}
                                        allExtensions={allExtensions!}
                                    />
                                </Stack.Item>
                            );
                        }
                        return null;
                    })}
                    {availableFunctions?.map((item) => {
                        const dateDiff: number = item.ProductionDate ? (new Date(moment().format('YYYY-MM-DD')).getTime() - new Date(moment(item.ProductionDate).format('YYYY-MM')).getTime()) / 86400000 || -1000 : 1000;
                        console.log(dateDiff);
                        if (item.Description && dateDiff > -100) {
                            return (
                                <Stack.Item
                                    key={item.FunctionName}
                                    styles={{ root: { marginRight: 20, minWidth: 250, width: 250 } }}
                                >
                                    <FunctionComponent
                                        key={item.FunctionName}
                                        item={item}
                                        status={dateDiff >= 0 ? dateDiff > 60 ? '' : 'new' : 'comingsoon'}
                                        added={false}
                                        allFunctions={allFunctions!}
                                    />
                                </Stack.Item>
                            );
                        }
                        return null;
                    })}
                </Stack>
                {availableExtensions.length + availableFunctions.length > 3 &&
                    (!showMore ?
                        <Link onClick={() => setShowMore(true)}>Expand...</Link>
                        :
                        <Link onClick={() => setShowMore(false)}>Collapse...</Link>
                    )
                }
            </Stack>
        </Stack.Item>
    );
};
export default DiscoverMore;

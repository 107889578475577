import * as React from 'react';
import Axios, { CancelTokenSource } from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import { TenantContext } from '../../contexts/ContextWrapper';
import { GetLoginStatistics } from '../../helpers/GetFromRestApi';
import { StatisticMonthLogInItem } from '../../interfaces/Statisticd';
import StatisticsBarChart from '../statistics/StatisticsBarChart';
import { Stack ,Text} from '@fluentui/react';

export interface HomeStatisticsProps {

}

let ourRequest: CancelTokenSource | undefined;

const HomeStatistics: React.FC<HomeStatisticsProps> = () => {

    const [loginItems, setLoginItems] = React.useState<StatisticMonthLogInItem[]>([]);

    const { tenantContext } = React.useContext(TenantContext);
    const { accessToken } = React.useContext(AuthContext);

    React.useEffect(() => {
        let mounted = true;
        const getLoginStatistics = async () => {
            if (ourRequest) {
                ourRequest.cancel();
            }
            ourRequest = Axios.CancelToken.source();

            try {
                if (accessToken && tenantContext.tenantName && tenantContext.api) {
                    const loginItems = await GetLoginStatistics(
                        accessToken!,
                        tenantContext.tenantName,
                        tenantContext.api,
                        ourRequest.token
                    );
                    if (!mounted) return;
                    setLoginItems(loginItems || []);
                }
            } catch (error) {

                // Handle the error appropriately
            }
        };
        getLoginStatistics();
        return () => {
            mounted = false;
        };
    }, [accessToken!, tenantContext.tenantName, tenantContext.api]);

    if (loginItems!?.length === 0) { return null; }

    return (
        <Stack.Item styles={{ root: { padding: 20, maxWidth: 340 } }}>
            <Stack tokens={{ childrenGap: '10px' }}>
                <Text variant="xLarge">Monthly unique logins</Text>
                <StatisticsBarChart
                    title=""
                    plotItems={loginItems
                        .slice(0, 5)
                        .reverse()
                        .map((item) => ({
                            x: item.Month + ' ' + item.Year,
                            y: item.UniqueLogIn,
                        }))}
                    horizontal={true}
                />
            </Stack>
        </Stack.Item>
    );
};

export default HomeStatistics;
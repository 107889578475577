import * as React from 'react';
import pretty from 'pretty';
import {
	DefaultButton,
	IconButton,
	IContextualMenuItem,
	IContextualMenuProps,
	Image,
	Separator,
	Stack,
	Text,
	TextField,
} from '@fluentui/react';
import * as DOMPurify from 'dompurify';
import { EmailSignature } from '../../interfaces/EmailSignature';
import { EmbeddedPictureItem } from '../../interfaces/EmbeddedPictureItem';
import { GetUseCustomData, GetUseUserInformation } from '../../helpers/SettingsCompany';
import { DialogOkAbort } from '../dialogs/DialogOkAbort';
import { aadFieldsMenuProps, buttonStyles, combinedFieldsMenuProps, customFieldsMenuProps } from '../../helpers/HtmlStyling';
import { useCustomData } from '../../hooks/useCustomData';
import { useReplace } from '../../hooks/useReplace';
import { useUserData } from '../../hooks/useUserData';
import HtmlButtons from '../common/HtmlButtons';
import { TenantContext } from '../../contexts/ContextWrapper';
import { useCombinedData } from '../../hooks/useCombinedData';

export interface EmailSignaturesPreviewProps {
	saveSignatures: (signature: EmailSignature) => void
	signature: EmailSignature
	embeddedPictureItems: EmbeddedPictureItem[]
	signatureVersion: string
}

// export interface EmailSignaturesPreviewState {
// 	signature: EmailSignature
// 	html: string
// 	cursorPositionStart: number
// 	cursorPositionEnd: number
// 	// hideSaveDialog: boolean
// 	hideResetDialog: boolean
// 	// saveConfirmed: boolean
// 	// resetConfirmed: boolean
// }

// export default class EmailSignaturesPreview extends React.Component<EmailSignaturesPreviewProps, EmailSignaturesPreviewState> {

// constructor(props: EmailSignaturesPreviewProps) {
// 	super(props);
// 	this.state = {
// 		signature: exampleEmailSignature,
// 		html: '',
// 		cursorPositionStart: 0,
// 		cursorPositionEnd: 0,
// 		// hideSaveDialog: true,
// 		hideResetDialog: true,
// 		// saveConfirmed: false,
// 		// resetConfirmed: false,
// 	};
// 	this.boundSetState = this.setState.bind(this)
// }

// boundSetState = (x: any) => { this.setState(x) }

const EmailSignaturesPreview: React.FC<EmailSignaturesPreviewProps> = ({ saveSignatures, signature, embeddedPictureItems, signatureVersion }) => {
	// const [localSignature, setLocalSignature] = React.useState<EmailSignature>(exampleEmailSignature);
	const [html, setHtml] = React.useState<string>('');
	const [cursorPositionStart, setCursorPositionStart] = React.useState<number>(0);
	const [cursorPositionEnd, setCursorPositionEnd] = React.useState<number>(0);
	const [hideResetDialog, setHideResetDialog] = React.useState<boolean>(true);

	const { tenantContext } = React.useContext(TenantContext)

	const { userDataItems } = useUserData()
	const { customDataItem } = useCustomData()
	const { combinedDataItems } = useCombinedData()
	const { ReplaceInText, isLoadingUserData, isLoadingCustomData } = useReplace()

	React.useEffect(() => {
		// setLocalSignature(signature);
		setHtml(pretty(signature.HTML, { ocd: true }));
	}, [signature]);

	const insertTextAtSelection = (startText: string, endText?: string) => {
		setHtml(`${html.substring(0, cursorPositionStart)}${startText}${html.substring(cursorPositionStart, cursorPositionEnd)}${endText ? endText : ''}${html.substring(cursorPositionEnd, html.length)}`)
	}

	const CheckInvalidRef = (): boolean => {
		return html.match(/src=['"]cid:([^"']*)["']/ig)!?.some((name) => {
			const pic = embeddedPictureItems!?.find((pic) => pic.PictureName === name.replace(/src=['"]cid:([^"']*)["']/ig, '$1'))
			if (!pic) { return true }
			return false
		})
	}

	const SignaturePreview = (): string => {
		let signature = html
		try {
			//replace pictures with base64
			signature = signature.replace(/src=['"]cid:([^"']*)["']/ig, (_match, p1) => {
				const pic: EmbeddedPictureItem = embeddedPictureItems.find((val) => val!?.PictureName === p1!)!
				const extension = pic!?.PictureName.split('.').pop()!?.toLowerCase()
				return `src='data:image/${extension === 'jpg' ? 'jpeg' : extension};base64,${pic!?.Base64!}'`
			})
			const groupFields: string[] = signature.match(/\[grp[^\]]*\]/g)!?.map(val => { return val })
			// console.log({ groupFields: groupFields })

			if (groupFields && groupFields!.length > 0) {
				groupFields.forEach((groupField) => {
					const arr: string[] = groupField.split('=')
					if (arr.length === 2) {
						const id = arr[1].replace(']', '')
						const item = combinedDataItems.find((val) => val.ID === id)

						if (item) {
							const grpString = item.DataItems && item.DataItems!?.map((subItem) => {
								return ReplaceInText(subItem.DataItem, true)
							}).filter((v) => v.length > 0).join(item.Delimiter === '\v' ? '<br />' : item.Delimiter)
							const regex = new RegExp(`\\[grp=${id}\\]`, 'g')
							signature = signature.replace(regex, grpString)
						}
					}
				})
			}


			signature = ReplaceInText(signature, false)

			const errorFields: string[] = signature.match(/\[grp[^\]]*\]|\[aad[^\]]*\]|\[cs[^\]]*\]/g)!?.map(val => { return val })

			if (errorFields && errorFields!.length > 0) {
				errorFields.forEach((errorField) => {
					signature = signature.replace(errorField, `<span style='color:red'>${errorField}</span>`)
				})
			}

		} catch (error) {
			console.log(error)
		}
		return signature
	}

	const setCursor = (event: any) => {
		setCursorPositionStart(event.currentTarget.selectionStart!)
		setCursorPositionEnd(event.currentTarget.selectionEnd!)
	}

	const pictureMenuProps = (): IContextualMenuProps | undefined => {
		if (embeddedPictureItems) {
			return {
				items: embeddedPictureItems.map((pic) => {
					return {
						key: pic.PictureName,
						text: pic.PictureName,
						data: pic.Base64,
						onRender: (item: IContextualMenuItem) => {
							return (
								<IconButton
									key={item.text!}
									ariaLabel={item.text!}
									styles={buttonStyles}
									style={{ width: '100%', height: 'auto', maxHeight: 104, padding: 2, border: '' }}
									onClick={() => { insertTextAtSelection(`<img src="cid:${item.text!}">`) }}
								>
									<Stack>
										<Image
											src={`data:image/${item.text!.split('.').pop()?.toLowerCase() === 'jpg' ? 'jpeg' : item.text!.split('.').pop()?.toLowerCase()};base64,${item.data!}`}
											alt={item.text!}
											styles={{ image: { objectFit: 'contain', height: 'auto', width: 'auto', maxHeight: 86, maxWidth: 100 } }}
										/>
										<Text variant='xSmall'>{item.text!}</Text>
									</Stack>
								</IconButton>
							)
						}
					} as IContextualMenuItem
				})
			}
		}
		else { return { items: [] } }
	}

	return (
		<Stack className='Width100' styles={{ root: { paddingLeft: '20px', paddingRight: '20px' } }} tokens={{ childrenGap: '5px' }}>
			{signatureVersion === 'TEST' ?
				<>
					<span><h3>Edit signature</h3></span>
					<TextField
						styles={{
							root: {
								width: '100%',
								selectors: {
									'textarea': {
										whiteSpace: 'pre-wrap',
										fontFamily: 'monospace',
										fontSize: '12px',
									},
								}
							},
							field: {}
						}}
						value={html}
						onChange={(_event, value) => {
							setHtml(value!);
							// setCursorPositionStart(event.currentTarget.selectionStart!);
							// setCursorPositionEnd(event.currentTarget.selectionEnd!);
						}}
						onClick={(event) => {
							setCursor(event)
						}}
						onKeyUp={(event) => {
							setCursor(event)
						}}
						onSelectCapture={(event) => {
							setCursor(event)
						}}
						multiline
						rows={12}
					/>
					<Stack horizontal wrap tokens={{ childrenGap: '5px' }}>
						<DefaultButton
							primary
							text='Save'
							disabled={CheckInvalidRef()}
							onClick={() => {
								saveSignatures(
									{
										...signature,
										HTML: html,
										EmbeddedPictures: html.match(/src=['"]cid:([^"']*)["']/ig)!?.map((name) => {
											return { PictureName: name.replace(/src=['"]cid:([^"']*)["']/ig, '$1') } as EmbeddedPictureItem
										})
									})
							}}
						/>
						<DefaultButton
							text='Reset'
							// onClick={() => this.setState({ html: pretty(signature.HTML, { ocd: true }) })}
							onClick={() => setHideResetDialog(false)}
						/>
						<HtmlButtons
							InsertTextAtSelection={insertTextAtSelection}
						/>
						{!isLoadingUserData && GetUseUserInformation(tenantContext.settings) &&
							<DefaultButton
								text='User Data'
								menuProps={aadFieldsMenuProps(userDataItems, insertTextAtSelection)}
							/>
						}
						{!isLoadingCustomData && GetUseCustomData(tenantContext.settings) && customDataItem &&
							<DefaultButton
								text='Custom Data'
								menuProps={customFieldsMenuProps(customDataItem, insertTextAtSelection)}
							/>
						}
						{!isLoadingUserData && !isLoadingCustomData && GetUseUserInformation(tenantContext.settings) && combinedDataItems
							&& <DefaultButton
								text='Combined Data'
								menuProps={combinedFieldsMenuProps(combinedDataItems, insertTextAtSelection)}
							/>
						}
						<DefaultButton
							text='Insert Picture'
							menuProps={pictureMenuProps()}
						/>
					</Stack>
				</>
				:
				<span><h3>Preview signature</h3></span>

			}
			<Separator key='sepPreview' />
			<div dangerouslySetInnerHTML={{
				__html: DOMPurify.sanitize(
					// SignaturePreview(html, embeddedPictureItems, settings.UserInformation!, customDataItem)
					SignaturePreview()
				)
			}} />
			<DialogOkAbort
				callbackOk={() => {
					setHtml(pretty(signature.HTML, { ocd: true }));
					setHideResetDialog(true);
					// this.setState({ html: pretty(signature.HTML, { ocd: true }), hideResetDialog: true })
				}}
				callbackAbort={() => {
					setHideResetDialog(true);
					//  this.setState({ hideResetDialog: true })
				}}
				title='Reset signature'
				description={`Do you want to reset the signature ${signature.SignatureName}? All changes will be lost!`}
				hidden={hideResetDialog!}
			/>
		</Stack>
	);
}

export default EmailSignaturesPreview;
import axios, { AxiosRequestConfig, AxiosRequestHeaders, CancelToken } from 'axios';
import { ContentFolder } from '../interfaces/ContentFolder';
import { ContentItem } from '../interfaces/ContentItem';
import { CustomDataDefItem } from '../interfaces/CustomDataDefItem';
import { CustomDataItem } from '../interfaces/CustomDataItem';
import { DomainContent } from '../interfaces/DomainContent';
import { EmailSignature } from '../interfaces/EmailSignature';
import { EmbeddedPictureItem } from '../interfaces/EmbeddedPictureItem';
import { ExtensionItem } from '../interfaces/ExtensionItem';
import { FileInfo } from '../interfaces/FileInfo';
import { FunctionItem } from '../interfaces/FunctionItem';
import { GroupInfo } from '../interfaces/GroupInfo';
import { IconListItem } from '../interfaces/IconListItem';
import { ManifestItem } from '../interfaces/ManifestItem';
import { examplePictureList, PictureListItem } from '../interfaces/PictureListItem';
import { ExampleLayoutList, PictureSrcLib } from '../interfaces/PictureSrcLib';
import { PresentationListItem } from '../interfaces/PresentationListItem';
import { SlideListItem } from '../interfaces/SlideListItem';
import { StatisticMonthLogInItem, StatisticSlideItem, StatisticTemplatesItem } from '../interfaces/Statisticd';
import { TemplateApiHeaders } from '../interfaces/TemplateApiHeaders';
import { TemplateFolderItem } from '../interfaces/TemplateFolderItem';
import { TemplateListItem } from '../interfaces/TemplateListItem';
import { UserAdminItem } from '../interfaces/UserAdminItem';
import SettingsCompany, { UserInfoItem } from './SettingsCompany';
import { GroupID } from '../interfaces/GroupID';
import { adminApiUris, apiUris } from './SettingsMisc';
import { NewsItem } from '../interfaces/NewsItem';
import { RedirectItem } from '../interfaces/RedirectItem';
import { GroupedData } from '../interfaces/GroupedData';
import { CustomDataPlainListItem } from '../interfaces/CustomDataPlainList';
import { SectionsDocument } from '../interfaces/SectionsDocument';
import { AcademyItem, AcademyLearningLevel, AcademyLevel, AcademyPlatform } from '../interfaces/AcademyItem';
import { AcademyPlatformItems } from '../interfaces/AcademyPlatform';
import SettingsShort from '../interfaces/SettingsShort';


//Settings
export async function GetAdminSettings(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<SettingsCompany> {
	let settings: SettingsCompany = await GetData(`${apiUris(api)}admin_products`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || {} as SettingsCompany;
	// if (!settings.ExcelSettings) { settings.ExcelSettings = {} as XLSettings }
	// if (!settings.PowerPointSettings) { settings.PowerPointSettings = {} as PPSettings }
	// if (!settings.WordSettings) { settings.WordSettings = {} as WDSettings }
	console.error(settings)
	return settings;
}

export async function GetAllExtensions(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<ExtensionItem[]> {
	let extensions: ExtensionItem[] = await GetData(`${apiUris(api)}admin_products?AllExtensions=True`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || [];
	return extensions;
}

export async function GetAvailableExtensions(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<ExtensionItem[]> {
	let extensions: ExtensionItem[] = await GetData(`${apiUris(api)}admin_products?AvailableExtensions=True`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || [];
	return extensions;
}

export async function GetAllFunctions(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<FunctionItem[]> {
	let functions: FunctionItem[] = await GetData(`${apiUris(api)}admin_products?AllFunctions=True`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || [];
	return functions;
}

export async function GetAvailableFunctions(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<FunctionItem[]> {
	let functions: FunctionItem[] = await GetData(`${apiUris(api)}admin_products?AvailableFunctions=True`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || [];
	return functions;
}

export async function GetManifestItems(accessToken: string, domain: string, api: string, outlookManifest: boolean, cancelToken?: CancelToken): Promise<ManifestItem[]> {
	let manifestitems: ManifestItem[] = await GetData(`${apiUris(api)}admin_products?ManifestItems=True`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'IS-OUTLOOK-MANIFEST' : outlookManifest}, true, cancelToken!) || [];
	return manifestitems;
}

export async function GetManifestFile(accessToken: string, domain: string, api: string, includeExcel: boolean, includePowerPoint: boolean, includeWord: boolean, updateVersion: boolean, cancelToken?: CancelToken): Promise<string> {
	let manifestfile: string = await GetData(`${apiUris(api)}admin_products?ManifestFile=True&excel=${includeExcel}&powerpoint=${includePowerPoint}&word=${includeWord}`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'UPDATE-VERSION': updateVersion }, true, cancelToken!) || [];
	return manifestfile;
}

export async function GetOutlookManifestFile(accessToken: string, domain: string, api: string, updateVersion: boolean, cancelToken?: CancelToken): Promise<string> {
	let manifestfile: string = await GetData(`${apiUris(api)}admin_products?ManifestFile=True&outlook=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'UPDATE-VERSION': updateVersion }, true, cancelToken!) || [];
	return manifestfile;
}

// export async function GetSettingsUpdate(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<boolean> {
// 	// let canUpdateString: string = await GetData(`${apiUris(api)}admin_products?CheckUpdate=True`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, false, cancelToken!) || false;
// 	let canUpdate: boolean = await GetData(`${apiUris(api)}admin_products?CheckUpdate=True`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, false, cancelToken!) === 'true' || false;
// 	return canUpdate;
// }

export async function GetLoginStatistics(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<StatisticMonthLogInItem[]> {
	return GetData(`${apiUris(api)}admin_statistics`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || [];
}

export async function GetSlidesStatistics(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<StatisticSlideItem[]> {
	return GetData(`${apiUris(api)}admin_statistics?slides=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || [];
}

export async function GetTemplatesStatistics(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<StatisticTemplatesItem[]> {
	return GetData(`${apiUris(api)}admin_statistics?templates=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || [];
}

//New Templates
export async function GetTemplatesList(accessToken: string, domain: string, api: string, headers: TemplateApiHeaders, cancelToken?: CancelToken): Promise<TemplateListItem[]> {
	return GetData(`${apiUris(api)}admin_templates`,
		{
			'Authorization': 'Bearer ' + accessToken,
			'Domain': domain,
			'API': api,
			'TEMPLATES-VERSION': headers.templateVersion,
			'TEMPLATES-TYPE': headers.templateType,
			'TEMPLATES-ENVIRON': headers.templateEnviron,
			'MY-GROUPS': headers.myGroups,
			'GROUP-SELECTION': headers.groupSelection,
			'SHOW-PATH': headers.showPath!,
		}, true, cancelToken!)
}

export async function GetTemplatesFolders(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<TemplateFolderItem[]> {
	return GetData(`${apiUris(api)}admin_templates?folder=true`, {
		'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api
	}, true, cancelToken!)
}

export async function GetTemplateId(accessToken: string, domain: string, api: string, id: string, headers: TemplateApiHeaders, cancelToken?: CancelToken): Promise<string> {
	return GetData(`${apiUris(api)}admin_templates/${id}`,
		{
			'Authorization': 'Bearer ' + accessToken,
			'Domain': domain,
			'API': api,
			'TEMPLATES-VERSION': headers.templateVersion,
			'TEMPLATES-TYPE': headers.templateType,
			'TEMPLATES-ENVIRON': headers.templateEnviron,
			'MY-GROUPS': headers.myGroups,
			'GROUP-SELECTION': headers.groupSelection,
		}, false, cancelToken!)
}

export async function IsTemplateAdmin(accessToken: string, domain: string, api: string, hashedUser: string, cancelToken?: CancelToken): Promise<FunctionItem[]> {
	return GetData(`${apiUris(api)}admin_templates?CheckAdmin=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'MY-USER': hashedUser }, true, cancelToken!)
}


//Icons
export async function GetIconsList(accessToken: string, domain: string, api: string, templateVersion: string, myGroups?: string, groupSelection?: string, cancelToken?: CancelToken): Promise<IconListItem[]> {
	return GetData(`${apiUris(api)}admin_icons`,
		{
			'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api,
			'TEMPLATES-VERSION': templateVersion,
			'MY-GROUPS': myGroups!,
			'GROUP-SELECTION': groupSelection!,
		}, true, cancelToken!)
}

export async function GetIconsFileList(accessToken: string, domain: string, api: string, templateVersion: string, cancelToken?: CancelToken): Promise<FileInfo[]> {
	return GetData(`${apiUris(api)}admin_icons?fileList=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'TEMPLATES-VERSION': templateVersion }, true, cancelToken!)
}

export async function GetIconsFile(accessToken: string, domain: string, api: string, filename: string, templateVersion: string, cancelToken?: CancelToken): Promise<string> {
	return GetData(`${apiUris(api)}admin_icons?fileName=${filename}`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'TEMPLATES-VERSION': templateVersion }, false, cancelToken!)
}

export async function GetIconsUpdate(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<boolean> {
	return GetData(`${apiUris(api)}admin_icons?CheckUpdate=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, false, cancelToken!)
		.then((value) => {
			return value === 'true'
		})
}

//Slides
export async function GetSlidesList(accessToken: string, domain: string, api: string, templateVersion: string, myGroups: GroupID[], groupSelection: string, presentationContent: string, cancelToken?: CancelToken): Promise<SlideListItem[]> {
	return GetData(`${apiUris(api)}admin_slides`,
		{
			'PRESENTATION-CONTENT': presentationContent,
			'Authorization': 'Bearer ' + accessToken,
			'Domain': domain,
			'API': api,
			'TEMPLATES-VERSION': templateVersion,
			'MY-GROUPS': JSON.stringify(myGroups),
			'GROUP-SELECTION': groupSelection,
		}, true, cancelToken!)
}

export async function GetPresentationList(accessToken: string, domain: string, api: string, templateVersion: string, myGroups: GroupID[], groupSelection: string, cancelToken?: CancelToken): Promise<PresentationListItem[]> {
	return GetData(`${apiUris(api)}admin_slides`,
		{
			'PRESENTATION-CONTENT': 'ALL-PRESENTATIONS',
			'Authorization': 'Bearer ' + accessToken,
			'Domain': domain,
			'API': api,
			'TEMPLATES-VERSION': templateVersion,
			'MY-GROUPS': JSON.stringify(myGroups),
			'GROUP-SELECTION': groupSelection,
		}, true, cancelToken!)
}

export async function GetSlidesPresentation(accessToken: string, domain: string, api: string, filename: string, templateVersion: string, cancelToken?: CancelToken): Promise<string> {
	return GetData(`${apiUris(api)}admin_slides?fileName=${filename}`,
		{
			'Authorization': 'Bearer ' + accessToken,
			'Domain': domain,
			'API': api,
			'TEMPLATES-VERSION': templateVersion,
		}, false, cancelToken!)
}

//Pictures
export async function GetPictureLayoutList(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<PictureSrcLib[]> {
	return GetData(`${apiUris(api)}admin_pictures?imgSrcList=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || ExampleLayoutList
}

// export async function GetPicturesByLayout(accessToken: string, layout: string, cancelToken?: CancelToken): Promise<PictureListItem[]> {
// 	return GetData(`${apiUris(api)}admin_pictures?imgSrc=${layout}`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || examplePictureList
// }

export async function GetPicturesByPath(accessToken: string, domain: string, api: string, path: string, cancelToken?: CancelToken): Promise<PictureListItem[]> {
	return GetData(`${apiUris(api)}admin_pictures?path=${path}`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || examplePictureList
}

export async function GetPictureSizes(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<PictureSrcLib[]> {
	return GetData(`${apiUris(api)}admin_pictures?SrcLibDef=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || ExampleLayoutList
}

export async function GetPictureCount(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<number> {
	return GetData(`${apiUris(api)}admin_pictures?imgCount=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!) || 0
}

export async function GetPicture(accessToken: string, domain: string, api: string, id: string, cancelToken?: CancelToken, previewOption?: string): Promise<string> {
	try {
		return JSON.parse(await GetData(`${apiUris(api)}admin_pictures/${id}`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, ...(previewOption && { 'PREVIEW': previewOption! }) }, false, cancelToken!))
	} catch (error) {
		return ''
	}
}

//CustomData
export async function GetCustomData(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<CustomDataItem[]> {
	return GetData(`${apiUris(api)}admin_customdata`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!)
}

export async function GetCustomDataById(accessToken: string, domain: string, api: string, dataId: string, cancelToken?: CancelToken): Promise<CustomDataItem> {
	return GetData(`${apiUris(api)}admin_customdata?DataID=${dataId}`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!)
}

export async function GetCustomDataDef(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<CustomDataDefItem> {
	return GetData(`${apiUris(api)}admin_customdata?def=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!)
}

export async function GetCustomPlainList(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<CustomDataPlainListItem[]> {
	return GetData(`${apiUris(api)}admin_customdata?plaindatalist=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'ONLY-FIRST': 'TRUE' }, true, cancelToken!)
}

//Sections
export async function GetSectionsList(accessToken: string, domain: string, api: string, templateVersion: string, cancelToken?: CancelToken): Promise<SectionsDocument[]> {
	return GetData(`${apiUris(api)}admin_docbuilder?list=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'TEMPLATES-VERSION': templateVersion }, true, cancelToken!)
}

export async function GetSectionsDoc(accessToken: string, domain: string, api: string, filename: string, templateVersion: string, cancelToken?: CancelToken): Promise<string> {
	return GetData(`${apiUris(api)}admin_docbuilder?filename=${filename}`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'TEMPLATES-VERSION': templateVersion }, false, cancelToken!)
}

//Emailsignatures
export async function GetEmailSignatures(accessToken: string, domain: string, api: string, signatureVersion: string, cancelToken?: CancelToken): Promise<EmailSignature[]> {
	return GetData(`${apiUris(api)}admin_emailsignatures`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'ALL-SIGNATURES': 'TRUE', 'EMAIL-SIGNATURE-VERSION': signatureVersion }, true, cancelToken!)
}

export async function GetEmailSignaturePictures(accessToken: string, domain: string, api: string, signatureVersion: string, cancelToken?: CancelToken): Promise<EmbeddedPictureItem[]> {
	return GetData(`${apiUris(api)}admin_emailsignatures?pictures=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'EMAIL-SIGNATURE-VERSION': signatureVersion }, true, cancelToken!)
}

//Word Content
export async function IsWordContentAdmin(accessToken: string, domain: string, api: string, templateVersion: string, hashedUser: string, cancelToken?: CancelToken): Promise<boolean> {
	return GetData(`${apiUris(api)}admin_wordcontents?CheckAdmin=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'TEMPLATES-VERSION': templateVersion, 'MY-USER': hashedUser }, true, cancelToken!)
}

export async function GetWordContentFolders(accessToken: string, domain: string, api: string, templateVersion: string, hashedUser: string, cancelToken?: CancelToken): Promise<ContentFolder[]> {
	return GetData(`${apiUris(api)}admin_wordcontents?Folder=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'TEMPLATES-VERSION': templateVersion, 'MY-USER': hashedUser }, true, cancelToken!)
}

export async function GetWordContent(accessToken: string, domain: string, api: string, templateVersion: string, search: string, cancelToken?: CancelToken): Promise<ContentItem[]> {
	return GetData(`${apiUris(api)}admin_wordcontents`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'TEMPLATES-VERSION': templateVersion, 'MY-SEARCH': search }, true, cancelToken!)
}

export async function GetWordContentById(accessToken: string, domain: string, api: string, contentId: string, templateVersion: string, cancelToken?: CancelToken): Promise<ContentItem> {
	return GetData(`${apiUris(api)}admin_wordcontents?ContentID=${contentId}`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, 'TEMPLATES-VERSION': templateVersion, }, true, cancelToken!)
}

//Groups
export async function GetGroups(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<GroupInfo[]> {
	return GetData(`${apiUris(api)}admin_groups`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, true, cancelToken!)
}

//Users
export async function GetUser(accessToken: string, tenantDomains?: string, cancelToken?: CancelToken): Promise<UserAdminItem> {
	return GetData(`${adminApiUris()}admin_users`, { 'Authorization': 'Bearer ' + accessToken, 'MY-DOMAINS': tenantDomains ? tenantDomains : '' }, true, cancelToken!)
}

export async function GetAccountsCSV(accessToken: string, api: string, cancelToken?: CancelToken): Promise<string> {
	const data: string = await GetData(`${adminApiUris()}admin_users?AllAccounts=true`, { 'Authorization': 'Bearer ' + accessToken, 'API': api, 'GET-AS-CSV': true }, true, cancelToken!) || []
	return data
}

// export async function VerifyUser(accesstoken: string, tenantDomains: string, cancelToken?: CancelToken): Promise<boolean> {
// 	return GetData(`${adminApiUris()}users?VerifyUser=true`, { 'Authorization': `Bearer ${accesstoken}`, 'MY-DOMAINS': tenantDomains }, false, cancelToken!)
// }

export async function GetAllUsers(accessToken: string, allDomains?: boolean, cancelToken?: CancelToken): Promise<UserAdminItem[]> {
	return GetData(`${adminApiUris()}admin_users?allusers=true`, { 'Authorization': 'Bearer ' + accessToken, 'ALL-DOMAINS': allDomains ? 'TRUE' : '' }, true, cancelToken!)
}

export async function GetUserIsAdmin(accessToken: string, cancelToken?: CancelToken): Promise<boolean> {
	return GetData(`${adminApiUris()}admin_users?isAdmin=true`, { 'Authorization': 'Bearer ' + accessToken }, false, cancelToken!)
}

export async function GetDomainContent(accessToken: string, api: string, cancelToken?: CancelToken): Promise<DomainContent[]> {
	const data: DomainContent[] = await GetData(`${adminApiUris()}admin_domains?api=${api}`, { 'Authorization': 'Bearer ' + accessToken }, true, cancelToken!) || []
	return data
}

export async function GetDomainExtensions(accessToken: string, api: string, cancelToken?: CancelToken): Promise<SettingsShort[]> {
	const data: SettingsShort[] = await GetData(`${adminApiUris()}admin_domains?AllProducts=true`, { 'Authorization': 'Bearer ' + accessToken, 'API': api }, true, cancelToken!) || []
	return data
}

export async function GetDomainsCSV(accessToken: string, api: string, cancelToken?: CancelToken): Promise<string> {
	const data: string = await GetData(`${adminApiUris()}admin_domains?AllProducts=true`, { 'Authorization': 'Bearer ' + accessToken, 'API': api, 'GET-AS-CSV': true }, true, cancelToken!) || []
	return data
}

//UserData
export async function GetUserData(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<UserInfoItem[]> {
	return GetData(`${apiUris(api)}admin_userdata`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, }, true, cancelToken!)
}

export async function GetAllUserData(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<UserInfoItem[]> {
	return GetData(`${apiUris(api)}admin_userdata?ad=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, }, true, cancelToken!)
}

//GroupedData
export async function GetAllCombinedData(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<GroupedData[]> {
	return GetData(`${apiUris(api)}admin_userdata?groupedData=true`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, }, true, cancelToken!)
}

export async function GetCombinedDataById(accessToken: string, id: string, domain: string, api: string, cancelToken?: CancelToken): Promise<GroupedData> {
	return GetData(`${apiUris(api)}admin_userdata?groupedData=true&id=${id}`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api, }, true, cancelToken!)
}

//Redirect
export async function GetRedirects(accessToken: string, targetDomain: string, api: string, cancelToken?: CancelToken): Promise<RedirectItem[]> {
	return GetData(`${adminApiUris()}admin_domains?redirect=true&targetdomain=${targetDomain}`, { 'Authorization': 'Bearer ' + accessToken, 'API': api, }, true, cancelToken!)
}

//News
export async function GetAllNews(accessToken: string, newsVersion: 'TEST' | 'PROD', cancelToken?: CancelToken): Promise<NewsItem[]> {
	return GetData(`${adminApiUris()}admin_news`, { 'Authorization': 'Bearer ' + accessToken, 'NEWS-VERSION': newsVersion }, true, cancelToken!)
}

//Logo
export async function GetLogo(accessToken: string, domain: string, api: string, cancelToken?: CancelToken): Promise<string> {
	try {
		return JSON.parse(await GetData(`${apiUris(api)}admin_products?application=logo`, { 'Authorization': 'Bearer ' + accessToken, 'Domain': domain, 'API': api }, false, cancelToken!))
	} catch (error) {
		return ''
	}

}

//Academy
export async function GetAllAcademyItems(accessToken: string, api: string, cancelToken?: CancelToken): Promise<AcademyItem[]> {
	return GetData(`${apiUris(api)}admin_academy`, { 'Authorization': 'Bearer ' + accessToken, 'API': api }, true, cancelToken!)
}

export async function GetAcademyList(accessToken: string, api: string, language: string, cancelToken?: CancelToken): Promise<AcademyPlatformItems[]> {
	return GetData(`${apiUris(api)}admin_academy?simple=true&language=${language}`, { 'Authorization': 'Bearer ' + accessToken, 'API': api }, true, cancelToken!)
}

export async function GetAcademyItem(accessToken: string, api: string, id: string, cancelToken?: CancelToken): Promise<AcademyItem> {
	return GetData(`${apiUris(api)}admin_academy?id=${id}&a=true&b=true`, { 'Authorization': 'Bearer ' + accessToken, 'API': api }, true, cancelToken!)
}

export async function GetAcademyLearningLevels(accessToken: string, api: string, cancelToken?: CancelToken): Promise<AcademyLearningLevel[]> {
	return GetData(`${apiUris(api)}admin_academy?list=learninglevels`, { 'Authorization': 'Bearer ' + accessToken, 'API': api }, true, cancelToken!)
}

export async function GetAcademyPlatforms(accessToken: string, api: string, cancelToken?: CancelToken): Promise<AcademyPlatform[]> {
	return GetData(`${apiUris(api)}admin_academy?list=platforms`, { 'Authorization': 'Bearer ' + accessToken, 'API': api }, true, cancelToken!)
}

export async function GetAcademyLevels(accessToken: string, api: string, cancelToken?: CancelToken): Promise<AcademyLevel[]> {
	return GetData(`${apiUris(api)}admin_academy?list=levels`, { 'Authorization': 'Bearer ' + accessToken, 'API': api }, true, cancelToken!)
}

//GPT
export async function GetGptToken(accessToken: string, cancelToken?: CancelToken): Promise<string> {
	return GetData(`${adminApiUris()}admin_gpt`, { 'Authorization': 'Bearer ' + accessToken }, false, cancelToken!)
}


export async function GetData(url: string, headers: AxiosRequestHeaders, isObject: boolean, cancelToken?: CancelToken): Promise<any> {
	let myData: any
	const config: AxiosRequestConfig = {
		url: url,
		method: 'GET',
		headers: headers,
		cancelToken: cancelToken!,
	}
	// console.log(config)
	await axios(config)
		.then(async (response) => {
			myData = isObject ? response.data : JSON.stringify(response.data)
		})
		.catch((requestError) => {
			console.log(requestError)
			console.log("ERROR");
		});
	return myData
}
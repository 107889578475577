export const checkValidToken = (token: string): boolean => {
	try {
		const claims = JSON.parse(atob(token.split('.')[1]));
		// console.log(claims.exp - (Math.floor(Date.now() / 1000)))
		return claims.exp - (Math.floor(Date.now() / 1000)) > 0
	} catch (error) {
		return false
	}
}

export const upnFromToken = (token: string): string => {
	try {
		const claims = JSON.parse(atob(token.split('.')[1]));
		return (claims.upn as string).toLowerCase()
	} catch (error) {
		return ""
	}
}

import { DefaultButton, Stack, Text } from '@fluentui/react';
import { ExtensionItem } from '../../interfaces/ExtensionItem';
import Parser from 'html-react-parser';
import React from 'react';
import { GraphContext, TenantContext } from '../../contexts/ContextWrapper';
import EncapsulatedSVG from '../common/EncapsultedSVG';

interface ExtensionItemProps {
    item: ExtensionItem;
    status: string;
    added: boolean;
    allExtensions: ExtensionItem[]; // Assuming you have this prop
}


const ExtensionComponent: React.FC<ExtensionItemProps> = (props) => {

    const svgString: string = `data:image/svg+xml,${encodeURIComponent(props.allExtensions?.find(v => v.ExtensionName === props.item.ExtensionName)?.ImageString ? props.allExtensions?.find(v => v.ExtensionName === props.item.ExtensionName)?.ImageString! : '')}`

    const { tenantContext } = React.useContext(TenantContext)
    const { graphContext } = React.useContext(GraphContext)

    return (
        <div key={props.item.ExtensionName} style={{ position: 'relative' }}>
            <Stack key={props.item.ExtensionName} verticalAlign="start" tokens={{ childrenGap: '5px' }} styles={{ root: { width: '80%', minWidth: '250px', height: '100%', padding: '10px', border: '1px solid #e1dfdd', backgroundColor: '#faf9f8' } }}>
                <Stack.Item align="center">
                    <EncapsulatedSVG>
                        <img width='100%' height='100%' src={svgString} style={{maxHeight: 48}} alt={props.item.ExtensionName} />
                    </EncapsulatedSVG>
                </Stack.Item>
                <Stack.Item align="center" >
                    <Stack horizontal tokens={{ childrenGap: '5px' }}>
                        <Text className="ms-fontSize-18 ms-fontWeight-semibold">{props.item.ExtensionName}</Text>
                    </Stack>
                </Stack.Item>
                <Stack.Item align="center">
                    <Stack horizontal tokens={{ childrenGap: '5px' }}>
                        {props.item.ExistInWord && <div className="ms-BrandIcon--icon16 ms-BrandIcon--word" />}
                        {props.item.ExistInPowerPoint && <div className="ms-BrandIcon--icon16 ms-BrandIcon--powerpoint" />}
                        {props.item.ExistInExcel && <div className="ms-BrandIcon--icon16 ms-BrandIcon--excel" />}
                        {props.item.ExistInOutlook && <div className="ms-BrandIcon--icon16 ms-BrandIcon--outlook" />}
                    </Stack>
                </Stack.Item>
                <Stack.Item align="center" styles={{ root: { height: '110px', lineHeight: '110%' } }}>
                    <Text variant='small'>
                        {Parser(`${props.allExtensions?.find(v => v.ExtensionName === props.item.ExtensionName)?.Description}`)}
                    </Text>
                </Stack.Item>
                <Stack.Item align="center" >
                    {props.status === 'new' && <div style={{ position: 'absolute', top: '10px', left: '10px', borderRadius: '10px', backgroundColor: '#eec73a', padding: '3px 15px 3px 15px', fontSize: '10px', fontWeight: 'bold' }}>NEW!</div>}
                    {props.status === 'comingsoon' && <div style={{ position: 'absolute', top: '10px', left: '10px', borderRadius: '10px', backgroundColor: '#a8d4ba', padding: '3px 10px 3px 10px', fontSize: '10px', fontWeight: 'bold' }}>COMING SOON!</div>}
                </Stack.Item>
                <Stack.Item align="center" >
                    <Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }}>
                        <DefaultButton
                            text={"Contact me"}
                            iconProps={{ iconName: "Mail" }}
                            onClick={() => {
                                let anchor: HTMLAnchorElement = document.createElement("a");
                                anchor.href = 'mailto:info@rehngruppen.se?subject=TemplateExtender - ' + tenantContext.settings.CompanyName + ' - ' + props.item.ExtensionName + '&body=Hello, I\'m interested in ' + props.item.ExtensionName + ' for TemplateExtender. Please contact me for further information. %0A%0ABest regards,%0A' + graphContext.userDetails.displayName;
                                anchor.click(); //Downloaded file
                            }}
                        />
                    </Stack>
                </Stack.Item>
            </Stack>
        </div>
    );
};

export default ExtensionComponent;
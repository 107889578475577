import * as React from 'react';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import {
	CommandBar,
	FontIcon,
	ICommandBarItemProps,
	SelectionMode,
	ShimmeredDetailsList,
	IColumn,
	CheckboxVisibility
} from '@fluentui/react';
import { DetailsListLayoutMode, Selection, } from 'office-ui-fabric-react/lib/DetailsList';
import { hasValues, sortArray } from '../../helpers/MiscFunctions';
import { createNewUser, deleteUser, updateUser } from '../../helpers/PostToAdminApi';
import { MessageBarType, Stack } from 'office-ui-fabric-react';
import { DialogEditUser } from '../dialogs/DialogEditUser';
import { DialogDelete } from '../dialogs/OldDialogDelete';
import { DialogOkOnly } from '../dialogs/OldDialogOkOnly';
import SettingsCompany from '../../helpers/SettingsCompany';
import { MessageContextType } from '../../interfaces/ContextType';
import FloatingButton from '../common/FloatingButton';


export interface AccountsListProps {
	setMessage: (x: MessageContextType) => void;
	updateUsers(): void;
	settings: SettingsCompany
	accesstoken: string,
	domain: string,
	api: string,
	userData: UserAdminItem,
	users: UserAdminItem[];
	canChangeTenant: boolean;
}

export interface AccountsListState {
	// items: UserAdminItem[];
	selectedItem: UserAdminItem;
	filteredItems: UserAdminItem[];
	selection: Selection;
	columns: IColumn[];
	// domainToken: string,
	// apiName: string,
	newUserItem: UserAdminItem;
	hideNewUserDialog: boolean;
	hideEditUserDialog: boolean;
	hideNoSelectDialog: boolean;
	hideUserIsAdminDialog: boolean;
	hideDeleteDialog: boolean;
	hideUserExist: boolean;
	newUserConfirmed: boolean;
	editUserConfirmed: boolean
	deleteConfirmed: boolean;
}



export default class AccountsList extends React.Component<AccountsListProps, AccountsListState> {

	private _selection: Selection;

	private _onColumnClick = (_ev: React.MouseEvent<HTMLElement>, column: IColumn): void => {
		const { columns, filteredItems } = this.state;
		const newColumns: IColumn[] = columns.slice();
		const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
		newColumns.forEach((newCol: IColumn) => {
			if (newCol === currColumn) {
				currColumn.isSortedDescending = !currColumn.isSortedDescending;
				currColumn.isSorted = true;
				// this.setState({
				//   announcedMessage: `${currColumn.name} is sorted ${
				// 	currColumn.isSortedDescending ? 'descending' : 'ascending'
				//   }`,
				// });
			} else {
				newCol.isSorted = false;
				newCol.isSortedDescending = true;
			}
		});
		const newItems = sortArray(filteredItems, currColumn.fieldName!, currColumn.isSortedDescending);
		this.setState({
			columns: newColumns,
			filteredItems: newItems,
		});
	};

	isTemplateAdminColumn: IColumn[] = this.props.settings.Extensions?.find((val) => val.ExtensionName === 'Templates') ? [{
		key: 'column4',
		name: 'Templates',
		fieldName: 'isTemplateAdmin',
		minWidth: 70,
		maxWidth: 70,
		isResizable: false,
		onColumnClick: this._onColumnClick,
		onRender: (item: UserAdminItem) => { return item.isTemplateAdmin ? <FontIcon iconName="CheckMark" /> : null },
	}] : []

	isPictureAdminColumn: IColumn[] = this.props.settings.Extensions?.find((val) => val.ExtensionName === 'Pictures') ? [{
		key: 'column5',
		name: 'Pictures',
		fieldName: 'isPictureAdmin',
		minWidth: 70,
		maxWidth: 70,
		isResizable: false,
		onColumnClick: this._onColumnClick,
		onRender: (item: UserAdminItem) => { return item.isPictureAdmin ? <FontIcon iconName="CheckMark" /> : null },
	}] : []

	isIconAdminColumn: IColumn[] = this.props.settings.Extensions?.find((val) => val.ExtensionName === 'Icons') ? [{
		key: 'column6',
		name: 'Icons',
		fieldName: 'isIconAdmin',
		minWidth: 50,
		maxWidth: 50,
		isResizable: false,
		onColumnClick: this._onColumnClick,
		onRender: (item: UserAdminItem) => { return item.isIconAdmin ? <FontIcon iconName="CheckMark" /> : null },
	}] : []

	isSlideAdminColumn: IColumn[] = this.props.settings.Extensions?.find((val) => val.ExtensionName === 'Slides') ? [{
		key: 'column7',
		name: 'Slides',
		fieldName: 'isSlideAdmin',
		minWidth: 50,
		maxWidth: 50,
		isResizable: false,
		onColumnClick: this._onColumnClick,
		onRender: (item: UserAdminItem) => { return item.isSlideAdmin ? <FontIcon iconName="CheckMark" /> : null },
	}] : []

	isContentsAdminColumn: IColumn[] = this.props.settings.Extensions?.find((val) => val.ExtensionName === 'Contents') ? [{
		key: 'column8',
		name: 'Contents',
		fieldName: 'isContentAdmin',
		minWidth: 70,
		maxWidth: 70,
		isResizable: false,
		onColumnClick: this._onColumnClick,
		onRender: (item: UserAdminItem) => { return item.isContentAdmin ? <FontIcon iconName="CheckMark" /> : null },
	}] : []

	isSectionsAdminColumn: IColumn[] = this.props.settings.Extensions?.find((val) => val.ExtensionName === 'Sections') ? [{
		key: 'column9',
		name: 'Sections',
		fieldName: 'isSectionsAdmin',
		minWidth: 70,
		maxWidth: 70,
		isResizable: false,
		onColumnClick: this._onColumnClick,
		onRender: (item: UserAdminItem) => { return item.isSectionsAdmin ? <FontIcon iconName="CheckMark" /> : null },
	}] : []

	isEmailSignatureAdminColumn: IColumn[] = this.props.settings.Extensions?.find((val) => val.ExtensionName === 'Sections') ? [{
		key: 'column10',
		name: 'Email signature',
		fieldName: 'isEmailSignatureAdmin',
		minWidth: 100,
		maxWidth: 100,
		isResizable: false,
		onColumnClick: this._onColumnClick,
		onRender: (item: UserAdminItem) => { return item.isEmailSignatureAdmin ? <FontIcon iconName="CheckMark" /> : null },
	}] : []

	changeTenantDomainColumn: IColumn[] = this.props.canChangeTenant && this.props.domain === 'rehngruppen.se' ? [{
		key: 'column100',
		name: 'Tenant',
		fieldName: 'changeTenantDomain',
		minWidth: 150,
		maxWidth: 300,
		isResizable: true,
	}] : []

	constructor(props: AccountsListProps) {
		super(props);
		this._selection = new Selection({
			onSelectionChanged: () => this.setState({ selection: this._selection }),
		});
		this.state = {
			filteredItems: [],
			selectedItem: {} as UserAdminItem,
			selection: this._selection,
			newUserItem: {} as UserAdminItem,
			hideNewUserDialog: true,
			hideEditUserDialog: true,
			hideNoSelectDialog: true,
			hideUserIsAdminDialog: true,
			hideDeleteDialog: true,
			hideUserExist: true,
			newUserConfirmed: false,
			editUserConfirmed: false,
			deleteConfirmed: false,
			columns: [{ key: 'column1', name: '', minWidth: 16, }],
		};
		this.boundSetState = this.setState.bind(this)
	}

	boundSetState = (x: any) => { this.setState(x) }

	getColums(): IColumn[] {
		return [
			{
				key: 'column1',
				name: 'Username',
				fieldName: 'User',
				minWidth: 150,
				maxWidth: 300,
				isSorted: true,
				isSortedDescending: false,
				isResizable: true,
				onColumnClick: this._onColumnClick,
			},
			{
				key: 'column2',
				name: 'Admin',
				fieldName: 'isSettingsAdmin',
				minWidth: 50,
				maxWidth: 50,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				onRender: (item: UserAdminItem) => { return item.isSettingsAdmin ? <FontIcon iconName="CheckMark" /> : <></> },
			},
			{
				key: 'column3',
				name: 'Contributor',
				fieldName: 'isContributorAdmin',
				minWidth: 80,
				maxWidth: 80,
				isResizable: true,
				onColumnClick: this._onColumnClick,
				onRender: (item: UserAdminItem) => { return item.isContributorAdmin ? <FontIcon iconName="CheckMark" /> : <></> },
			},
			...this.isTemplateAdminColumn!,
			...this.isIconAdminColumn!,
			...this.isPictureAdminColumn!,
			...this.isSlideAdminColumn!,
			...this.isContentsAdminColumn!,
			...this.isSectionsAdminColumn!,
			...this.isEmailSignatureAdminColumn!,
			...this.changeTenantDomainColumn!
		]
	}

	commandItems = (): ICommandBarItemProps[] => [
		{
			key: 'newField',
			text: 'New account',
			iconProps: { iconName: 'Add' },
			onClick: () => this._NewUserPrompt(),
		},
		{
			key: 'edit',
			text: 'Edit',
			disabled: this._selection.count === 0,
			iconProps: { iconName: 'Edit' },
			onClick: () => this._EditUserPrompt(),
		},
		{
			key: 'delete',
			text: 'Delete',
			disabled: this._selection.count === 0,
			iconProps: { iconName: 'Delete' },
			onClick: () => this._deletePrompt(),
		},
	];

	componentDidUpdate(prevProps: Readonly<AccountsListProps>, prevState: Readonly<AccountsListState>, _snapshot?: any): void {
		if (prevProps.users !== this.props.users ||
			prevProps.domain !== this.props.domain ||
			(prevState.filteredItems && !this.state.filteredItems)) {
			const newItems: UserAdminItem[] = (this.props.users && this.props.users) ? sortArray(this.props.users.filter(user => user.Domains.filter(value => (value.DomainName.toLowerCase() === this.props.domain.toLowerCase() && value.APIName.toLowerCase() === this.props.api.toLowerCase())).length > 0), 'User', false) : []
			this.setState({
				columns: this.getColums(),
				filteredItems: newItems,
				selectedItem: {} as UserAdminItem,
				selection: this._selection,
			})
		}
		if (this.state.deleteConfirmed) {
			this._deleteSelectedUsers(this.props.accesstoken)
		}
		if (this.state.newUserConfirmed) {
			this._newUser(this.props.accesstoken)
		}
		if (this.state.editUserConfirmed) {
			this._editUser(this.props.accesstoken)
		}
	}

	componentDidMount(): void {
		this.setState({
			columns: this.getColums(),
			filteredItems: sortArray(this.props.users.filter(user => user.Domains.filter(value => (value.DomainName.toLowerCase() === this.props.domain.toLowerCase() && value.APIName.toLowerCase() === this.props.api.toLowerCase())).length > 0), 'User', false),
			selectedItem: {} as UserAdminItem,
			selection: this._selection,
		})
	}

	render(): JSX.Element {

		return (
			<Stack className='Width100'>
				<span className='Indent20'><h3>Accounts</h3></span>
				<CommandBar
					items={this.commandItems()}
					ariaLabel="Use left and right arrow keys to navigate between commands"
				/>
				<ShimmeredDetailsList
					items={this.state.filteredItems}
					columns={this.state.columns}
					enableShimmer={!hasValues(this.state.filteredItems)}
					setKey="set"
					layoutMode={DetailsListLayoutMode.justified}
					selectionPreservedOnEmptyClick={true}
					selectionMode={SelectionMode.single}
					ariaLabelForSelectionColumn="Toggle selection"
					ariaLabelForSelectAllCheckbox="Toggle selection for all items"
					ariaLabelForShimmer="Content is being fetched"
					ariaLabelForGrid="Item details"
					checkButtonAriaLabel="Row checkbox"
					selection={(this._selection)}
					checkboxVisibility={CheckboxVisibility.always}
					onActiveItemChanged={this._onActiveItemChanged}
				/>
				<DialogEditUser
					setParentState={this.boundSetState}
					hidden={this.state.hideNewUserDialog}
					hideState='hideNewUserDialog'
					confirmState='newUserConfirmed'
					newUserItem='newUserItem'
					domains={this.props.userData!?.Domains}
					apis={this.props.userData!?.API}
					defaultApi={this.props.api}
					defaultDomain={this.props.domain}
					selectedItem={{} as UserAdminItem}
					canChangeTenant={this.props.canChangeTenant}
					settings={this.props.settings}
					isNew={true}
				/>
				<DialogEditUser
					setParentState={this.boundSetState}
					hidden={this.state.hideEditUserDialog}
					hideState='hideEditUserDialog'
					confirmState='editUserConfirmed'
					newUserItem='newUserItem'
					domains={this.props.userData!?.Domains}
					apis={this.props.userData!?.API}
					defaultApi={this.props.api}
					defaultDomain={this.props.domain}
					selectedItem={this.state.selectedItem}
					canChangeTenant={this.props.canChangeTenant && this.props.domain === 'rehngruppen.se'}
					settings={this.props.settings}
					isNew={false}
				/>
				<DialogDelete
					setParentState={this.boundSetState}
					hidden={this.state.hideDeleteDialog}
					hideState='hideDeleteDialog'
					confirmState='deleteConfirmed'
					filename={` user ${this.state.selectedItem.User}`}
				/>
				<DialogOkOnly
					setParentState={this.boundSetState}
					hidden={this.state.hideNoSelectDialog}
					hideState='hideNoSelectDialog'
					title='Nothing selected'
					text='You have to select a user to use this option.'
				/>
				<DialogOkOnly
					setParentState={this.boundSetState}
					hidden={this.state.hideUserIsAdminDialog}
					hideState='hideUserIsAdminDialog'
					title='User is super admin'
					text='You cannot edit a user that is a super admin.'
				/>
				<DialogOkOnly
					setParentState={this.boundSetState}
					hidden={this.state.hideUserExist}
					hideState='hideUserExist'
					title='User exist'
					text='This username already exist.'
				/>
				<FloatingButton
					hidden={false}
					iconName='Add'
					tooltip='New account'
					right='200px'
					onClick={() => this._NewUserPrompt()}
				/>
				<FloatingButton
					hidden={this._selection.count === 0}
					iconName='Edit'
					tooltip='Edit'
					right='120px'
					onClick={() => this._EditUserPrompt()}
				/>
				<FloatingButton
					hidden={this._selection.count === 0}
					iconName='Delete'
					tooltip='Delete'
					right='40px'
					onClick={() => this._deletePrompt()}
				/>
			</Stack>
		);
	}



	private _deletePrompt() {
		if (this._selection.count === 0 || typeof this.state.selectedItem.User === 'undefined') {
			this.setState({ hideNoSelectDialog: false })
		}
		else if (this.state.selectedItem.isAdmin) {
			this.setState({ hideUserIsAdminDialog: false })
		}
		else {
			this.setState({ hideDeleteDialog: false })
		}
	}

	private _deleteSelectedUsers(accessToken: string) {
		if (true) {
			let i: number;
			for (i = 0; i < this._selection.getSelection().length; i++) {
				deleteUser(accessToken, (this._selection.getSelection()[0] as UserAdminItem).User)
					.then(async (response) => {
						setTimeout(() => {
							this.setState({
								filteredItems: [],
								// hideDeleteDialog: true,
								deleteConfirmed: false,
							})
							this.props.setMessage({ message: response.data, messageType: response.status === 200 ? MessageBarType.success : MessageBarType.error, visible: true })
							this.props.updateUsers()
						}, 500)
					})
			}
		}
	}

	private _newUser(accessToken: string) {
		createNewUser(
			accessToken,
			JSON.stringify(this.state.newUserItem)
		)
			.then(async (response) => {
				if (response.status === 200) {
					setTimeout(() => {
						this.setState({
							filteredItems: [],
							hideNewUserDialog: true,
							newUserConfirmed: false,
							newUserItem: {} as UserAdminItem,
						})
						this.props.updateUsers()
						this.props.setMessage({ message: response.data, messageType: MessageBarType.success, visible: true })
					}, 500)
				}
				else if (response.status === 405) {
					setTimeout(() => {
						this.setState({
							hideUserExist: false,
							newUserConfirmed: false,
							hideNewUserDialog: true,
							newUserItem: {} as UserAdminItem,
						})
						this.props.setMessage({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
					}, 500)
				}
				else {
					setTimeout(() => {
						this.setState({
							newUserConfirmed: false,
							hideNewUserDialog: true,
							newUserItem: {} as UserAdminItem,
						})
						this.props.setMessage({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
					}, 500)
				}
			});
	}

	private _editUser(accessToken: string) {
		updateUser(
			accessToken,
			JSON.stringify(this.state.newUserItem)
		)
			.then(async (response) => {
				if (response.status === 200) {
					setTimeout(() => {
						this.setState({
							filteredItems: [],
							hideEditUserDialog: true,
							editUserConfirmed: false,
							newUserItem: {} as UserAdminItem,
						})
						this.props.updateUsers()
						this.props.setMessage({ message: response.data, messageType: MessageBarType.success, visible: true })
					}, 500)
				}
				else {
					setTimeout(() => {
						this.setState({
							hideEditUserDialog: true,
							editUserConfirmed: false,
							newUserItem: {} as UserAdminItem,
						})
						this.props.setMessage({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true })
					}, 500)
				}
			});
	}

	private _NewUserPrompt() {
		this.setState({ hideNewUserDialog: false })
	}

	private _EditUserPrompt() {
		if (this._selection.count === 0 || typeof this.state.selectedItem.User === 'undefined') {
			this.setState({ hideNoSelectDialog: false })
		}
		// else if (this.state.selectedItem.isAdmin) {
		// 	this.setState({hideUserIsAdminDialog: false})
		// }
		else {
			this.setState({ hideEditUserDialog: false })
		}
	}

	private _onActiveItemChanged = (item?: UserAdminItem): void => {
		// console.log(item?.User)
		this.setState({ selectedItem: item! })
	}

}

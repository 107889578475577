import { FontIcon, getTheme, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import { TenantContext } from '../../contexts/ContextWrapper';

export interface ExtensionsOverviewProps {

}

const theme = getTheme();

const ExtensionsOverview: React.FC<ExtensionsOverviewProps> = () => {

    const { tenantContext } = React.useContext(TenantContext);

    if (tenantContext.settings.Extensions === undefined) { return <></> }

    return (
        <Stack.Item styles={{ root: { padding: 20,maxWidth: 340 } }}>
            <Stack tokens={{ childrenGap: '10px' }}>
                <Text variant="xLarge">Extensions</Text>

                <table className='table-overview' width='310px'>
                    <tbody>
                        <tr>
                            <td width='150px'></td>
                            <td width='40px'><div className="ms-BrandIcon--icon16 ms-BrandIcon--powerpoint"></div></td>
                            <td width='40px'><div className="ms-BrandIcon--icon16 ms-BrandIcon--word"></div></td>
                            <td width='40px'><div className="ms-BrandIcon--icon16 ms-BrandIcon--excel"></div></td>
                            <td width='40px'><div className="ms-BrandIcon--icon16 ms-BrandIcon--outlook"></div></td>
                        </tr>
                        {tenantContext.settings.Extensions!?.map((extension) => {
                            if (!extension.ActivateExcel && !extension.ActivateOutlook && !extension.ActivatePowerPoint && !extension.ActivateWord) { return <></> }
                            return (
                                <tr key={extension.ExtensionName}>
                                    <td>{extension.ExtensionName}</td>
                                    <td>
                                        {(extension.PowerPointDesktop || extension.PowerPointOnline) && <FontIcon iconName="CheckMark" />}
                                        {!extension.ActivatePowerPoint && extension.ExistInPowerPoint && <FontIcon iconName="Blocked" />}
                                        {extension.ActivatePowerPoint && !extension.PowerPointDesktop && !extension.PowerPointOnline && <FontIcon iconName="CirclePause" />}
                                    </td>
                                    <td>
                                        {(extension.WordDesktop || extension.WordOnline) && <FontIcon iconName="CheckMark" />}
                                        {!extension.ActivateWord && extension.ExistInWord && <FontIcon iconName="Blocked" />}
                                        {extension.ActivateWord && !extension.WordDesktop && !extension.WordOnline && <FontIcon iconName="CirclePause" />}
                                    </td>
                                    <td>
                                        {(extension.ExcelDesktop || extension.ExcelOnline) && <FontIcon iconName="CheckMark" />}
                                        {!extension.ActivateExcel && extension.ExistInExcel && <FontIcon iconName="Blocked" />}
                                        {extension.ActivateExcel && !extension.ExcelDesktop && !extension.ExcelOnline && <FontIcon iconName="CirclePause" />}
                                    </td>
                                    <td>
                                        {(extension.OutlookDesktop || extension.OutlookOnline) && <FontIcon iconName="CheckMark" />}
                                        {!extension.ActivateOutlook && extension.ExistInOutlook && <FontIcon iconName="Blocked" />}
                                        {extension.ActivateOutlook && !extension.OutlookDesktop && !extension.OutlookOnline && <FontIcon iconName="CirclePause" />}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <Stack horizontal styles={{ root: { color: theme.palette.neutralSecondary } }} tokens={{ childrenGap: '10px' }}>
                    <Stack.Item><FontIcon iconName="CheckMark" /> Enabled</Stack.Item>
                    <Stack.Item><FontIcon iconName="CirclePause" /> Not activated</Stack.Item>
                    <Stack.Item><FontIcon iconName="Blocked" /> Not ordered</Stack.Item>
                </Stack>
            </Stack>
        </Stack.Item >
    );
};

export default ExtensionsOverview;
/* eslint-disable react/prop-types */
import * as React from "react"
import { Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton } from "@fluentui/react";

export interface DialogOkAbortProps {
	callbackOk: (checked?: boolean) => void,
	callbackAbort: () => void,
	hidden: boolean,
	title?: string,
	description?: string
	checkBoxText?: string
	checkBoxDefaultValue?: boolean
}


export const DialogOkAbort: React.FunctionComponent<DialogOkAbortProps> = (props): JSX.Element => {

	const [checkboxValue, setCheckboxValue] = React.useState<boolean>(props.checkBoxDefaultValue || false);

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: props.title,
		subText: props.description,
	};

	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 450 } },
	};

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.callbackAbort()}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			{props.checkBoxText &&
				<Checkbox
					label={props.checkBoxText}
					checked={checkboxValue}
					onChange={(_ev, checked) => setCheckboxValue(!!checked)}
				/>}
			<DialogFooter>
				<PrimaryButton onClick={() => props.callbackOk(checkboxValue)} text="Ok" />
				<DefaultButton onClick={() => props.callbackAbort()} text="Abort" />
			</DialogFooter>
		</Dialog>
	);
};
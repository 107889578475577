import * as React from "react"
import { DatePicker, DefaultButton, Dialog, DialogFooter, DialogType, PrimaryButton, TextField, Toggle } from "@fluentui/react"
import { EmailSignature } from "../../interfaces/EmailSignature";
import { DayOfWeek } from "office-ui-fabric-react";
import { isInvalidDate } from "../../helpers/DateTime";
import ErrorMessage from "../common/ErrorMessage";

export interface DialogEditSignatureProps {
	callbackOk: (signature: EmailSignature) => void,
	callbackAbort: () => void,
	hidden: boolean,
	selectedItem: EmailSignature,
	status: 'new' | 'edit' | 'duplicate' | 'hide',
}

export const DialogEditSignature: React.FunctionComponent<DialogEditSignatureProps> = (props) => {

	// const [hasItems, setHasItems] = React.useState(false);
	const [signatureName, setSignatureName] = React.useState<string>('');
	const [isDefault, setIsDefault] = React.useState<boolean>(false);
	const [isReply, setIsReply] = React.useState<boolean>(false);
	const [isTimeBound, setIsTimeBound] = React.useState<boolean>(false);
	const [startDate, setStartDate] = React.useState<Date | string>(new Date());
	const [endDate, setEndDate] = React.useState<Date | string>('');

	React.useEffect(() => {
		if (props.status === 'hide') { }
		if (props.status === "new") {
			setSignatureName('');
			setIsDefault(false)
			setIsReply(false)
			setIsTimeBound(false)
		}
		else {
			setSignatureName(props.selectedItem.SignatureName);
			setIsDefault(props.selectedItem.DefaultSignature)
			setIsReply(props.selectedItem.ReplySignature)
			setIsTimeBound(!isInvalidDate(new Date(props.selectedItem.StartTime)))
			setStartDate(new Date(props.selectedItem.StartTime))
			setEndDate(new Date(props.selectedItem.EndTime))
		}
	}, [props])

	if (props.status === 'hide') {
		return <></>
	}

	const dialogContentProps = {
		type: DialogType.largeHeader,
		title: props.status === 'new' ? 'Create new signature' : props.status === 'edit' ? 'Edit selected signature' : 'Duplicate selected signature',
		// subText: props.isNew ? '' : '',
	};

	const modalProps = {
		isBlocking: false,
		styles: { main: { maxWidth: 600 } },
	};

	return (
		<Dialog
			hidden={props.hidden}
			onDismiss={() => props.callbackAbort()}
			dialogContentProps={dialogContentProps}
			modalProps={modalProps}
		>
			<TextField
				label='Signature name'
				value={signatureName}
				disabled={props.status === 'edit'}
				onChange={(_event, value) => setSignatureName(value!)}
			/>
			<Toggle
				label="Default signature"
				id="default"
				onText="On"
				offText="Off"
				inlineLabel
				checked={isDefault}
				onChange={(_ev, checked) => { setIsDefault(checked!) }}
			/>
			<Toggle
				label="Reply signature"
				id="reply"
				onText="On"
				offText="Off"
				inlineLabel
				checked={isReply}
				onChange={(_ev, checked) => { setIsReply(checked!) }}

			/>
			<Toggle
				label="Time bound"
				id="reply"
				onText="On"
				offText="Off"
				inlineLabel
				checked={isTimeBound}
				onChange={(_ev, checked) => {
					setIsTimeBound(checked!)
					if (checked) {
						setStartDate(new Date())
						setEndDate('')
					}
				}}
			/>
			<ErrorMessage message='Time bound must be either Default or Reply' isVisible={isTimeBound && !isDefault && !isReply} />
			{isTimeBound && <>
				<DatePicker
					label="Start date"
					firstDayOfWeek={DayOfWeek.Monday}
					showMonthPickerAsOverlay={true}
					placeholder="Select start date..."
					ariaLabel="Select start date"
					value={startDate && !isInvalidDate(startDate) ? new Date(startDate) : undefined}
					onSelectDate={(date) => date && setStartDate(date!.toLocaleDateString('sv-SE'))}
					formatDate={(date) => date ? new Date(date).toLocaleDateString('sv-SE') : ''}
				/>
				<DatePicker
					label="End date"
					firstDayOfWeek={DayOfWeek.Monday}
					showMonthPickerAsOverlay={true}
					placeholder="Select end date..."
					ariaLabel="Select end date"
					value={endDate && !isInvalidDate(endDate) ? new Date(endDate) : undefined}
					onSelectDate={(date) => date && setEndDate(date!.toLocaleDateString('sv-SE'))}
					formatDate={(date) => date ? new Date(date).toLocaleDateString('sv-SE') : ''}
				/>
			</>}
			<DialogFooter>
				<PrimaryButton
					disabled={isTimeBound && ((!isDefault && !isReply) || !startDate || !endDate)}
					onClick={() => props.callbackOk({
						...props.selectedItem,
						SignatureName: signatureName,
						DefaultSignature: isDefault,
						ReplySignature: isReply,
						StartTime: isTimeBound ? startDate : '',
						EndTime: isTimeBound ? endDate : '',
					})} text={props.status === 'new' ? "Create" : "Update"}
				/>
				<DefaultButton onClick={() => props.callbackAbort()} text="Cancel" />
			</DialogFooter>
		</Dialog>
	);
};

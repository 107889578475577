
import * as React from 'react';
import { DefaultButton, SpinnerSize, Stack, Text } from '@fluentui/react';
import { GetAccountsCSV, GetDomainsCSV } from '../../helpers/GetFromRestApi';
import Axios, { CancelTokenSource } from 'axios';
import { AuthContext } from '../../contexts/AuthContext';
import { Spinner } from 'office-ui-fabric-react';
import { TenantContext } from '../../contexts/ContextWrapper';

export interface CustomersExportStatProps {

}

const CustomersExport: React.FC<CustomersExportStatProps> = () => {
    const [accountsCSV, setAccountsCSV] = React.useState<string>('');
    const [domainCSV, setDomainCSV] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const ourRequest = React.useRef<CancelTokenSource>(Axios.CancelToken.source());

    const { accessToken } = React.useContext(AuthContext)
    const { tenantContext } = React.useContext(TenantContext)

    const updateAccounts = async () => {
        ourRequest.current = Axios.CancelToken.source();
        GetAccountsCSV(accessToken!, tenantContext.api, ourRequest.current.token)
            .then((users) => {
                setAccountsCSV(users)
            })
    };

    const updateDomains = async () => {
        ourRequest.current = Axios.CancelToken.source();
        GetDomainsCSV(accessToken!, tenantContext.api, ourRequest.current.token)
            .then((csv) => {
                setDomainCSV(csv)
            })
    };

    React.useEffect(() => {
        if (accessToken) {
            setIsLoading(true);
            Promise.all([updateAccounts(), updateDomains()])
                .finally(() => setIsLoading(false));
        }
        return () => {
            try {
                ourRequest.current.cancel();
            } catch (error) {
                // Handle cancellation error if necessary
            }
        };
    }, [accessToken]);

    const downloadEmailsAsCsv = () => {
        downloadCSV(accountsCSV, '365TemplateExtender - Accounts.csv')
    }

    const downloadCompaniesAsCsv = () => {
        downloadCSV(domainCSV, '365TemplateExtender - Customers.csv')
    }

    const downloadCSV = (csv: string, filename: string) => {
        const bom = '\uFEFF'; // UTF-8 Byte Order Mark (BOM)
        const csvWithBom = bom + csv; // Prepend BOM to the CSV string
        const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' }); // Specify charset=utf-8
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a); // Append the element to the body to ensure compatibility
        a.click();
        document.body.removeChild(a); // Clean up by removing the element
        window.URL.revokeObjectURL(url);
    };


    return (
        <Stack styles={{ root: { padding: '20px' } }} tokens={{ childrenGap: '10px' }}>
            {isLoading && <Spinner size={SpinnerSize.large} label="Loading..." />}
            {!isLoading && (accountsCSV.length > 0 || domainCSV.length > 0) &&
                <>
                    <Text variant='medium'>Export data as CSV</Text>
                    <Stack horizontal verticalAlign='center' tokens={{ childrenGap: '10px' }}>
                        {accountsCSV.length > 0 && <DefaultButton
                            iconProps={{ iconName: 'ExcelLogo16' }}
                            onClick={downloadEmailsAsCsv}
                        >
                            Export Accounts
                        </DefaultButton>}
                        {domainCSV.length > 0 && <DefaultButton
                            iconProps={{ iconName: 'ExcelLogo16' }}
                            onClick={downloadCompaniesAsCsv}
                        >
                            Export Customers
                        </DefaultButton>}
                    </Stack>
                </>
            }
        </Stack>
    );
};

export default CustomersExport;

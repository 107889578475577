
import * as React from 'react';
import { Stack, Text } from '@fluentui/react';
import { ContentFrame } from '../common/ContentFrame';
import CustomersFeatures from './CustomersFeatures';
import CustomersExport from './CustomersExport';

export interface CompaniesProps {

}

const Customers: React.FC<CompaniesProps> = () => {
   
    return (
        <Stack>
            <Stack.Item className="" styles={{ root: { margin: '30px' } }}>
                <Text variant="xxLarge">Customers</Text>
            </Stack.Item>     
            <ContentFrame>
                <CustomersFeatures/>
            </ContentFrame>       
            <ContentFrame>
                <CustomersExport/>
            </ContentFrame>
        </Stack>
    );
};

export default Customers;

import { Stack } from '@fluentui/react';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import UserData from './components/userdata/UserData';
import Domains from './components/domains/Domains';
import EmailSignatures from './components/emailsignature/EmailSignatures';
import Groups from './components/groups/Groups';
import Icons from './components/icons/Icons';
import IconsAdmin from './components/icons/IconsAdmin';
import Pictures from './components/pictures/Pictures';
import Slides from './components/slides/Slides';
import Statistics from './components/statistics/Statistics';
import Templates from './components/templates/Templates';
import Accounts from './components/accounts/Accounts';
import WordContent from './components/wordcontent/WordContent';
import Settings from './components/settings/Settings';
import { GraphContext, MessageContext, TenantContext, UserContext } from './contexts/ContextWrapper';
import CustomData from './components/customdata/CustomData';
import { useSettingsFetch } from './hooks/useSettingsFetch';
import { useUserFetch } from './hooks/useUserFetch';
import HomeTenant from './components/home/HomeTenant';
import { MessageContextType } from './interfaces/ContextType';
import { GetMyMenuItems } from './helpers/MenuHelper';
import { getNavigationAddress } from './helpers/NavigationHelper';
import { checkValidToken } from './helpers/LogginHelper';
import News from './components/news/News';
import { AuthContext } from './contexts/AuthContext';
import Sections from './components/sections/Sections';
import Academy from './components/academy/Academy';
import Customers from './components/customers/Customers';


interface MainPageProps {
	setSelectedPage: (x: string) => void;
}

const MainPage: React.FC<MainPageProps> = (props) => {

	const { page } = useParams<'page'>();

	const { tenantContext } = React.useContext(TenantContext);
	const { messageContext, setMessageContext } = React.useContext(MessageContext);
	const { userContext, setUserContext } = React.useContext(UserContext);
	const { graphContext} = React.useContext(GraphContext);

	const { accessToken, scopes, refreshToken, handleLogin } = React.useContext(AuthContext);
	const { UpdateSettings } = useSettingsFetch()
	const { FetchUser } = useUserFetch()

	const navigate = useNavigate();

	let main: JSX.Element = (<></>)

	React.useEffect(() => {
		if (!tenantContext.settings) {
			UpdateSettings(tenantContext.api, tenantContext.tenantName)
		}
	}, [])

	const myPages: string[] = React.useMemo(() => {
		return GetMyMenuItems(userContext.userData, tenantContext.settings, graphContext.adminMode)
	}, [userContext.userData, tenantContext.settings, graphContext.adminMode])

	React.useEffect(() => {
		if (page && myPages.includes(page.toLowerCase())) {
			props.setSelectedPage(page!)
			localStorage.setItem('selectedPage', page!)
		}
		else {
			navigate(getNavigationAddress(tenantContext, userContext, 'home'))
		}
		setMessageContext({ ...messageContext, visible: false })
	}, [page, myPages])



	const UpdateMessage = React.useCallback((message: MessageContextType) => {
		setMessageContext({ ...messageContext, message: message.message, messageType: message.messageType, visible: message.visible });
	}, [messageContext, setMessageContext]);

	// if (page && !myPages.includes(page.toLowerCase())) {
	// 	navigate(`/${tenantContext.api.toLowerCase()}/${tenantContext.tenantName.toLowerCase()}/home`)
	// }

	console.log('MainPage.tsx: useEffect: checkValidToken')
	if (!checkValidToken(accessToken!)) { refreshToken() }

	switch (page) {
		case '':
		case 'home':
			main = (
				<HomeTenant />
			)
			break;
		case 'settings':
			main = (
				<Settings />
			)
			break;
		case 'templates':
			main = (
				<Templates
					setMessage={(message) => { UpdateMessage(message) }}
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
					groupIds={tenantContext.groupIds}
					companyGroups={tenantContext.companyGroups}
					settings={tenantContext.settings}
				/>
			)
			break;
		case 'icons':
			main = (
				<Icons
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
					groupIds={tenantContext.groupIds}
					settings={tenantContext.settings}
					userData={userContext.userData}
				/>
			)
			break;
		case 'iconsadmin':
			main = (
				<IconsAdmin
					setMessage={(message) => { UpdateMessage(message) }}
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
				/>
			)
			break;
		case 'pictures':
			main = (
				<Pictures
					setMessage={(message) => { UpdateMessage(message) }}
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
					settings={tenantContext.settings}
				/>
			)
			break;
		case 'slides':
			main = (
				<Slides />
			)
			break;
		case 'wordcontents':
			main = (
				<WordContent
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
					groupIds={tenantContext.groupIds}
					companyGroups={tenantContext.companyGroups}
					settings={tenantContext.settings}
				/>
			)
			break;
		case 'sections':
			main = (
				<Sections />
			)
			break;
		case 'emailsignatures':
			main = (
				<EmailSignatures />
			)
			break;
		case 'customdata':
			main = (
				<CustomData
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
					groupIds={tenantContext.groupIds}
					settings={tenantContext.settings}
				/>
			)
			break;
		case 'userdata':
			main = (
				<UserData
					updateSettings={() => UpdateSettings(tenantContext.api, tenantContext.tenantName)}
					settings={tenantContext.settings}
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
				/>
			)
			break;
		case 'accounts':
			main = (
				<Accounts
					setMessage={(message) => { UpdateMessage(message) }}
					userData={userContext.userData}
					setUserData={(userData) => { setUserContext({ ...userContext, userData: userData }) }}
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
					canChangeTenant={tenantContext.settings.enableTestDomain}
					settings={tenantContext.settings}
				/>
			)
			break;
		case 'groups':
			main = (
				<Groups
					getCompanyGroups={() => { }} //TODO
					setNewScope={(scope) => { handleLogin(scope) }}
					scopes={scopes}
					accessToken={accessToken!}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
					allGroups={tenantContext.allGroups}
					companyGroups={tenantContext.companyGroups}
					isUsersTenant={tenantContext.MyTenant}
				/>
			)
			break;
		case 'statistics':
			main = (
				<Statistics />
			)
			break;
		case 'domains':
			main = (
				<Domains
					setMessage={(message) => { UpdateMessage(message) }}
					updateUserData={() => { FetchUser(accessToken!) }}
					accessToken={accessToken!}
					userData={userContext.userData}
					domain={tenantContext.tenantName}
					api={tenantContext.api}
				/>
			)
			break;
		case 'news':
			main = (
				<News />
			)
			break;
		case 'customers':
			main = (
				<Customers />
			)
			break;
		case 'academy':
			main = (
				<Academy />
			)
			break;
		default:
			main = (<></>)
			break;
	}

	return (
		<Stack.Item className="ms-Grid-col ms-bgColor-gray10" styles={{ root: { height: '100%' } }}>
			{main}
		</Stack.Item>
	);
}

export default MainPage


import { createTheme, ITheme } from '@fluentui/react';

export const ExcelTheme: ITheme = createTheme({
    palette: {
        themePrimary: '#217346',
        themeLighterAlt: '#f2f9f5',
        themeLighter: '#cee9da',
        themeLight: '#a8d5bc',
        themeTertiary: '#62ab83',
        themeSecondary: '#318456',
        themeDarkAlt: '#1e673f',
        themeDark: '#195735',
        themeDarker: '#134027',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});

export const PowerPointTheme: ITheme = createTheme({
    palette: {
        themePrimary: '#b7472a',
        themeLighterAlt: '#fcf6f4',
        themeLighter: '#f4dcd6',
        themeLight: '#eabeb4',
        themeTertiary: '#d48672',
        themeSecondary: '#c0583e',
        themeDarkAlt: '#a53f26',
        themeDark: '#8c3620',
        themeDarker: '#672718',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});

export const WordTheme: ITheme = createTheme({
    palette: {
        themePrimary: '#2b579a',
        themeLighterAlt: '#f4f7fb',
        themeLighter: '#d3deef',
        themeLight: '#b0c3e0',
        themeTertiary: '#6e90c2',
        themeSecondary: '#3d66a5',
        themeDarkAlt: '#274e8a',
        themeDark: '#214274',
        themeDarker: '#183156',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});

export const OutlookTheme: ITheme = createTheme({
    palette: {
        themePrimary: '#0078d4',
        themeLighterAlt: '#f3f9fd',
        themeLighter: '#d0e7f8',
        themeLight: '#a9d3f2',
        themeTertiary: '#5ca9e5',
        themeSecondary: '#1a86d9',
        themeDarkAlt: '#006cbe',
        themeDark: '#005ba1',
        themeDarker: '#004377',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});

export const SunflowerTheme: ITheme = createTheme({
    palette: {
        themePrimary: '#e99b00',
        themeLighterAlt: '#fefbf4',
        themeLighter: '#fbeed3',
        themeLight: '#f8dfae',
        themeTertiary: '#f1c160',
        themeSecondary: '#eba61c',
        themeDarkAlt: '#d18b00',
        themeDark: '#b07600',
        themeDarker: '#825700',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralSecondaryAlt: '#8a8886',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});

export const CoboltTheme: ITheme = createTheme({
    palette: {
        themePrimary: '#0000ff',
        themeLighterAlt: '#f5f5ff',
        themeLighter: '#d6d6ff',
        themeLight: '#b3b3ff',
        themeTertiary: '#6666ff',
        themeSecondary: '#1f1fff',
        themeDarkAlt: '#0000e6',
        themeDark: '#0000c2',
        themeDarker: '#00008f',
        neutralLighterAlt: '#faf9f8',
        neutralLighter: '#f3f2f1',
        neutralLight: '#edebe9',
        neutralQuaternaryAlt: '#e1dfdd',
        neutralQuaternary: '#d0d0d0',
        neutralTertiaryAlt: '#c8c6c4',
        neutralTertiary: '#a19f9d',
        neutralSecondary: '#605e5c',
        neutralSecondaryAlt: '#8a8886',
        neutralPrimaryAlt: '#3b3a39',
        neutralPrimary: '#323130',
        neutralDark: '#201f1e',
        black: '#000000',
        white: '#ffffff',
    }
});
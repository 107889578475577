import { Dropdown, IDropdownOption, Stack } from '@fluentui/react';
import * as React from 'react';
import { APIItem } from '../../interfaces/APIItem';
import { DomainItem } from '../../interfaces/DomainItem';
import { GraphContext, TenantContext, UserContext } from '../../contexts/ContextWrapper';
import { useNavigate } from 'react-router-dom';
import { useCallback, useContext, useEffect, useState } from 'react';
import NavigationButtonAdmin from '../navigation/NavigationButtonAdmin';

interface SelectionsProps {
	loggedIn: boolean
}

const Selections: React.FC<SelectionsProps> = (props) => {

	const { tenantContext, setTenantContext } = useContext(TenantContext);
	const { userContext } = useContext(UserContext);
	const { graphContext } = React.useContext(GraphContext);
	const navigate = useNavigate();

	const [selectedDomain, setSelectedDomain] = useState<number>(userContext.userData!?.Domains!?.filter(data => tenantContext.api.toLowerCase() === data.APIName.toLowerCase())!?.findIndex((d) => d.APIName.toLowerCase() === tenantContext.api.toLowerCase() && d.DomainName.toLowerCase() === tenantContext.tenantName.toLowerCase()) || 0);
	const [selectedApi, setSelectedApi] = useState<number>(userContext.userData!?.API!?.findIndex((a) => a.APIName.toLowerCase() === tenantContext.api.toLowerCase()) || 0);

	const backColor = (): string => {
		try {
			switch (userContext.userData.API[selectedApi].APIName.toLowerCase()) {
				case 'test':
					return '#e3f0e1'
				case 'dev':
					return '#fbe4e4'
				default:
					return '#f3f2f1'
			}
		} catch (error) {
			return '#f3f2f1'
		}
	}


	const apiOptions = useCallback(() => {
		return userContext.userData.API!?.map((item: APIItem, idx) => {
			return ({
				key: idx,
				text: item.APIName,
				data: item.APIUri,
			})
		})
	}, [userContext.userData.API])

	const domainOptions = useCallback(() => {
		return userContext.userData.Domains!?.filter(data => tenantContext.api.toLowerCase() === data.APIName.toLowerCase())!?.map((item: DomainItem, idx) => {
			return ({
				key: idx,
				text: `${item.CompanyName} (${item.DomainName})`,
				data: item,
			})
		})
	}, [userContext.userData.Domains, tenantContext.api])

	useEffect(() => {
		setSelectedDomain(userContext.userData.Domains!?.filter(data => tenantContext.api.toLowerCase() === data.APIName.toLowerCase()).findIndex((d) => d.APIName.toLowerCase() === tenantContext.api.toLowerCase() && d.DomainName.toLowerCase() === tenantContext.tenantName.toLowerCase()) || 0)
		setSelectedApi(userContext.userData.API!?.findIndex((a) => a.APIName.toLowerCase() === tenantContext.api.toLowerCase()) || 0)
	}, [tenantContext.api, tenantContext.tenantName, userContext.userData.API, userContext.userData.Domains])

	const onChangeDomain = async (option: IDropdownOption) => {
		setSelectedDomain(option.key as number)
		console.log(option.data)
		setTenantContext({ ...tenantContext, MyTenant: (option.data as DomainItem).MyTenant, tenantName: option.data.DomainName })
		const page: string = localStorage.getItem('selectedPage') || 'home'
		navigate(`/${(option.data as DomainItem)!?.APIName.toLowerCase()}/${option.data.DomainName.toLowerCase()}/${page}`)
	};

	const onChangeApi = async (option: IDropdownOption) => {
		setSelectedApi(option.key as number)
		setSelectedDomain(0)
		// const firstDomain: string = domainOptions().length > 0 ? domainOptions()[0].data.DomainName.toLowerCase() : ''
		// for firstdomain, first check if the tenantcontect.tenantname is in the userdata.domains with the selected api, option.text otherwise use the first domain in userdata.domains with the selected api
		const domain: string = userContext.userData.Domains!?.filter(data => option.text.toLowerCase() === data.APIName.toLowerCase())!?.find((d) => d.DomainName.toLowerCase() === tenantContext.tenantName.toLowerCase())?.DomainName.toLowerCase() || userContext.userData.Domains!?.filter(data => option.text.toLowerCase() === data.APIName.toLowerCase())![0].DomainName.toLowerCase()
		setTenantContext({ ...tenantContext, api: option.text, apiUri: option.data, tenantName: domain })
		const page: string = localStorage.getItem('selectedPage') || 'home'
		if (domain) {
			navigate(`/${option.text.toLowerCase()}/${domain}/${page}`)
		}
	};

	if (!props.loggedIn) {
		return <></>
	}

	if (!userContext.userData.Domains || !userContext.userData.API) {
		return <></>
	}

	const DomainDropdown: JSX.Element = (
		<Dropdown
			placeholder="Select domain"
			aria-label="Domain selection dropdown"
			options={domainOptions()}
			selectedKey={selectedDomain}
			styles={{ root: { width: 360, maxWidth: '90%' } }}
			onChange={(_ev, opt) => onChangeDomain(opt!)}
		/>
	)

	const ApiDropdown: JSX.Element = (
		<Dropdown
			placeholder="Select API"
			aria-label="Domain selection dropdown"
			options={apiOptions()}
			selectedKey={selectedApi}
			styles={{ root: { width: 100, maxWidth: '90%' } }}
			onChange={(_ev, opt) => onChangeApi(opt!)}
		/>
	)

	return (
		<Stack horizontal wrap horizontalAlign="end" styles={{ root: { padding: '10px', backgroundColor: backColor() } }} tokens={{ childrenGap: 15 }}>
			{graphContext.adminMode && apiOptions()!?.length > 1 ? ApiDropdown : null}
			{(graphContext.adminMode || !userContext.userData.isRehngruppen) && domainOptions()!?.length > 1 ? DomainDropdown : null}
			{graphContext.adminMode && <NavigationButtonAdmin selectPage={(page) => {
				navigate(`/${tenantContext.api.toLowerCase()}/${tenantContext.tenantName.toLowerCase()}/${page}`)
			}} />}
		</Stack>
	)
}
export default Selections
import { ExtensionItem } from "../interfaces/ExtensionItem";
import { FunctionItem } from "../interfaces/FunctionItem";
import { ManifestItem } from "../interfaces/ManifestItem";

export interface UserInfoItem {
    field: string;
    label: string[];
    store: string;
    value?: string;
    item: string;
    subItem?: string;
    hidden: boolean;
    mask?: string;
}

export interface UserInfoCategory {
    category: string[];
    items: UserInfoItem[];
}

export interface UserInfoExtended extends UserInfoItem {
    key: string,
    labelSwe: string,
    labelEng: string,
}

export default interface SettingsCompany {
    Domain: string
    CompanyName: string
    Caption: string[]
    hideLogo: boolean
    hideCaption: boolean
    UserInformation: UserInfoCategory[] | null
    LogoBase64: String
    Extensions: ExtensionItem[]
    Functions: FunctionItem[]
    Manifest: ManifestItem[]
    ManifestOutlook: ManifestItem[]
    RibbonIcon16: string
    RibbonIcon32: string
    RibbonIcon80: string
    RibbonOutlookIcon16: string
    RibbonOutlookIcon32: string
    RibbonOutlookIcon80: string
    enableTestDomain: boolean
    enableRedirect: boolean
    UniqueManifestID: boolean
    InternalUse: boolean
}

export const exampleInfoList: UserInfoExtended[] = [{
    field: 'No items',
    hidden: false,
    item: '',
    key: '0',
    label: [''],
    labelEng: '',
    labelSwe: '',
    store: '',
}]

export function GetUseDynamicForms(settings: SettingsCompany): boolean {
    if (!settings.Functions) { return false }
    return settings.Functions.find(item => item.FunctionName === 'Dynamic Forms')?.EnableFunction!;
}

export function GetUseUserInformation(settings: SettingsCompany): boolean {
    if (!settings.Functions) { return false }
    return settings.Functions.find(item => item.FunctionName === 'User Information')?.EnableFunction!;
}

export function GetUseGroups(settings: SettingsCompany): boolean {
    if (!settings.Functions) { return false }
    return settings.Functions.find(item => item.FunctionName === 'Microsoft 365 Groups')?.EnableFunction!;
}

export function GetUseCustomData(settings: SettingsCompany): boolean {
    if (!settings.Functions) { return false }
    return settings.Functions.find(item => item.FunctionName === 'Custom Data')?.EnableFunction!;
}

export function GetMaxPictureCount(settings: SettingsCompany): number {
    if (!settings.Extensions) { return 250 }
    return parseInt(settings.Extensions.find((item) => item.ExtensionName === 'Pictures')?.ExtraFields?.find((item) => item.Title === 'Max Picture Count')?.Value!) || 250
}

export function GetSectionsCheckAll(settings: SettingsCompany): boolean {
    if (!settings.Functions) { return false }
    return settings.Extensions.find(item => item.ExtensionName === 'Sections')?.ExtraFields?.find(item => item.Title === 'Default Check All')?.Value.toLowerCase() === 'true';
}

export function GetSectionsHideIndex(settings: SettingsCompany): boolean {
    if (!settings.Functions) { return false }
    return settings.Extensions.find(item => item.ExtensionName === 'Sections')?.ExtraFields?.find(item => item.Title === 'Default Hide Index')?.Value.toLowerCase() === 'true';
}

export function GetSectionsIgnoreHierarchy(settings: SettingsCompany): boolean {
    if (!settings.Functions) { return false }
    return settings.Extensions.find(item => item.ExtensionName === 'Sections')?.ExtraFields?.find(item => item.Title === 'Default Ignore Hierarchy')?.Value.toLowerCase() === 'true';
}

export function GetSectionsHeadingLevels(settings: SettingsCompany): number[] {
    if (!settings.Extensions) { return [] }
    let levels = settings.Extensions.find(item => item.ExtensionName === 'Sections')?.ExtraFields?.find(item => item.Title === 'Default Paragraph Outline Levels')?.Value || '';
    return levels.split(',').map((item) => { return parseInt(item) });
}
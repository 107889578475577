import * as React from 'react';
import { ActionButton, ContextualMenu, ContextualMenuItemType, Dropdown, DropdownMenuItemType, FontIcon, IContextualMenuItem, IDropdownOption, IPersonaSharedProps, Persona, PersonaSize, Stack, Text, Toggle, TooltipHost } from '@fluentui/react';
import { useNavigate } from 'react-router-dom';
import HeaderLogo from './HeaderLogo';
import { GraphContext, TenantContext, UserContext } from '../../contexts/ContextWrapper';
import { DomainItem } from '../../interfaces/DomainItem';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import { updateUser } from '../../helpers/PostToAdminApi';
import { AuthContext } from '../../contexts/AuthContext';
import SettingsShort from '../../interfaces/SettingsShort';
import { GetDomainExtensions } from '../../helpers/GetFromRestApi';

interface HeaderProps {
	logout: () => void
	loggedIn: boolean
}

const Header: React.FC<HeaderProps> = (props) => {

	const [showContextualMenu, setShowContextualMenu] = React.useState(false);

	const { accessToken } = React.useContext(AuthContext)
	const { tenantContext } = React.useContext(TenantContext)
	const { graphContext, setGraphContext } = React.useContext(GraphContext)
	const { userContext, setUserContext } = React.useContext(UserContext)

	const [settings, setSettings] = React.useState<SettingsShort[]>([]);
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const linkRef = React.useRef(null);
	const navigate = useNavigate();

	const onShowContextualMenu = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
		ev.preventDefault(); // don't navigate
		setShowContextualMenu(true);
	}, []);

	const onHideContextualMenu = React.useCallback(() => setShowContextualMenu(false), []);

	const updateUsers = async () => {
		setIsLoading(true);
		const allDomains = await GetDomainExtensions(accessToken!, tenantContext.api);
		setSettings(allDomains)
		setIsLoading(false);
	};

	React.useEffect(() => {
		if (accessToken && tenantContext.api) {
			updateUsers();
		}
	}, [accessToken, tenantContext.api]);

	const changeTenantOptions: IDropdownOption[] = React.useMemo(() => {
		if (isLoading) return ([{ key: 'loading', text: 'Loading...' }] as IDropdownOption[])
		if (!userContext.userData!?.Domains) return ([] as IDropdownOption[])
		const clearOpt: IDropdownOption[] = userContext.userData.changeTenantEnabled ? [{ key: 'clear', text: 'Clear' }] : []
		return [
			{ key: 'header', text: 'Change tenant', itemType: DropdownMenuItemType.Header },
			...clearOpt,
			{ key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
			...userContext.userData.Domains!?.filter(data => data.APIName.toLowerCase() === 'prod')!?.map((item: DomainItem) => {
				return ({
					key: item.DomainName,
					text: `${item.CompanyName} (${item.DomainName})`,
					ariaLabel: `${item.CompanyName} (${item.DomainName})`,
					data: settings.find((setting) => setting.CompanyName === item.CompanyName),
				}) as IDropdownOption
			})
		]
	}, [userContext.userData, isLoading, settings]);

	const changeTenantHandler = React.useCallback((_ev: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined) => {
		if (!item || !accessToken) return
		let userData: UserAdminItem = { ...userContext.userData }
		if (item.key === 'clear') { userData = { ...userData, changeTenantEnabled: false, changeTenantDomain: '' } }
		else {
			userData = { ...userData, changeTenantEnabled: true, changeTenantDomain: item.key as string }
		}
		updateUser(
			accessToken!,
			JSON.stringify(userData),
		)
		setUserContext({ ...userContext, userData: userData })
		if (item.key !== 'clear') {
			navigate(`/prod/${item.key}/home`)
		}
	}, [userContext])

	const onRenderChangeTenantOption = (item: IDropdownOption | undefined) => {
		if (!item) return null
		return (
			<Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign='center' horizontalAlign='space-between' styles={{ root: { width: '100%' } }}>
				<span>{item.text}</span>
				{(item.data as SettingsShort)!?.UniqueManifestID ?
					<TooltipHost content='Denna kund har ett eget manifest - Kontakta utvecklingsavdelningen för mer hjälp'>
						<FontIcon iconName="DocumentManagement" />
					</TooltipHost>
					: null}
			</Stack	>
		)
	}


	const changeTenantMenu: IContextualMenuItem[] = React.useMemo(() => {
		if (!userContext.userData!?.isRehngruppen) return []
		return [
			{
				key: 'divider_2',
				itemType: ContextualMenuItemType.Divider,
			},
			{
				key: 'adminMode',
				text: 'Admin',
				onRender: () => {
					return (
						<Toggle
							label="Admin"
							onText="On"
							offText="Off"
							checked={graphContext.adminMode}
							onChange={(_ev, checked) => setGraphContext({ ...graphContext, adminMode: checked! })}
							styles={{ root: { margin: 5 } }}
						/>
					)
				}
			},
			{
				key: 'changeTenant',
				text: 'Change tenant',
				onRender: () => {
					return (
						<Dropdown
							placeholder="Change tenant"
							label="Change tenant"
							options={changeTenantOptions}
							onRenderOption={onRenderChangeTenantOption}
							defaultSelectedKey={userContext.userData!?.changeTenantDomain}
							onChange={changeTenantHandler}
							styles={{ root: { margin: 5, minWidth: 300 } }}
						/>
					)
				},
			}
		]
	}, [userContext.userData, graphContext.adminMode, changeTenantOptions, settings, isLoading])

	const menuItems: IContextualMenuItem[] = React.useMemo(() => {
		if (!userContext.userData) return []
		return [
			{
				key: 'topbuttons',
				onRender: () => {
					return (
						<Stack horizontal horizontalAlign='space-between' verticalAlign='center' styles={{ root: { marginLeft: '10px' } }}>
							<Text>{userContext.userData.Domains && userContext.userData.Domains[0]!?.CompanyName}</Text>
							<ActionButton
								text='Sign out'
								onClick={() => {
									props.logout();
									navigate('/login');
								}}
							/>
						</Stack>
					)
				},
				text: 'Change tenant',
				// onClick: () => console.log('Change Tenant'),
			},
			{
				key: 'userinfo',
				onRender: () => {
					return (
						<Persona styles={{ root: { margin: '10px' } }}
							{...basePersona}
							size={PersonaSize.size72}
							{...userContext.userData!?.changeTenantDomain && { ...{ onRenderTertiaryText: () => <span style={{ color: userContext.userData!?.changeTenantDomain ? '#a80000' : 'black' }}>{userContext.userData!?.changeTenantDomain}</span> } }}
						/>
					)
				},
				text: 'Change tenant',
				// onClick: () => console.log('Change Tenant'),
			},
			...changeTenantMenu
		]
	}, [userContext.userData, changeTenantMenu])

	if (!props.loggedIn) {
		return (
			<Stack.Item className="ms-bgColor-white" styles={{ root: { padding: '20px' } }}>
				<HeaderLogo />
			</Stack.Item>
		)
	}

	const basePersona: IPersonaSharedProps = {
		imageUrl: 'data:image/jpeg;base64,' + graphContext.userPhoto,
		text: graphContext.userDetails!?.displayName,
		secondaryText: graphContext.userDetails!?.userPrincipalName,
		tertiaryText: graphContext.userDetails!?.jobTitle,
		imageAlt: "User Details",
	};

	console.log(changeTenantOptions)

	return (
		<Stack.Item className="ms-bgColor-white" styles={{ root: { padding: '20px' } }}>
			<HeaderLogo>
				{userContext.userData!?.isRehngruppen && <Toggle
					className='ms-hiddenLgDown'
					inlineLabel={true}
					label="Admin"
					checked={graphContext.adminMode}
					onChange={(_ev, checked) => setGraphContext({ ...graphContext, adminMode: checked! })}
					styles={{ root: { margin: 5 } }}
				/>}
				<Stack.Item styles={{ root: { justifyContent: 'end' } }}>
					<Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign='center'>
						<ContextualMenu
							items={menuItems}
							hidden={!showContextualMenu}
							target={linkRef}
							isBeakVisible={true}
							onItemClick={onHideContextualMenu}
							onDismiss={onHideContextualMenu}
						/>
						<div className='user-button' ref={linkRef} onClick={onShowContextualMenu}>
							<Stack.Item styles={{ root: { justifyContent: 'end', minWidth: 40, borderRadius: 20 } }}>
								<Persona className='ms-hiddenLgUp'
									imageUrl={'data:image/jpeg;base64,' + graphContext.userPhoto}
									text=''
									secondaryText=''
									size={PersonaSize.size40}
									styles={{ root: { width: 40 } }}
								/>
								<Persona className='ms-hiddenMdDown'
									{...basePersona}
									size={PersonaSize.size40}
									{...userContext.userData!?.changeTenantDomain && { ...{ onRenderSecondaryText: () => <span style={{ color: userContext.userData!?.changeTenantDomain ? '#a80000' : 'black' }}>{userContext.userData!?.changeTenantDomain}</span> } }}
								/>
							</Stack.Item>
						</div>
					</Stack>
				</Stack.Item>
			</HeaderLogo>
		</Stack.Item>
	)
}

export default Header;

import * as React from 'react';
import { TenantContext, UserContext } from "../contexts/ContextWrapper";
import { TenantContextType } from "../interfaces/ContextType";
import { apiUris } from "../helpers/SettingsMisc";
import { GetAdminSettings, GetLogo } from "../helpers/GetFromRestApi";
import { GetUseGroups } from "../helpers/SettingsCompany";
import { FetchGroups } from "../helpers/Groups";
import { GroupInfo } from "../interfaces/GroupInfo";
import { GroupDetails } from "../interfaces/GroupDetails";
import { AuthContext } from "../contexts/AuthContext";

export const useSettingsFetch = (

) => {
    const [isLoadingSettings, setIsLoadingSettings] = React.useState<boolean>(false)
    const { accessToken, scopes, setScopes, handleLogin } = React.useContext(AuthContext);

    const { userContext } = React.useContext(UserContext);
    const { tenantContext, setTenantContext } = React.useContext(TenantContext);

    const UpdateSettings = async (api: string, domain: string,) => {
        setIsLoadingSettings(true);

        const [fetchAdminSettings, fetchAdminLogo] = await Promise.all([
            GetAdminSettings(accessToken!, domain, api),
            GetLogo(accessToken!, domain, api)
        ])

        let groupIds: GroupInfo[] = []
        let allGroups: GroupDetails[] = []
        let newToken: string = accessToken!
        if (GetUseGroups(fetchAdminSettings)) {
            if (scopes.indexOf('Group.Read.All') === -1) {
                setScopes([...scopes, 'Group.Read.All'])
                newToken = await handleLogin([...scopes, 'Group.Read.All'])
            }
            [groupIds, allGroups] = await FetchGroups(newToken, domain, api)
        }

        if (fetchAdminSettings || fetchAdminLogo) {
            setTenantContext({
                ...tenantContext,
                api: api.toLowerCase(),
                apiUri: apiUris(api),
                logo: fetchAdminLogo,
                settings: fetchAdminSettings,
                tenantName: fetchAdminSettings.Domain!?.toLowerCase(),
                groupIds: groupIds,
                companyGroups: allGroups.length ? groupIds.map((idItem) => { return allGroups!.find(item => item.id === idItem.groupID)! }).sort((a, b) => { let fa = a.displayName.toLowerCase(), fb = b.displayName.toLowerCase(); if (fa < fb) { return -1; } if (fa > fb) { return 1; } return 0; }) : [],
                allGroups: allGroups,
                MyTenant: userContext.userData.Domains!?.find(item => item.DomainName.toLowerCase() === domain.toLowerCase())!.MyTenant,
            })
        }
        else {
            setTenantContext({} as TenantContextType)
            localStorage.clear()
        }

        setIsLoadingSettings(false);
    }

    return {
        UpdateSettings, isLoadingSettings
    }
}
import { FontIcon, Stack, Text } from '@fluentui/react';
import * as React from 'react';
import { TenantContext } from '../../contexts/ContextWrapper';

export interface ExtensionsOverviewProps {

}

const FunctionsOverview: React.FC<ExtensionsOverviewProps> = () => {

    const { tenantContext } = React.useContext(TenantContext);
    
    if (tenantContext.settings.Functions === undefined) { return <></> }

    return (
        <Stack.Item styles={{ root: { padding: 20, maxWidth: 340 } }}>
            <Stack tokens={{ childrenGap: '5px' }}>
                <Text variant="xLarge">Functions</Text>
                <table className='table-overview' width='190px'>
                    <tr>
                        <td width='150px' height='16px'>&nbsp;</td>
                        <td width='40px'>&nbsp;</td>
                    </tr>
                    <tbody>
                        {tenantContext.settings.Functions!?.map((func) => {
                            if (!func.ActivateFunction) { return <></> }
                            return (
                                <tr key={func.FunctionName} >
                                    <td>{func.FunctionName}</td>
                                    <td>
                                        {func.EnableFunction && <FontIcon iconName="CheckMark" />}
                                        {!func.EnableFunction && <FontIcon iconName="CirclePause" />}
                                        {!func.ActivateFunction && <FontIcon iconName="Blocked" />}
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </Stack>
        </Stack.Item>
    );
};

export default FunctionsOverview;
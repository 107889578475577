import * as React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface HTMLEditorProps {
    html: string,
    setHtml: (html: string) => void
    preset: 'academy' | 'signature'
}

const AcademyModules = {
    toolbar: [
        [{ 'header': [false, 2, 3] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'list': 'ordered' }],
        ['link'],
        ['clean']
    ],
}

const SignatureModules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'font': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        ['link', 'image',],    
        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'list': 'check' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],  
        [{ 'indent': '-1'}, { 'indent': '+1' }],         
        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
        [{ 'align': [] }],
        ['clean']                 
    ],
}

const AcademyFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

const SignatureFormats = [
    'header',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
]

const HTMLEditor: React.FC<HTMLEditorProps> = (props) => {

    const modules = props.preset === 'academy' ? AcademyModules : SignatureModules
    const formats = props.preset === 'academy' ? AcademyFormats : SignatureFormats
    
    
    return (
        <ReactQuill
            defaultValue={props.html}
            modules={modules}
            formats={formats}
            onChange={(_content, _delta, _source, _editor) => {
                // console.log(_content, _delta, _source, _editor)
                props.setHtml(_editor.getHTML().replace(/<p><br><\/p>/g, '').replace(/<p><\/p>/g, ''))
            }
            }
        />
    );
}

export default HTMLEditor;
import { EmbeddedPictureItem } from "./EmbeddedPictureItem";

export interface EmailSignature {
	SignatureName: string
    DefaultSignature: boolean
    ReplySignature: boolean
    HTML: string
    EmbeddedPictures: EmbeddedPictureItem[]
    StartTime: Date | string
    EndTime: Date | string
    Active: boolean
    ModifiedDate: Date | string
    PublishDate: Date | string
}

export const exampleEmailSignature: EmailSignature = {
    SignatureName: '',
    DefaultSignature: false,
    ReplySignature: false,
    HTML: '',
    EmbeddedPictures: [],
    StartTime: '',
    EndTime: '',
    Active: false,
    ModifiedDate: '',
    PublishDate: '',
}
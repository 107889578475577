import { TenantContextType, UserContextType } from "../interfaces/ContextType"

export const getNavigationAddress = (tenantContext: TenantContextType, userContext: UserContextType, page: string): string => {
	if (userContext.userData &&
		userContext.userData.Domains &&
		userContext.userData.Domains.length > 0 &&
		userContext.userData.Domains.some(domain =>
			domain.DomainName!?.toLowerCase() === tenantContext.tenantName!?.toLowerCase() &&
			domain.APIName!?.toLowerCase() === tenantContext.api!?.toLowerCase())
	) { return `/${tenantContext.api!?.toLowerCase()}/${tenantContext.tenantName!?.toLowerCase()}/${page}` }
	else if (userContext.userData &&
		userContext.userData.Domains &&
		userContext.userData.Domains.length > 0
	) { return `/${userContext.userData.Domains[0].APIName.toLowerCase()}/${userContext.userData.Domains[0].DomainName.toLowerCase()}/${page}` }
	else { return '/login' }
}


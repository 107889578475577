// MsalProvider.tsx

import React, { useEffect, useState } from 'react';
import * as msal from '@azure/msal-browser';
import { GetHostHref } from '../helpers/SettingsMisc';
import { CenterComponent } from '../components/common/CenterComponent';
import { Spinner, SpinnerSize } from '@fluentui/react';
import { MsalProvider } from '@azure/msal-react';

const msalConfig = (): msal.Configuration => {
  return {
    auth: {
      clientId: 'a9ce01e0-c456-4ace-b769-014ead92a43c',
      authority: 'https://login.microsoftonline.com/common',
      redirectUri: GetHostHref(), // Update with your app's redirect URI
      postLogoutRedirectUri: GetHostHref() + '/login',
      navigateToLoginRequestUrl: true,
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
      secureCookies: true,
    },
    system: {
      tokenRenewalOffsetSeconds: 300, // Adjust this as needed, e.g., 300 seconds (5 minutes) before token expiry.
    },
  }
}

interface MsalAuthProviderProps {
  children: React.ReactNode; // Add this prop
}

const MsalAuthProvider: React.FC<MsalAuthProviderProps> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [localPca, setLocalPca] = useState<msal.IPublicClientApplication | null>(null);

  useEffect(() => {
    // This is just an example assuming GetHostHref is asynchronous
    // Adjust as per your actual async operation.
    const initializePca = async () => {
      const pcaInstance = await msal.createNestablePublicClientApplication(msalConfig());
      setLocalPca(pcaInstance);
      setInitialized(true);
    };

    initializePca();
  }, []);

  if (!initialized) return (
    <CenterComponent>
      <Spinner className='spinner' size={SpinnerSize.large} label='Initializing...' />
    </CenterComponent>
  );

  console.log('localPca', localPca);
  return (
    <MsalProvider instance={localPca!}>
      {children}
    </MsalProvider>
  );
};

export default MsalAuthProvider;

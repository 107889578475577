import { UserAdminItem } from "../interfaces/UserAdminItem";
import SettingsCompany from "./SettingsCompany";

const hasSpecialPermission = (userData: UserAdminItem, extensionName: string,): boolean => {
    switch (extensionName) {
        case 'Templates':
            return userData.isTemplateAdmin;
        case 'Pictures':
            return userData.isPictureAdmin;
        case 'Slides':
            return userData.isSlideAdmin;
        case 'Sections':
            return userData.isSectionsAdmin;
        default:
            return false;
    }
}

const hasExtensionPermission = (userData: UserAdminItem, extensionName: string, settings: SettingsCompany): boolean => {
    const extension = settings.Extensions?.find((val) => val.ExtensionName === extensionName);
    return (
        (userData.isTemplateAdmin || userData.isContributorAdmin || userData.isSettingsAdmin || hasSpecialPermission(userData, extensionName)) &&
        (extension!?.ActivateExcel || extension!?.ActivateWord || extension!?.ActivatePowerPoint || extension!?.ActivateOutlook)
    ) as boolean;
};

const hasFunctionPermission = (userData: UserAdminItem, functionName: string, settings: SettingsCompany): boolean => {
    const func = settings.Functions?.find((val) => val.FunctionName === functionName);
    return (
        (userData.isContributorAdmin || userData.isSettingsAdmin) && func!?.ActivateFunction
    );
};

const getMenuItems = (userData: UserAdminItem, settings: SettingsCompany, adminMode: boolean): string[] => {
    if (!userData) return [];

    return [
        'home',
        ...(hasExtensionPermission(userData, 'Templates', settings) ? ['templates'] : []),
        ...(hasExtensionPermission(userData, 'Icons', settings) ? ['icons'] : []),
        ...(hasExtensionPermission(userData, 'Pictures', settings) ? ['pictures'] : []),
        ...(hasExtensionPermission(userData, 'Slides', settings) ? ['slides'] : []),
        ...(hasExtensionPermission(userData, 'Contents', settings) ? ['wordcontents'] : []),
        ...(hasExtensionPermission(userData, 'Sections', settings) ? ['sections'] : []),
        ...(hasExtensionPermission(userData, 'Email Signatures', settings) ? ['emailsignatures'] : []),
        ...(hasFunctionPermission(userData, 'Custom Data', settings) ? ['customdata'] : []),
        ...(adminMode && userData.isAdmin && hasExtensionPermission(userData, 'Icons', settings) ? ['iconsadmin'] : []),
        ...(userData.isSettingsAdmin ? ['settings'] : []),
        ...(hasFunctionPermission(userData, 'User Information', settings) ? ['userdata'] : []),
        ...(userData.isSettingsAdmin ? ['accounts'] : []),
        ...(userData.isSettingsAdmin && hasFunctionPermission(userData, 'Microsoft 365 Groups', settings) ? ['groups'] : []),
        ...(userData.isSettingsAdmin || userData.isContributorAdmin ? ['statistics'] : []),
        ...(adminMode && userData.isAdmin ? ['domains'] : []),
        ...(adminMode && userData.isAdmin ? ['news'] : []),
        ...(adminMode && userData.isRehngruppen ? ['customers'] : []),
        ...(adminMode && userData.isRehngruppen ? ['academy'] : []),
    ];
};

export const GetMyMenuItems = (userData: UserAdminItem, settings: SettingsCompany, adminMode: boolean): string[] => {
    return getMenuItems(userData, settings, adminMode);
};

export const AdminMenuItems = (userData: UserAdminItem, settings: SettingsCompany): { key: string, name: string, url: string, data: string }[] => {
    if (!userData) return [];

    const menuItems = [
        { key: 'iconsadmin', name: 'Icons Admin', url: '', data: 'FileCode', condition: userData.isAdmin && hasExtensionPermission(userData, 'Icons', settings) },
        { key: 'domains', name: 'Domains', url: '', data: 'ServerEnviroment', condition: userData.isAdmin },
        { key: 'news', name: 'News', url: '', data: 'News', condition: userData.isAdmin },
        { key: 'customers', name: 'Customers', url: '', data: 'Customers', condition: userData.isRehngruppen },
        { key: 'academy', name: 'RehnAcademy', url: '', data: 'Academy', condition: userData.isRehngruppen },
    ];

    return menuItems.filter(item => item.condition).map(({ condition, ...item }) => item);
};

export const AllMenuItems = (userData: UserAdminItem, settings: SettingsCompany): { key: string, name: string, url: string, data: string }[] => {
    if (!userData) return [];

    const menuItems = [
        { key: 'home', name: 'Home', url: '', data: 'Home', condition: true },
        { key: 'templates', name: 'Templates', url: '', data: 'Documentation', condition: hasExtensionPermission(userData, 'Templates', settings) },
        { key: 'icons', name: 'Icons', url: '', data: 'PictureTile', condition: hasExtensionPermission(userData, 'Icons', settings) },
        { key: 'pictures', name: 'Pictures', url: '', data: 'PictureCenter', condition: hasExtensionPermission(userData, 'Pictures', settings) },
        { key: 'slides', name: 'Slides', url: '', data: 'Slideshow', condition: hasExtensionPermission(userData, 'Slides', settings) },
        { key: 'wordcontents', name: 'Contents', url: '', data: 'WordLogo', condition: hasExtensionPermission(userData, 'Contents', settings) },
        { key: 'sections', name: 'Sections', url: '', data: 'WordLogo', condition: hasExtensionPermission(userData, 'Sections', settings) },
        { key: 'emailsignatures', name: 'Email Signatures', url: '', data: 'InsertSignatureLine', condition: hasExtensionPermission(userData, 'Email Signatures', settings) },
        { key: 'customdata', name: 'Custom Data', url: '', data: 'EMI', condition: hasFunctionPermission(userData, 'Custom Data', settings) },
        { key: 'settings', name: 'Settings', url: '', data: 'Settings', condition: userData.isSettingsAdmin },
        { key: 'userdata', name: 'User Data', url: '', data: 'ContactInfo', condition: hasFunctionPermission(userData, 'User Information', settings) },
        { key: 'accounts', name: 'Accounts', url: '', data: 'Contact', condition: userData.isSettingsAdmin },
        { key: 'groups', name: 'Groups', url: '', data: 'Group', condition: userData.isSettingsAdmin && hasFunctionPermission(userData, 'Microsoft 365 Groups', settings) },
        { key: 'statistics', name: 'Statistics', url: '', data: 'BarChart4', condition: userData.isSettingsAdmin || userData.isContributorAdmin },
    ];

    return menuItems.filter(item => item.condition).map(({ condition, ...item }) => item);
};
import React, { useContext } from 'react';
import { DefaultButton } from '@fluentui/react';
import { AdminMenuItems } from '../../helpers/MenuHelper';
import { TenantContext, UserContext } from '../../contexts/ContextWrapper';
// import { useNavigate } from 'react-router-dom';

export interface NavigationButtonAdminProps {
	selectPage: (x: string) => void;
}

const NavigationButtonAdmin: React.FC<NavigationButtonAdminProps> = (props) => {

	const { tenantContext } = useContext(TenantContext);
	const { userContext } = useContext(UserContext);

	return (<DefaultButton
		text='Admin'
		iconProps={{ iconName: 'GlobalNavButton' }}
		menuProps={{
			items: [...AdminMenuItems(userContext.userData, tenantContext.settings)],
			onItemClick: (_ev, item) => props.selectPage(item!?.key!),
		}}
	/>
	);
};

export default NavigationButtonAdmin;
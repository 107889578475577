import * as React from 'react';
import { ContentFrame } from '../common/ContentFrame';
import CombinedDataList from './CombinedDataList';
import CombinedDataEdit from './CombinedDataEdit';
import { useCombinedData } from '../../hooks/useCombinedData';

export const CombinedDataWrapper: React.FunctionComponent = () => {

    const {
        getCombinedDataItems,
        deleteCombinedDataItem,
        combinedDataItems,
        setCombinedDataItems,
        selectedCombinedData,
        setSelectedCombinedData,
        updateCombinedDataItem,
        isLoadingCombinedData
    } = useCombinedData()

    return (
        <>
            <ContentFrame>
                <CombinedDataList
                    items={combinedDataItems}
                    setItems={setCombinedDataItems}
                    selectedItem={selectedCombinedData!}
                    setSelectedItem={setSelectedCombinedData}
                    deleteItem={deleteCombinedDataItem}
                />
            </ContentFrame>
            {!!selectedCombinedData && <ContentFrame>
                <CombinedDataEdit
                    selectedItem={selectedCombinedData!}
                    updateItem={updateCombinedDataItem}
                    getItems={getCombinedDataItems}
                    isLoading={isLoadingCombinedData}
                />
            </ContentFrame>}
        </>
    );
}

export default CombinedDataWrapper;
import React, { useContext } from 'react';
import { CommandBar, Stack, Text } from '@fluentui/react';
import { AllMenuItems } from '../../helpers/MenuHelper';
import { TenantContext, UserContext } from '../../contexts/ContextWrapper';
// import { useNavigate } from 'react-router-dom';

export interface NavigationButtonProps {
	selectPage: (x: string) => void;
}

const NavigationButton: React.FC<NavigationButtonProps> = (props) => {

	const { tenantContext } = useContext(TenantContext);
	const { userContext } = useContext(UserContext);

	return (
		<Stack horizontal horizontalAlign="start" verticalAlign="center">
			<CommandBar
				items={[
					{
						key: 'navButton',
						text: 'Navigation',
						iconProps: { iconName: 'GlobalNavButton' },
						subMenuProps: {
							items: [...AllMenuItems(userContext.userData, tenantContext.settings)],
							onItemClick: (_ev, item) => props.selectPage(item!?.key!),
						},
					},
				]}
			/>
			<Text variant="xLarge">{tenantContext.settings?.CompanyName}</Text>
		</Stack>

	);
};

export default NavigationButton;

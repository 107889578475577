import * as React from 'react';
import { Text } from '@fluentui/react';

export interface ErrorMessageProps {
    message: string
    isVisible?: boolean
    className?: string
}

export const ErrorMessage: React.FunctionComponent<ErrorMessageProps> = ({message, isVisible = true, className}) => {
    if (!isVisible) {
        return <></>
    }
    return (
        <Text className={className} variant="small" styles={{ root: { color: 'rgb(164, 38, 44)' } }}>{message}</Text>
    )
}

export default ErrorMessage;
export const GetTime = (date: Date): string => {
    try {
        return new Date(date).toLocaleTimeString('sv-SE').split(':').splice(0, 2).join(':')
    } catch (error) {
        return date.toString().split('T')[1].split(':').splice(0, 2).join(':');
    }
}

export const GetDate = (date: Date): string => {
    try {
        return new Date(date).toLocaleDateString('sv-SE');
    } catch (error) {
        return date.toString().split('T')[0];
    }
}

export const GetDateTime = (date: Date): string => {
    try {
        return new Date(date).toLocaleString('sv-SE', { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' });
    } catch (error) {
        return date.toString();
    }
}

export const IsBeforeDate = (date: Date, compareDate: Date): boolean => {
    return date.getTime() < compareDate.getTime();
}

export const isInvalidDate = (date: string | Date): boolean => {
    const invalidDate = new Date("0001-01-01T00:00:00Z").getTime();
    return new Date(date).getTime() === invalidDate || isNaN(new Date(date).getTime());
};
import React, { useMemo } from 'react';
import { CommandBar, IColumn, ICommandBarItemProps, ShimmeredDetailsList, Selection, Stack, DetailsListLayoutMode, SelectionMode, CheckboxVisibility, Icon, mergeStyleSets, MessageBarType } from '@fluentui/react';
import { SectionsDocument } from '../../interfaces/SectionsDocument';
import { DialogUploadSections } from '../dialogs/DialogUploadSections';
import { getFileTypeIconProps } from '@fluentui/react-file-type-icons';
import { DialogDelete } from '../dialogs/DialogDelete';
import { GetSectionsDoc } from '../../helpers/GetFromRestApi';
import { AuthContext } from '../../contexts/AuthContext';
import { MessageContext, TenantContext } from '../../contexts/ContextWrapper';
import { deleteSectionsDocument, publishSectionsAllDocument, publishSectionsDocument } from '../../helpers/PostToRestApi';
import { DialogOkAbort } from '../dialogs/DialogOkAbort';
import { AxiosError } from 'axios';

export interface SectionsFilesProps {
	items: SectionsDocument[]
	reloadItems: () => void
	selectedItem: SectionsDocument
	setSelectedItem: (item: SectionsDocument) => void
	templateVersion: string
}

const classNames = mergeStyleSets({
	fileIconHeaderIcon: {
		padding: 0,
		fontSize: '16px',
	},
	fileIconCell: {
		textAlign: 'center',
		selectors: {
			'&:before': {
				content: '.',
				display: 'inline-block',
				verticalAlign: 'middle',
				height: '100%',
				width: '0px',
				visibility: 'hidden',
			},
		},
	},
	fileIconImg: {
		verticalAlign: 'middle',
		maxHeight: '16px',
		maxWidth: '16px',
	},
	controlWrapper: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	exampleToggle: {
		display: 'inline-block',
		marginBottom: '10px',
		marginRight: '30px',
	},
	selectionDetails: {
		marginBottom: '20px',
	},
});

const SectionsFiles: React.FC<SectionsFilesProps> = (props) => {

	// const [selectedItem, setSelectedItem] = React.useState<GroupedData | null>(null);
	const [selectionCount, setSelectedCount] = React.useState<number>(0);

	const [hideUploadDialog, setHideUploadDialog] = React.useState<boolean>(true);
	const [hideDeleteDialog, setHideDeleteDialog] = React.useState<boolean>(true);
	const [hidePublishDialog, setHidePublishDialog] = React.useState<boolean>(true);
	const [hidePublishAllDialog, setHidePublishAllDialog] = React.useState<boolean>(true);

	const { accessToken } = React.useContext(AuthContext);
	const { tenantContext } = React.useContext(TenantContext);
	const { setMessageContext } = React.useContext(MessageContext)


	let selection: Selection = new Selection({
		onSelectionChanged: () => {
			props.setSelectedItem(selection.getSelection()[0] as SectionsDocument)
			setSelectedCount(selection.getSelectedCount())
		}
	});

	React.useEffect(() => {
		selection.selectToIndex(props.items.findIndex((item) => item.DocName === props.selectedItem?.DocName))
	}, [props.selectedItem])

	const commandItems = (): ICommandBarItemProps[] => {
		return [
			...(props.templateVersion === 'TEST' ? [{
				key: 'upload',
				text: 'Upload',
				disabled: props.items!?.length > 0 && props.items![props.items!?.length - 1]?.DocName === '',
				iconProps: { iconName: 'Upload' },
				onClick: () => setHideUploadDialog(false),
			}] : []),
			{
				key: 'download',
				text: 'Download',
				iconProps: { iconName: 'Download' },
				disabled: selectionCount === 0 || props.selectedItem!?.DocName === '',
				onClick: () => downloadFile(),
			},
			// ...(props.templateVersion === 'TEST' ? [{
			// 	key: 'edit',
			// 	text: 'Edit',
			// 	disabled: (!GetUseGroups(props.settings) && !(state.selectedItem.slideGroups!?.length! > 0)),
			// 	iconProps: { iconName: 'Edit' },
			// 	onClick: () => _editPrompt(),
			// }] : []),
			{
				key: 'delete',
				text: 'Delete',
				iconProps: { iconName: 'Delete' },
				disabled: selectionCount === 0 || props.selectedItem!?.DocName === '',
				onClick: () => setHideDeleteDialog(false),
			},
			...(props.templateVersion === 'TEST' ? [{
				key: 'publish',
				text: 'Publish Document',
				disabled: selectionCount === 0 || props.selectedItem!?.DocName === '',
				iconProps: { iconName: 'PublishContent' },
				onClick: () => setHidePublishDialog(false),
			}] : []),
			...(props.templateVersion === 'TEST' ? [{
				key: 'publishAll',
				text: 'Publish All',
				iconProps: { iconName: 'PublishContent' },
				onClick: () => setHidePublishAllDialog(false),
			}] : []),
		]
	};

	const columns: IColumn[] = useMemo(() => [
		{
			key: 'column0',
			name: 'Icon',
			fieldName: 'icon',
			className: classNames.fileIconCell,
			iconClassName: classNames.fileIconHeaderIcon,
			ariaLabel: 'Column operations for File type, Press to sort on File type',
			// iconName: 'Page',
			isIconOnly: true,
			minWidth: 16,
			maxWidth: 16,
			isResizable: false,
			onRender: () => { return <Icon {...getFileTypeIconProps({ extension: 'docx', size: 16 })} />; },
		},
		{ key: 'column1', name: 'Name', fieldName: 'DocName', minWidth: 150, maxWidth: 300, isResizable: true },
		{ key: 'column2', name: 'File size', fieldName: 'fileSizeNum', minWidth: 60, maxWidth: 100, isResizable: true, onRender: (item: SectionsDocument) => { return <span>{item.fileSize}</span>; }, },
		{ key: 'column3', name: 'Upload date', fieldName: 'modifiedDate', minWidth: 100, maxWidth: 100, isResizable: true, },
		{ key: 'column4', name: 'Publish date', fieldName: 'publishDate', minWidth: 100, maxWidth: 100, isResizable: true, },
	], [props.items])

	const downloadFile = async () => {
		if (props.selectedItem) {
			let fileBase64 = await GetSectionsDoc(accessToken!, tenantContext.tenantName, tenantContext.api, props.selectedItem.DocName, props.templateVersion)
			let anchor: HTMLAnchorElement = document.createElement("a");
			anchor.href = 'data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,' + JSON.parse(fileBase64);
			anchor.download = props.selectedItem.DocName;
			anchor.click();
		}
	}

	const deleteFile = async () => {
		if (props.selectedItem) {
			deleteSectionsDocument(accessToken!, props.selectedItem.DocName, tenantContext.tenantName, tenantContext.api, props.templateVersion)
				.then((response) => {
					if (response.status === 200) { setMessageContext({ message: response.data, messageType: MessageBarType.success, visible: true }) }
					else { setMessageContext({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true }) }
				})
				.then(() => { props.reloadItems() })
				.catch((error) => {
					setMessageContext({ message: (error as AxiosError).message, messageType: MessageBarType.error, visible: true })
				})
		}
	}

	const publishFile = async () => {
		if (props.selectedItem) {
			publishSectionsDocument(accessToken!, props.selectedItem.DocName, tenantContext.tenantName, tenantContext.api)
				.then((response) => {
					if (response.status === 200) { setMessageContext({ message: response.data, messageType: MessageBarType.success, visible: true }) }
					else { setMessageContext({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true }) }
				})
				.catch((error) => {
					setMessageContext({ message: (error as AxiosError).message, messageType: MessageBarType.error, visible: true })
				})
		}
	}

	const publishAll = async () => {
		publishSectionsAllDocument(accessToken!, tenantContext.tenantName, tenantContext.api)
			.then((response) => {
				if (response.status === 200) { setMessageContext({ message: response.data, messageType: MessageBarType.success, visible: true }) }
				else { setMessageContext({ message: typeof response.data === 'string' ? response.data : response.data.Message, messageType: MessageBarType.error, visible: true }) }
			})
			.catch((error) => {
				setMessageContext({ message: (error as AxiosError).message, messageType: MessageBarType.error, visible: true })
			})
	}

	return (
		<Stack className='Width100'>
			<span className='Indent20'><h3>Documents</h3></span>
			<CommandBar
				items={commandItems()}
				ariaLabel="Use left and right arrow keys to navigate between commands"
			/>
			{props.items!?.length > 0 &&
				<ShimmeredDetailsList
					items={props.items}
					columns={columns}
					enableShimmer={!props.items}
					setKey="set"
					layoutMode={DetailsListLayoutMode.justified}
					selectionPreservedOnEmptyClick={true}
					selectionMode={SelectionMode.single}
					selection={selection}
					ariaLabelForSelectionColumn="Toggle selection"
					ariaLabelForSelectAllCheckbox="Toggle selection for all items"
					checkButtonAriaLabel="Row checkbox"
					checkboxVisibility={CheckboxVisibility.always}
				/>}
			<DialogUploadSections
				hidden={hideUploadDialog}
				callbackOk={() => {
					props.reloadItems()
					setHideUploadDialog(true)
				}}
				callbackAbort={() => setHideUploadDialog(true)}
			/>
			<DialogDelete
				hidden={hideDeleteDialog}
				callbackDelete={() => {
					deleteFile()
					setHideDeleteDialog(true)
				}}
				callbackCancel={() => setHideDeleteDialog(true)}
				title='Delete Document'
				description={`Are you sure you want to delete ${props.selectedItem!?.DocName || ''}?`}
			/>
			<DialogOkAbort
				hidden={hidePublishDialog}
				callbackOk={() => { setHidePublishDialog(true); publishFile() }}
				callbackAbort={() => setHidePublishDialog(true)}
				title='Publish Document'
				description={`Are you sure you want to publish ${props.selectedItem!?.DocName || ''}?`}
			/>
			<DialogOkAbort
				hidden={hidePublishAllDialog}
				callbackOk={() => { setHidePublishAllDialog(true); publishAll() }}
				callbackAbort={() => setHidePublishAllDialog(true)}
				title='Publish All Documents'
				description='Are you sure you want to publish all documents?'
			/>
		</Stack>
	);
};

export default SectionsFiles;
import React from 'react';
import {
	Checkbox,
	Stack,
	TextField,
	Text,
	Toggle,
	IconButton,
	DefaultButton,
	IContextualMenuProps,
} from '@fluentui/react';
import SettingsCompany from '../../helpers/SettingsCompany';
import { ExtensionItem } from '../../interfaces/ExtensionItem';
import { FunctionItem } from '../../interfaces/FunctionItem';
import { ExtraField } from '../../interfaces/ExtraField';
import { ExcelTheme, OutlookTheme, PowerPointTheme, WordTheme } from '../../helpers/Themes';

interface SettingsAdminProps {
	setEditedSettings: (x: SettingsCompany) => void;
	editedSettings: SettingsCompany;
	availableExtensions?: ExtensionItem[];
	availableFunctions?: FunctionItem[];
}

const SettingsAdmin: React.FC<SettingsAdminProps> = (props) => {

	const onChangeExtension = (item: ExtensionItem): void => {
		let extensions: ExtensionItem[] = props.editedSettings.Extensions;
		extensions[extensions.findIndex((val) => val.ExtensionName === item.ExtensionName)] = item;
		props.setEditedSettings(Object.assign(props.editedSettings, { Extensions: extensions }));
	};

	const addExtension = (itemName: string): void => {
		let extensions: ExtensionItem[] = props.editedSettings.Extensions;
		const availableItemIdx: number = props.availableExtensions!.findIndex((val) => val.ExtensionName === itemName);
		const newExtension: ExtensionItem = props.availableExtensions![availableItemIdx];
		newExtension.ActivateWord = newExtension.ExistInWord;
		newExtension.ActivatePowerPoint = newExtension.ExistInPowerPoint;
		newExtension.ActivateExcel = newExtension.ExistInExcel;
		newExtension.ActivateOutlook = newExtension.ExistInOutlook;
		newExtension.WordDesktop = newExtension.ExistInWord;
		newExtension.WordOnline = newExtension.ExistInWord;
		newExtension.PowerPointDesktop = newExtension.ExistInPowerPoint;
		newExtension.PowerPointOnline = newExtension.ExistInPowerPoint;
		newExtension.ExcelDesktop = newExtension.ExistInExcel;
		newExtension.ExcelOnline = newExtension.ExistInExcel;
		newExtension.OutlookDesktop = newExtension.ExistInOutlook;
		newExtension.OutlookOnline = newExtension.ExistInOutlook;
		extensions.push(newExtension);
		props.availableExtensions?.splice(availableItemIdx, 1);
		props.setEditedSettings(Object.assign(props.editedSettings, { Extensions: extensions }));
	};

	const removeExtension = (item: ExtensionItem): void => {
		let extensions: ExtensionItem[] = props.editedSettings.Extensions;
		extensions.splice(extensions.findIndex((val) => val.ExtensionName === item.ExtensionName), 1);
		props.availableExtensions?.push(item);
		props.setEditedSettings(Object.assign(props.editedSettings, { Extensions: extensions }));
	};

	const addFunction = (itemName: string): void => {
		let functions: FunctionItem[] = props.editedSettings.Functions;
		const availableItemIdx: number = props.availableFunctions!.findIndex((val) => val.FunctionName === itemName);
		const newFunction: FunctionItem = props.availableFunctions![availableItemIdx];
		newFunction.ActivateFunction = true;
		newFunction.EnableFunction = true;
		functions.push(newFunction);
		props.availableFunctions?.splice(availableItemIdx, 1);
		props.setEditedSettings(Object.assign(props.editedSettings, { Functions: functions }));
	};

	const removeFunction = (item: FunctionItem): void => {
		let functions: FunctionItem[] = props.editedSettings.Functions;
		functions.splice(functions.findIndex((val) => val.FunctionName === item.FunctionName), 1);
		props.availableFunctions?.push(item);
		props.setEditedSettings(Object.assign(props.editedSettings, { Functions: functions }));
	};

	const onChangeExtensionField = (item: ExtensionItem, field: ExtraField): void => {
		let fields: ExtraField[] = item.ExtraFields;
		fields[fields.findIndex((val) => val.Title === field.Title)] = field;

		let extensions: ExtensionItem[] = props.editedSettings.Extensions;
		extensions[extensions.findIndex((val) => val.ExtensionName === item.ExtensionName)] = Object.assign(item, { ExtraFields: fields });
		props.setEditedSettings(Object.assign(props.editedSettings, { Extensions: extensions }));
	};

	const onChangeFunction = (item: FunctionItem): void => {
		let functions: FunctionItem[] = props.editedSettings.Functions;
		functions[functions.findIndex((val) => val.FunctionName === item.FunctionName)] = item;
		props.setEditedSettings(Object.assign(props.editedSettings, { Functions: functions }));
	};

	const onChangeFunctionField = (item: FunctionItem, field: ExtraField): void => {
		let fields: ExtraField[] = item.ExtraFields;
		fields[fields.findIndex((val) => val.Title === field.Title)] = field;

		let functions: FunctionItem[] = props.editedSettings.Functions;
		functions[functions.findIndex((val) => val.FunctionName === item.FunctionName)] = Object.assign(item, { ExtraFields: fields });
		props.setEditedSettings(Object.assign(props.editedSettings, { Functions: functions }));
	};

	const addExtensionsMenuProps = (): IContextualMenuProps => {
		return {
			items: props.availableExtensions!.map((item) => {
				return {
					key: item.ExtensionName,
					text: item.ExtensionName,
				};
			}),
			onItemClick: (_ev, item) => addExtension(item?.text!),
			directionalHintFixed: true,
		};
	};

	const addFunctionsMenuProps = (): IContextualMenuProps => {
		return {
			items: props.availableFunctions!.map((item) => {
				return {
					key: item.FunctionName,
					text: item.FunctionName,
				};
			}),
			onItemClick: (_ev, item) => addFunction(item?.text!),
			directionalHintFixed: true,
		};
	};

	return props.editedSettings.Extensions!.length >= 0 ? (
		<Stack >
			<table className='SettingsTable SettingsEnableTable'>
				<thead>
					<tr className='NoBorder'>
						<th><Text className="ms-fontSize-16 ms-fontWeight-semibold">Extensions</Text></th>
						<th>
							<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }} styles={{ root: { justifyContent: 'center', paddingBottom: 5, borderBottom: '2px solid #2b579a' } }}>
								<div className="ms-BrandIcon--icon48 ms-BrandIcon--word ProgramIcon48"></div>
								<Stack.Item style={{ color: '#2b579a', fontSize: '16px' }}>Word</Stack.Item>
							</Stack>
						</th>
						<th />
						<th>
							<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }} styles={{ root: { justifyContent: 'center', paddingBottom: 5, borderBottom: '2px solid #b7472a' } }}>
								<div className="ms-BrandIcon--icon48 ms-BrandIcon--powerpoint ProgramIcon48"></div>
								<Stack.Item style={{ color: '#b7472a', fontSize: '16px' }}>Power&shy;Point</Stack.Item>
							</Stack>
						</th>
						<th />
						<th>
							<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }} styles={{ root: { justifyContent: 'center', paddingBottom: 5, borderBottom: '2px solid #217346' } }}>
								<div className="ms-BrandIcon--icon48 ms-BrandIcon--excel ProgramIcon48"></div>
								<Stack.Item style={{ color: '#217346', fontSize: '16px' }}>Excel</Stack.Item>
							</Stack>
						</th>
						<th />
						<th>
							<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }} styles={{ root: { justifyContent: 'center', paddingBottom: 5, borderBottom: '2px solid #0078d4' } }}>
								<div className="ms-BrandIcon--icon48 ms-BrandIcon--outlook ProgramIcon48"></div>
								<Stack.Item style={{ color: '#0078d4', fontSize: '16px' }}>Outlook</Stack.Item>
							</Stack>
						</th>
					</tr>
				</thead>
				<tbody>
					{props.editedSettings.Extensions.map((item: ExtensionItem, idx: number) => {
						return (
							<React.Fragment key={item.ExtensionName}>
								<tr key={idx}>
									<td>
										{item.ExtensionName}
										<IconButton
											aria-label='Remove extension'
											iconProps={{ iconName: 'Delete' }}
											onClick={() => removeExtension(item)}
										/>
									</td>
									<td>
										{item.ExistInWord ?
											<Checkbox
												aria-label={`Enable ${item.ExtensionName} in Word Desktop`}
												styles={{ root: { justifyContent: 'center' } }}
												checked={item.ActivateWord}
												// disabled={!item.ActivateWord}
												theme={WordTheme}
												onChange={(_ev, checked) => onChangeExtension(
													Object.assign(item, item.ActivateWord = checked!, item.WordDesktop = checked!, item.WordOnline = checked!)
												)}
											/>
											: null}
									</td>
									<td></td>
									<td>
										{item.ExistInPowerPoint ?
											<Checkbox
												aria-label={`Enable ${item.ExtensionName} in PowerPoint Desktop`}
												styles={{ root: { justifyContent: 'center' } }}
												checked={item.ActivatePowerPoint}
												// disabled={!item.ActivatePowerPoint}
												theme={PowerPointTheme}
												onChange={(_ev, checked) => onChangeExtension(
													Object.assign(item, item.ActivatePowerPoint = checked!, item.PowerPointDesktop = checked!, item.PowerPointOnline = checked!)
												)}
											/>
											: null}
									</td>
									<td></td>
									<td>
										{item.ExistInExcel ?
											<Checkbox
												aria-label={`Enable ${item.ExtensionName} in Excel Desktop`}
												styles={{ root: { justifyContent: 'center' } }}
												checked={item.ActivateExcel}
												// disabled={!item.ActivateExcel}
												theme={ExcelTheme}
												onChange={(_ev, checked) => onChangeExtension(
													Object.assign(item, item.ActivateExcel = checked!, item.ExcelDesktop = checked!, item.ExcelOnline = checked!)
												)}
											/>
											: null}
									</td>
									<td></td>
									<td>
										{item.ExistInOutlook ?
											<Checkbox
												aria-label={`Enable ${item.ExtensionName} in Excel Desktop`}
												styles={{ root: { justifyContent: 'center' } }}
												checked={item.ActivateOutlook}
												// disabled={!item.ActivateOutlook}
												theme={OutlookTheme}
												onChange={(_ev, checked) => onChangeExtension(
													Object.assign(item, item.ActivateOutlook = checked!, item.OutlookDesktop = checked!, item.OutlookOnline = checked!)
												)}
											/>
											: null}
									</td>
								</tr>
								{item.ExtraFields?.length > 0 &&
									item.ExtraFields.filter(value => !value.Visible).length > 0 &&
									<tr style={{ borderTop: '2px solid #fee' }}>
										<td></td>
										<td colSpan={10}>
											{item.ExtraFields.map((field: ExtraField, idx: number) => {
												if (!field.Visible) {
													const passwordfields = field.HiddenInput ?
														{
															type: "password",
															canRevealPassword: true,
															revealPasswordAriaLabel: "Show password"
														} : null
													// console.log(passwordfields)
													if (field.Value!?.toUpperCase() === 'TRUE' || field.Value!?.toUpperCase() === 'FALSE') {
														return (
															<Toggle
																key={field.Title}
																defaultChecked={field.Value!?.toUpperCase() === 'TRUE'}
																label={field.Title}
																// inlineLabel
																disabled={!item.ActivateWord && !item.ActivatePowerPoint && !item.ActivateExcel}
																onChange={(_ev, newValue) => { onChangeExtensionField(item, Object.assign(field, field.Value = newValue! ? 'TRUE' : 'FALSE')) }}
															/>
														)
													}
													else {
														return (
															<TextField
																key={idx + 100}
																styles={{ fieldGroup: { maxWidth: 400 } }}
																label={field.Title}
																defaultValue={field.Value?.toString()}
																onChange={(_ev, newValue) => { onChangeExtensionField(item, Object.assign(field, field.Value = newValue!)) }}
																// disabled={!item.ActivateWord && !item.ActivatePowerPoint && !item.ActivateExcel}
																{...passwordfields}
															/>
														)
													}
												}
												else { return null }
											})}
										</td>
									</tr>
								}
							</React.Fragment>
						)
					})}
					{props.availableExtensions?.length! > 0 &&
						<tr>
							<td colSpan={8}>
								<DefaultButton
									text="New extension"
									iconProps={{ iconName: 'Add' }}
									menuProps={addExtensionsMenuProps()}
								/>
							</td>
						</tr>
					}
					<tr style={{ backgroundColor: '#fff' }}>
						<td colSpan={8} /></tr>

					<tr><th><Text className="ms-fontSize-16 ms-fontWeight-semibold">Functions</Text></th></tr>
					{props.editedSettings.Functions
						.map((item: FunctionItem, idx: number, _arr: FunctionItem[]) => {
							return (
								<React.Fragment key={item.FunctionName}>
									<tr key={idx}>
										<td>
											{item.FunctionName}
											<IconButton
												aria-label='Remove extension'
												iconProps={{ iconName: 'Delete' }}
												onClick={() => removeFunction(item)}
											/>
										</td>
										<td>
											<Toggle
												// className={item.AnimationMotion}
												aria-label={`Enable ${item.FunctionName}`}
												styles={{ root: { justifyContent: 'center', marginBottom: '0px' } }}
												checked={item.ActivateFunction}
												// disabled={!item.ActivateFunction}
												onChange={(_ev, checked) => onChangeFunction(Object.assign(item, item.ActivateFunction = checked!, item.EnableFunction = checked!))}
											/>
										</td>
									</tr>
									{
										item.ExtraFields?.length > 0 && item.ExtraFields?.filter(value => value.Visible).length > 0 ?
											<tr>
												<td></td>
												<td>
													{item.ExtraFields.map((field: ExtraField, idx: number) => {
														if (field.Visible) {
															const passwordfields = field.HiddenInput ?
																{
																	canRevealPassword: true,
																	revealPasswordAriaLabel: "Show password"
																} : null
															console.log(passwordfields)
															return (
																<Stack>
																	<TextField
																		key={idx + 100}
																		styles={{ fieldGroup: { maxWidth: 400 } }}
																		label={field.Title}
																		defaultValue={field.Value?.toString()}
																		disabled={!item.ActivateFunction}
																		onChange={(_ev, newValue) => { onChangeFunctionField(item, Object.assign(field, field.Value = newValue!)) }}
																	// {...passwordfields}
																	/>
																</Stack>
															)

														}
														else { return null }
													})}
												</td>
											</tr>
											: null
									}
								</React.Fragment>
							)
						})}
					{props.availableFunctions?.length! > 0 &&
						<tr><td colSpan={8}>
							<DefaultButton
								text="New function"
								iconProps={{ iconName: 'Add' }}
								menuProps={addFunctionsMenuProps()}
							/>
						</td></tr>
					}
					<tr style={{ backgroundColor: '#fff' }}><td colSpan={8} /></tr>
					<tr><th><Text className="ms-fontSize-16 ms-fontWeight-semibold">Special</Text></th></tr>
					<tr>
						<td>Enable Test Domain</td>
						<td>
							<Toggle
								aria-label={`Enable Test Domain`}
								styles={{ root: { justifyContent: 'center', marginBottom: '0px' } }}
								checked={props.editedSettings.enableTestDomain}
								onChange={(_ev, checked) => props.setEditedSettings(Object.assign(props.editedSettings, { enableTestDomain: checked }))}
							/>
						</td>
					</tr>
					<tr>
						<td>Enable Redirect</td>
						<td>
							<Toggle
								aria-label={`Enable Redirect`}
								styles={{ root: { justifyContent: 'center', marginBottom: '0px' } }}
								checked={props.editedSettings.enableRedirect}
								onChange={(_ev, checked) => props.setEditedSettings(Object.assign(props.editedSettings, { enableRedirect: checked }))}
							/>
						</td>
					</tr>
					<tr>
						<td>Unique Manifest ID</td>
						<td>
							<Toggle
								aria-label={`Unique Manifest ID`}
								styles={{ root: { justifyContent: 'center', marginBottom: '0px' } }}
								checked={props.editedSettings.UniqueManifestID}
								onChange={(_ev, checked) => props.setEditedSettings(Object.assign(props.editedSettings, { UniqueManifestID: checked }))}
							/>
						</td>
					</tr>
					<tr>
						<td>Internal Use</td>
						<td>
							<Toggle
								aria-label={`Internal Use`}
								styles={{ root: { justifyContent: 'center', marginBottom: '0px' } }}
								checked={props.editedSettings.InternalUse}
								onChange={(_ev, checked) => props.setEditedSettings(Object.assign(props.editedSettings, { InternalUse: checked }))}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</Stack >
	) : null
};

export default SettingsAdmin;

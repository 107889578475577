import * as React from 'react';
import { Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import { GetEmailSignaturePictures, GetEmailSignatures } from '../../helpers/GetFromRestApi';
import Axios from 'axios';
import { EmailSignature, exampleEmailSignature } from '../../interfaces/EmailSignature';
import { EmbeddedPictureItem } from '../../interfaces/EmbeddedPictureItem';
import { deleteEmailsignature, publishEmailsignature, uploadEmailsignature } from '../../helpers/PostToRestApi';
import EmailSignaturesList from './EmailSignaturesList';
import EmailSignaturesPreview from './EmailSignaturesPreview';
import EmailSignaturesPictures from './EmailSignaturesPictures';
import { ContentFrame } from '../common/ContentFrame';
import { UploadItem } from '../../interfaces/UploadItem';
import { DialogOkAbort } from '../dialogs/DialogOkAbort';
import { DialogEditSignature } from '../dialogs/DialogEditSignature';
import { AuthContext } from '../../contexts/AuthContext';
import { TenantContext } from '../../contexts/ContextWrapper';
import { VersionSelector } from '../common/VersionSelector';

export interface EmailSignaturesProps {
}

const EmailSignatures: React.FC<EmailSignaturesProps> = () => {
	// Define the state using the React.useState hook
	// const [customDataItem, setCustomDataItem] = React.useState<CustomDataPlainListItem[]>([]);
	const [signatureItems, setSignatureItems] = React.useState<EmailSignature[]>([]);
	const [selectedSignature, setSelectedSignature] = React.useState<EmailSignature>(exampleEmailSignature);
	const [embeddedPictureItems, setEmbeddedPictureItems] = React.useState<EmbeddedPictureItem[]>([]);
	const [uploadSignature, setUploadSignature] = React.useState<UploadItem>({ inProgress: false, id: '', name: '', data: '' });
	const [deletedSignature, setDeletedSignature] = React.useState<UploadItem>({ inProgress: false, id: '', name: '' });
	// const [uploadPicture, setUploadPicture] = React.useState<UploadItem>({ inProgress: false, id: '', name: '', data: '' });
	// const [deletePicture, setDeletePicture] = React.useState<UploadItem>({ inProgress: false, id: '', name: '' });
	const [dialogEditStatus, setDialogEditStatus] = React.useState<'new' | 'edit' | 'duplicate' | 'hide'>('hide');
	const [dialogPublishStatus, setDialogPublishStatus] = React.useState<'publish' | 'publishall' | 'hide'>('hide');
	const [signatureVersion, setSingatureVersion] = React.useState<string>('TEST');
	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const { accessToken } = React.useContext(AuthContext)
	const { tenantContext } = React.useContext(TenantContext)

	let ourRequest = Axios.CancelToken.source();

	const getSignatures = async (version?: string) => {
		ourRequest = Axios.CancelToken.source()
		if (!version) { version = signatureVersion }
		let signatures: EmailSignature[] = await GetEmailSignatures(accessToken!, tenantContext.tenantName, tenantContext.api, version, ourRequest.token)
		if (!signatures) { signatures = [exampleEmailSignature] }
		setSignatureItems(signatures)
		setSelectedSignature(signatures![0])
	}

	//signature: EmailSignature
	const saveSignature = (item: UploadItem) => {
		ourRequest = Axios.CancelToken.source()
		console.log(item)
		uploadEmailsignature(accessToken!, tenantContext.tenantName, tenantContext.api, item.data!, ourRequest.token)
			.then((res) => {
				if (!res.data) { res.data = [exampleEmailSignature] }
				setUploadSignature({ inProgress: false, id: '', name: '', data: '' })
				setSignatureItems(res.data)
				setSelectedSignature(res.data.find((s: EmailSignature) => s.SignatureName === item.name) || res.data[0])
			})
		// .finally(() => {
		// 	//TODO remove after res.data is sorted
		// 	getSignatures()
		// })
	}

	const deleteSignature = (item: UploadItem, deleteUnusedPictures: boolean) => {
		ourRequest = Axios.CancelToken.source()
		deleteEmailsignature(accessToken!, tenantContext.tenantName, tenantContext.api, item.name, deleteUnusedPictures, signatureVersion, ourRequest.token)
			.then(() => {
				getSignatures()
			})
			.finally(() => {
				setDeletedSignature({ ...deletedSignature, inProgress: false, name: '', id: '' })
			})
	}

	const getEmbeddedPictures = async (version?: string) => {
		ourRequest = Axios.CancelToken.source()
		if (!version) { version = signatureVersion }
		const pictures: EmbeddedPictureItem[] = await GetEmailSignaturePictures(accessToken!, tenantContext.tenantName, tenantContext.api, version, ourRequest.token)
		setEmbeddedPictureItems(pictures)
	}

	const usedPictureNames: string[] = React.useMemo(() => {
		return signatureItems.reduce((acc: string[], item: EmailSignature) => {
			const pictureNames = item.EmbeddedPictures ? item.EmbeddedPictures.map((pic) => pic!?.PictureName) : [];
			return [...acc, ...pictureNames];
		}, []);
	}, [signatureItems])

	const updateSignatureItem = (key: 'new' | 'edit' | 'duplicate' | 'upload' | 'delete' | 'publishall' | 'publish', selectedItem: EmailSignature) => {
		setSelectedSignature(selectedItem)
		switch (key) {
			case 'new':

				setDialogEditStatus('new')
				setUploadSignature({ ...uploadSignature, id: '', name: '', data: '', inProgress: true })
				break;
			case 'edit':
				setDialogEditStatus('edit')
				setUploadSignature({ ...uploadSignature, id: selectedItem.SignatureName, name: selectedItem.SignatureName, data: JSON.stringify(selectedItem)!, inProgress: true })
				break;
			case 'duplicate':
				setDialogEditStatus('duplicate')
				setUploadSignature({ ...uploadSignature, id: selectedItem.SignatureName, name: selectedItem.SignatureName, data: JSON.stringify(selectedItem)!, inProgress: true })
				break;
			case 'upload':
				saveSignature({ ...uploadSignature, id: selectedItem.SignatureName, name: selectedItem.SignatureName, data: JSON.stringify(selectedItem) })
				break;
			case 'delete':
				setDeletedSignature({ ...deletedSignature, id: selectedItem.SignatureName, name: selectedItem.SignatureName, inProgress: true })
				break;
			case 'publish':
				setDialogPublishStatus('publish')
				break;
			case 'publishall':
				setDialogPublishStatus('publishall')
				break;
			default:
				break;
		}
	}

	const getAllData = (version?: string) => {
		setIsLoading(true)
		if (!version) { version = signatureVersion }
		else { setSingatureVersion(version) }
		getSignatures(version)
			.then(async () => await getEmbeddedPictures(version))
			.finally(() => {
				setIsLoading(false)
			})
	}

	React.useEffect(() => {
		return () => {
			ourRequest.cancel()
		}
	}, [])

	React.useEffect(() => {
		if (accessToken && tenantContext.api) {
			getAllData()
		}
	}, [accessToken, tenantContext.tenantName, tenantContext.api])

	if (isLoading) {
		return (
			<Stack horizontalAlign='center' verticalAlign='center' styles={{ root: { margin: '30px', height: '100%' } }}>
				<Spinner className='spinner' size={SpinnerSize.large} label='Loading email signatures...' />
			</Stack>
		)
	}

	return (
		<Stack >
			<Stack.Item className="" styles={{ root: { margin: '30px' } }}>
				<Text variant="xxLarge">
					Email Signatures
				</Text>
			</Stack.Item>
			<VersionSelector
				onVersionChange={(version) => {
					getAllData(version)
				}}
				version={signatureVersion}
				testDescription={<>
					You're now in test mode.
				</>}
				pubDescription={<>
					You're now in published mode.
				</>}
			/>
			<ContentFrame>
				<EmailSignaturesList
					setSelectedItem={(signature) => setSelectedSignature(signature)}
					onCommandItemClick={updateSignatureItem}
					selectedItem={selectedSignature}
					settings={tenantContext.settings}
					signatureItems={signatureItems!}
					signatureVersion={signatureVersion}
					title='Signatures'
				/>
			</ContentFrame>
			<ContentFrame>
				<EmailSignaturesPreview
					saveSignatures={(signature) => updateSignatureItem('upload', signature)}
					signature={selectedSignature!}
					embeddedPictureItems={embeddedPictureItems!}
					signatureVersion={signatureVersion}
				/>
			</ContentFrame>
			<ContentFrame>
				<EmailSignaturesPictures
					getEmbeddedPictures={getEmbeddedPictures}
					embeddedPictureItems={embeddedPictureItems!}
					usedPictureNames={usedPictureNames}
					signatureVersion={signatureVersion}
				/>
			</ContentFrame>
			<DialogOkAbort
				callbackOk={(checked) => { deleteSignature(deletedSignature, checked!) }}
				callbackAbort={() => { setDeletedSignature({ ...deletedSignature, inProgress: false, name: '', id: '' }) }}
				title='Delete Signature?'
				description={`Do you want to delete the signature ${deletedSignature.name}?`}
				checkBoxText='Delete unused embedded pictures'
				checkBoxDefaultValue={true}
				hidden={!deletedSignature.inProgress}
			/>
			<DialogEditSignature
				callbackOk={(signature) => { saveSignature({ ...uploadSignature, id: signature.SignatureName, name: signature.SignatureName, data: JSON.stringify(signature) }) }}
				callbackAbort={() => { setUploadSignature({ ...uploadSignature, inProgress: false, name: '', id: '', data: '' }) }}
				hidden={!uploadSignature.inProgress}
				selectedItem={selectedSignature}
				status={dialogEditStatus}
			/>
			<DialogOkAbort
				callbackOk={() => {
					publishEmailsignature(accessToken!, tenantContext.tenantName, tenantContext.api, dialogPublishStatus === 'publishall', selectedSignature.SignatureName, ourRequest.token)
						.then((res) => {
							if (!res.data) { res.data = [exampleEmailSignature] }
							setSingatureVersion('PUB')
							setSignatureItems(res.data)
							setSelectedSignature(res.data.find((s: EmailSignature) => s.SignatureName === selectedSignature.SignatureName) || res.data[0])
							getEmbeddedPictures('PUB')
						})
						.catch((error) => {
							console.error('Failed to publish signatures:', error)
							getAllData('PUB')
						})
						.finally(() => {
							setDialogPublishStatus('hide')
						})
				}}
				callbackAbort={() => { setDialogPublishStatus('hide') }}
				title={dialogPublishStatus === 'publishall' ? 'Publish all signatures?' : `Publish signature?`}
				description={dialogPublishStatus === 'publishall' ? `Do you want to publish all signatures?` : `Do you want to publish the signature ${selectedSignature.SignatureName}?`}
				hidden={dialogPublishStatus === 'hide'}
			/>
		</Stack >
	)
}

export default EmailSignatures;
import React, { useEffect, useState, } from 'react';
import {
	CommandBar,
	IButtonStyles,
	ICommandBarItemProps,
	IconButton,
	Image,
	ShimmerElementsGroup,
	Stack,
	Text,
	TooltipHost,
} from '@fluentui/react';
import { Shimmer, ShimmerElementType } from 'office-ui-fabric-react';
import Axios, { CancelTokenSource } from 'axios';
import { DialogUploadPictures } from '../dialogs/DialogUploadPictures';
import { deleteEmailsignaturePicture, uploadEmailsignaturePicture } from '../../helpers/PostToRestApi';
import { UploadFile } from '../../interfaces/UploadFile';
import { EmbeddedPictureItem } from '../../interfaces/EmbeddedPictureItem';
import { DialogOkAbort } from '../dialogs/DialogOkAbort';
import { AuthContext } from '../../contexts/AuthContext';
import { TenantContext } from '../../contexts/ContextWrapper';
import ErrorMessage from '../common/ErrorMessage';

export interface EmailSignaturesPreviewProps {
	getEmbeddedPictures: () => void;
	embeddedPictureItems: EmbeddedPictureItem[];
	usedPictureNames: string[];
	signatureVersion: string;
}

const EmailSignaturesPreview: React.FC<EmailSignaturesPreviewProps> = ({
	getEmbeddedPictures,
	embeddedPictureItems,
	usedPictureNames,
	signatureVersion,
}) => {
	const [hideUploadDialog, setHideUploadDialog] = useState(true);
	const [uploading, setUploading] = useState(false);
	const [uploadConfirmed, setUploadConfirmed] = useState(false);
	const [uploadItems, setUploadItems] = useState<UploadFile[]>([]);

	const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
	const [selectedItemName, setSelectedItemName] = useState<string>('')

	let cancelTokenSource: CancelTokenSource = Axios.CancelToken.source();

	const { accessToken } = React.useContext(AuthContext)
	const { tenantContext } = React.useContext(TenantContext)

	useEffect(() => {
		if (uploadConfirmed) {
			uploadPictures();
		}
	}, [uploadConfirmed]);

	useEffect(() => {
		return () => {
			cancelTokenSource.cancel();
		};
	}, []);

	const uploadPictures = async () => {
		setUploading(true);
		try {
			await Promise.all(
				uploadItems.map(async (file) => {
					const pic: EmbeddedPictureItem = { PictureName: file.filename, Base64: file.data };
					await uploadEmailsignaturePicture(
						accessToken!,
						tenantContext.tenantName,
						tenantContext.api,
						JSON.stringify(pic),
						cancelTokenSource.token
					);
				})
			);
			setTimeout(() => {
				getEmbeddedPictures();
			}, 500);
		} finally {
			setUploading(false);
		}
	};

	const downloadPicture = (name: string) => {
		const item = embeddedPictureItems.find((item) => item.PictureName === name);
		if (item) {
			const element = document.createElement('a');
			const extension = item.PictureName.split('.').pop()!?.toLowerCase();
			element.href = 'data:image/' + (extension === 'jpg' ? 'jpeg' : extension) + ';base64,' + item.Base64;
			element.download = item.PictureName;
			document.body.appendChild(element);
			element.click();
		}
	}

	const allowDelete = (name: string) => {
		return !usedPictureNames.includes(name);
	}

	const shimmerGroup = (
		<ShimmerElementsGroup shimmerElements={[{ type: ShimmerElementType.line, width: 100, height: 100 }]} />
	);

	const renderShimmerItems = () =>
		Array.from({ length: 20 }, (_, i) => (
			<Stack.Item key={i} styles={{ root: { padding: '5px' } }}>
				<Shimmer customElementsGroup={shimmerGroup} />
			</Stack.Item>
		));

	const buttonStyles: IButtonStyles = {
		root: {
			border: '1px',
			borderStyle: 'solid',
			borderColor: '#fff',
		},
		rootHovered: {
			border: '1px',
			borderStyle: 'solid',
			borderColor: '#e1dfdd',
		},
	};

	const commandItems = (): ICommandBarItemProps[] => {
		return [
			{
				key: 'upload',
				text: 'Upload',
				iconProps: { iconName: 'Upload' },
				onClick: () => setHideUploadDialog(false),
			},
			{
				key: 'download',
				text: 'Download',
				disabled: selectedItemName === '',
				iconProps: { iconName: 'Download' },
				onClick: () => downloadPicture(selectedItemName),
			},
			{
				key: 'delete',
				text: 'Delete',
				disabled: !allowDelete(selectedItemName) || selectedItemName === '',
				iconProps: { iconName: 'Delete' },
				onClick: () => setConfirmDelete(true),
			},
		];
	}

	return (
		<Stack className="Width100">
			<span className="Indent20">
				<h3>Uploaded Pictures</h3>
			</span>
			<CommandBar items={commandItems()} ariaLabel="Use left and right arrow keys to navigate between commands" />
			<ErrorMessage className="Indent20" message='Picture in use. Delete disabled' isVisible={!allowDelete(selectedItemName)} />
			<Stack horizontal wrap tokens={{ childrenGap: 15 }} styles={{ root: { margin: '20px' } }}>
				{embeddedPictureItems!?.length > 0
					? !uploading
						? embeddedPictureItems.map((item, idx) => {
							const tooltip = (
								<Stack tokens={{ childrenGap: 5 }}>
									<span style={{ fontWeight: 'bold' }}>{item.PictureName}</span>
									<Image
										src={`data:image/${item.PictureName.split('.').pop()?.toLowerCase() === 'jpg'
											? 'jpeg'
											: item.PictureName.split('.').pop()?.toLowerCase()
											};base64,${item.Base64}`}
										alt={item.PictureName}
									/>
								</Stack>
							);

							return (
								<Stack.Item key={idx} styles={{ root: { padding: '5px' } }}>
									<TooltipHost
										content={tooltip}
										id={idx.toString()}
										calloutProps={{ gapSpace: 0 }}
										styles={{ root: { display: 'inline-block' } }}
									>
										<IconButton
											ariaLabel={item.PictureName}
											styles={buttonStyles}
											style={{ width: 104, height: 104, padding: 2 }}
											checked={selectedItemName === item.PictureName}
											onClick={() => {
												setSelectedItemName(item.PictureName === selectedItemName ? '' : item.PictureName);
											}}
										>
											<Stack horizontalAlign="center" verticalAlign="center">
												<Image
													src={`data:image/${item.PictureName.split('.').pop()?.toLowerCase() === 'jpg'
														? 'jpeg'
														: item.PictureName.split('.').pop()?.toLowerCase()
														};base64,${item.Base64}`}
													alt={item.PictureName}
													styles={{
														image: { objectFit: 'contain', height: 'auto', width: 'auto', maxHeight: 86, maxWidth: 100 },
													}}
													onClick={() => navigator.clipboard.writeText(`<img src="${item.PictureName}">`)}
												/>
												<Text variant="xSmall">{item.PictureName}</Text>
											</Stack>
										</IconButton>
									</TooltipHost>
								</Stack.Item>
							);
						})
						: renderShimmerItems()
					: null}
			</Stack>
			<DialogUploadPictures
				setParentState={(newState: any) => {
					if ('hideUploadDialog' in newState) setHideUploadDialog(newState.hideUploadDialog);
					if ('uploadConfirmed' in newState) setUploadConfirmed(newState.uploadConfirmed);
					if ('uploadItems' in newState) setUploadItems(newState.uploadItems);
				}}
				hidden={hideUploadDialog}
				hideState="hideUploadDialog"
				confirmState="uploadConfirmed"
				uploadItemState="uploadItems"
			/>
			<DialogOkAbort
				callbackOk={() => {
					deleteEmailsignaturePicture(accessToken!, tenantContext.tenantName, tenantContext.api, selectedItemName, signatureVersion)
						.finally(() => {
							getEmbeddedPictures()
							setConfirmDelete(false)
						})
				}}
				callbackAbort={() => { setConfirmDelete(false) }}
				title='Delete signature picture?'
				description={`Do you want to delete the picture ${selectedItemName}?`}
				hidden={!confirmDelete}
			/>
		</Stack>
	);
};

export default EmailSignaturesPreview;

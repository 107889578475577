import React, { useState, useEffect } from "react";
import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDropdownOption,
  PrimaryButton,
  TextField,
} from "@fluentui/react";
import { APIItem } from "../../interfaces/APIItem";

export const DialogNewDomain: React.FunctionComponent<{
  setParentState: (x: any) => void;
  hideState: string;
  confirmState: string;
  newDomainItem: string;
  hidden: boolean;
  apis: APIItem[];
  defaultApi: string;
}> = (props) => {
  const [domainName, setDomainName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [invalidDomain, setInvalidDomain] = useState<boolean>(false);
  const [invalidCompany, setInvalidCompany] = useState<boolean>(false);
  const [emptyApis, setEmptyApis] = useState<boolean>(false);
  const [selectedApi, setSelectedApi] = useState<IDropdownOption | undefined>();

  useEffect(() => {
    if (!props.hidden) {
      if (!selectedApi) {
        setSelectedApi(
          apiOptions.find((item) => item.key === props.defaultApi)
        );
      }
    }
  }, [props.hidden, props.defaultApi, selectedApi]);

  const clearUseStates = () => {
    setDomainName("");
    setCompanyName("");
    setEmptyApis(false);
    setInvalidDomain(false);
    setInvalidCompany(false);
  };

  const dialogContentProps = {
    type: DialogType.largeHeader,
    title: "Create new domain",
    subText: "Domain name cannot be changed later",
  };

  const modalProps = {
    isBlocking: false,
    styles: { main: { maxWidth: 600 } },
  };

  const apiOptions: IDropdownOption[] = props.apis!?.map((item) => ({
    key: item.APIName,
    text: item.APIName,
    data: item.APIUri,
  }));

  const onChangeApi = (item: IDropdownOption): void => {
    setSelectedApi(item);
  };

  let ApiPart: JSX.Element = <></>;

  if (apiOptions.length > 1) {
    ApiPart = (
      <Dropdown
        placeholder="Select api"
        label="Select api"
        options={apiOptions}
        selectedKey={selectedApi ? selectedApi.key : props.defaultApi}
        onChange={(_event, option) => onChangeApi(option!)}
        errorMessage={emptyApis ? "Must select at least one API" : ""}
      />
    );
  }

  return (
    <Dialog
      hidden={props.hidden}
      onDismiss={() => props.setParentState({ [props.hideState]: true })}
      dialogContentProps={dialogContentProps}
      modalProps={modalProps}
    >
      <TextField
        label="Domain name"
        value={domainName}
        onChange={(_event, value) => setDomainName(value!)}
        errorMessage={invalidDomain ? "Not a valid domain name" : ""}
      />
      <TextField
        label="Company name"
        value={companyName}
        onChange={(_event, value) => setCompanyName(value!)}
        errorMessage={invalidCompany ? "Not a valid company name" : ""}
      />
      {ApiPart}
      <DialogFooter>
        <PrimaryButton
          onClick={() => {
            setEmptyApis(!selectedApi);

            if (!!selectedApi) {
              clearUseStates();
              props.setParentState({
                [props.hideState]: true,
                [props.confirmState]: true,
                [props.newDomainItem]: {
                  APIName: selectedApi?.text,
                  APIUri: selectedApi?.data,
                  DomainName: domainName,
                  CompanyName: companyName,
                },
              });
            }
          }}
          text="Create"
        />
        <DefaultButton
          onClick={() => {
            clearUseStates();
            props.setParentState({
              [props.hideState]: true,
            });
          }}
          text="Cancel"
        />
      </DialogFooter>
    </Dialog>
  );
};

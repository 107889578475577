import React, { useContext } from 'react';
import { Image, Nav, INavStyles, Stack } from '@fluentui/react';
import { AllMenuItems } from '../../helpers/MenuHelper';
import { getAnimStyleNavRight } from '../../helpers/Animations';
import { TenantContext, UserContext } from '../../contexts/ContextWrapper';

export interface NavigationPaneProps {
	selectPage: (x: string) => void;
	selectedPage: string;
}

const navStyles: Partial<INavStyles> = {
	root: {
		overflowY: 'auto',
	},
};

const NavigationPane: React.FC<NavigationPaneProps> = (props) => {

	const { tenantContext } = useContext(TenantContext);
	const { userContext } = useContext(UserContext);

	return (
		<Stack styles={getAnimStyleNavRight(false)}>
			<div style={{ paddingLeft: '30px', paddingTop: '10px' }}>
				{tenantContext.logo !== '' ? (
					<Image src={'data:image/png;base64,' + tenantContext.logo} style={{ maxHeight: 80, maxWidth: 120 }} alt="" />
				) : null}
				<h2>{tenantContext.settings?.CompanyName}</h2>
			</div>
			<Nav
				onLinkClick={(_ev, item) => props.selectPage(item!?.key!)}
				selectedKey={props.selectedPage}
				ariaLabel="Nav menu"
				styles={navStyles}
				groups={[{ links: [...AllMenuItems(userContext.userData, tenantContext.settings)] }]}
			/>
		</Stack>
	);
};

export default NavigationPane;

import * as React from 'react';
import { DefaultButton, Image, registerIcons, Stack, Text } from '@fluentui/react';
import { CancelTokenSource } from 'axios';
import { ExtensionItem } from '../../interfaces/ExtensionItem';
import SettingsCompany from '../../helpers/SettingsCompany';
import Parser from 'html-react-parser';
import moment from 'moment';
import EncapsulatedSVG from '../common/EncapsultedSVG';

export interface SettingsMoreProps {
	editedSettings: SettingsCompany;
	availableExtensions?: ExtensionItem[],
	allExtensions?: ExtensionItem[],
	displayName: string
}

export interface SettingsMoreState {

}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

// const iconClass = mergeStyles({
// 	fontSize: 100,
// 	height: 100,
// 	width: 100,
// 	margin: '0 25px',
// });

const ImageVaultLogo = require('../../assets/imagevault.svg');
const UnsplashLogo = require('../../assets/unsplash.svg');

registerIcons({
	icons: {
		'ImageVault': (
			<Image alt='ImageVault Logo' src={ImageVaultLogo} />
		),
		'Unsplash': (
			<Image alt='Unsplash Logo' src={UnsplashLogo} />
		),
	},
});

export default class SettingsMore extends React.Component<SettingsMoreProps, SettingsMoreState> {
	constructor(props: SettingsMoreProps) {
		super(props);
		this.state = {
		};
	}

	componentWillUnmount() {
		try {
			ourRequest.cancel()
		} catch (error) {

		}
	}



	extensionItem = (item: ExtensionItem, status: string, _added: boolean): JSX.Element => {
		const svgString: string = `data:image/svg+xml,${encodeURIComponent(item.ImageString || '')}`
		return (
			<div key={item.ExtensionName} style={{ position: 'relative' }}>
				{status === 'new' && <div style={{ position: 'absolute', top: '-10px', right: '15px', borderRadius: '10px', backgroundColor: '#eec73a', padding: '3px 15px 3px 15px', fontSize: '12px', fontWeight: 'bold' }}>NEW!</div>}
				{status === 'comingsoon' && <div style={{ position: 'absolute', top: '-10px', right: '15px', borderRadius: '10px', backgroundColor: '#a8d4ba', padding: '3px 10px 3px 10px', fontSize: '12px', fontWeight: 'bold' }}>COMING SOON!</div>}
				<Stack key={item.ExtensionName} verticalAlign="start" tokens={{ childrenGap: '10px' }} styles={{ root: { width: '250px', height: '290px', padding: '10px', border: '1px solid #e1dfdd', backgroundColor: '#faf9f8' } }}>
					<Stack.Item align="center">
						<EncapsulatedSVG>
							<img width='100%' height='100%' src={svgString} style={{ maxHeight: 64 }} alt={item.ExtensionName} />
						</EncapsulatedSVG>
					</Stack.Item>
					<Stack.Item align="center" >
						<Text className="ms-fontSize-18 ms-fontWeight-semibold">{item.ExtensionName}</Text>
					</Stack.Item>
					<Stack.Item align="center">
						<Stack horizontal tokens={{ childrenGap: '5px' }}>
							{item.ExistInWord && <div className="ms-BrandIcon--icon16 ms-BrandIcon--word" />}
							{item.ExistInPowerPoint && <div className="ms-BrandIcon--icon16 ms-BrandIcon--powerpoint" />}
							{item.ExistInExcel && <div className="ms-BrandIcon--icon16 ms-BrandIcon--excel" />}
							{item.ExistInOutlook && <div className="ms-BrandIcon--icon16 ms-BrandIcon--outlook" />}
						</Stack>
					</Stack.Item>
					<Stack.Item align="center" styles={{ root: { height: '90px', lineHeight: '110%' } }}>
						<Text variant='smallPlus'>
							{/* {item.Description} */}
							{Parser(`${this.props.allExtensions?.find(v => v.ExtensionName === item.ExtensionName)?.Description}`)}
						</Text>
					</Stack.Item>
					<Stack.Item align="center" >
						<Stack horizontal verticalAlign='center' tokens={{ childrenGap: '5px' }}>
							<DefaultButton
								text={_added ? "Active" : "Contact me"}
								iconProps={{ iconName: _added ? "SkypeCircleCheck" : "Mail" }}
								primary={!_added}
								disabled={status === 'comingsoon'}
								onClick={() => {
									if (_added) { return }
									let anchor: HTMLAnchorElement = document.createElement("a");
									anchor.href = 'mailto:info@rehngruppen.se?subject=TemplateExtender - ' + this.props.editedSettings.CompanyName + ' - ' + item.ExtensionName + '&body=Hello, I\'m interested in ' + item.ExtensionName + ' for TemplateExtender. Please contact me for further information. %0A%0ABest regards,%0A' + this.props.displayName;
									anchor.click(); //Downloaded file
								}}
							/>
						</Stack>
					</Stack.Item>
				</Stack>
			</div>

		)
	}

	render() {
		return (
			<Stack horizontal wrap tokens={{ childrenGap: 20 }} styles={{ root: { paddingTop: '5px' } }}>
				{this.props.editedSettings.Extensions?.map((settingsItem) => {
					const item: ExtensionItem = this.props.allExtensions?.find((val) => val.ExtensionName === settingsItem.ExtensionName)!
					const dateDiff: number = item.ProductionDate ? (new Date(moment().format('YYYY-MM-DD')).getTime() - new Date(moment(item.ProductionDate).format('YYYY-MM')).getTime()) / 86400000 || -1000 : 1000
					console.log(`${item.ExtensionName} - ${dateDiff}`)
					if (item && item.Description && dateDiff > -100) {
						return (
							this.extensionItem(item, dateDiff >= 0 ? dateDiff > 60 ? '' : 'new' : 'comingsoon', true)
						)
					}
					return null
				})}
				{this.props.availableExtensions?.map((item) => {
					const dateDiff: number = item.ProductionDate ? (new Date(moment().format('YYYY-MM-DD')).getTime() - new Date(moment(item.ProductionDate).format('YYYY-MM')).getTime()) / 86400000 || -1000 : 1000
					console.log(`${item.ExtensionName} - ${dateDiff}`)
					if (item.Description && dateDiff > -100) {
						return (
							this.extensionItem(item, dateDiff >= 0 ? dateDiff > 60 ? '' : 'new' : 'comingsoon', false)
						)
					}
					return null
				})}
			</Stack>
		)
	}
}
import * as React from 'react';
import { Image, ImageFit, Spinner, SpinnerSize, Stack, Text } from '@fluentui/react';
import IconsDetails from './IconsDetails';
import IconsCategories from './IconsCategories';
import { GetIconsList } from '../../helpers/GetFromRestApi';
import { hasValues } from '../../helpers/MiscFunctions';
import Axios, { CancelTokenSource } from 'axios';
import SettingsCompany from '../../helpers/SettingsCompany';
import { exampleIconList, IconListItem } from '../../interfaces/IconListItem';
import { GroupInfo } from '../../interfaces/GroupInfo';
import { UserAdminItem } from '../../interfaces/UserAdminItem';
import { VersionSelector } from '../common/VersionSelector';
import { ContentFrame } from '../common/ContentFrame';
import { getTooltipFromString } from '../../helpers/Descriptions';

export interface IconsProps {
  accessToken: string
  domain: string
  api: string
  groupIds: GroupInfo[]
  settings: SettingsCompany
  userData: UserAdminItem
}

export interface IconsState {
  iconItems: IconListItem[]
  selectedCategory: string
  iconWidth: number
  iconHeight: number
  templateVersion: string
  categories: string[]
}

let ourRequest: CancelTokenSource = {} as CancelTokenSource;

export default class Icons extends React.Component<IconsProps, IconsState> {
  constructor(props: IconsProps) {
    super(props);
    this.state = {
      iconItems: [],
      selectedCategory: '',
      iconWidth: 64,
      iconHeight: 64,
      templateVersion: 'TEST',
      categories: [],
    };
    this.boundSetState = this.setState.bind(this);
    this.getIcons = this.getIcons.bind(this);
  }

  boundSetState = (x: IconsState) => { this.setState(x) }

  getIcons = async (version: string) => {
    this.setState({ iconItems: [], selectedCategory: '' })
    ourRequest = Axios.CancelToken.source()
    let icons: IconListItem[] = await GetIconsList(this.props.accessToken!, this.props.domain, this.props.api, version, JSON.stringify([{ groupID: '123' }]), 'ALL-ICONS', ourRequest.token)
    console.log(icons)
    // console.log(hasValues(icons[0]?.libraryName))
    if (!hasValues(icons) || !hasValues(icons[0]?.libraryName)) { icons = exampleIconList }
    this.setState({
      iconItems: icons,
      categories: icons.filter((val, idx, arr) => arr.findIndex(item => item.libraryName === val.libraryName) === idx).map((item: IconListItem) => item.libraryName),
      templateVersion: version,
      selectedCategory: icons[0].libraryName,
      iconHeight: icons[0].previewHeight,
      iconWidth: icons[0].previewWidth,
    })
  }

  changeCategory = (category: string, height: number, width: number) => {
    this.setState({
      selectedCategory: category,
      iconHeight: height,
      iconWidth: width,
    })
  }

  componentWillUnmount() {
    try {
      ourRequest.cancel()
    } catch (error) {

    }
  }

  // shouldComponentUpdate(nextProps: IconsProps, nextState: IconsState) {
  // 	if (nextProps.accessToken !== this.props.accessToken ||
  // 		nextProps.apiUri !== this.props.apiUri ||
  // 		nextState.templateVersion !== this.state.templateVersion ||
  // 		nextState.selectedCategory !== this.state.selectedCategory ||
  // 		this.state.iconItems === undefined
  // 		) {
  // 		return true
  // 	}
  // 	return false
  // }

  componentDidUpdate(prevProps: IconsProps, prevState: IconsState) {
    if (prevProps.accessToken !== this.props.accessToken ||
      prevProps.domain !== this.props.domain ||
      prevProps.api !== this.props.api ||
      prevState.templateVersion !== this.state.templateVersion //||

      // this.state.iconItems?.length === 0
    ) {
      if (this.props.accessToken && this.props.api) {
        this.getIcons(this.state.templateVersion!)
      }
    }
    // if (!prevState.iconCategoriesUpdating && this.state.iconCategoriesUpdating) {
    // 	setTimeout(() => {
    // 		this.setState({ iconCategoriesUpdating: false })
    // 	}, 300)
    // }
    // if (!prevState.iconDetailsUpdating && this.state.iconDetailsUpdating) {
    // 	setTimeout(() => {
    // 		this.setState({ iconDetailsUpdating: false })
    // 	}, 300)
    // }
  }

  componentDidMount() {
    this.getIcons(this.state.templateVersion!)
  }

  render() {
    const settingGuideImage = require('../../assets/settings-guide.png');
    return (
      <Stack >
        <Stack.Item className="" styles={{ root: { margin: '30px' } }}>
          <Text variant="xxLarge">
            Icons
          </Text>
        </Stack.Item>
        <VersionSelector
          onVersionChange={this.getIcons}
          version={this.state.templateVersion}
          testDescription={<>
            You're now in test mode. Contact Rehngruppen to upload icons. Icons will not be available to all users before they are published by Rehngruppen.<br />
            Users with an account in this portal are able to switch to Test version in the 365TemplateExtender addin. To reach the <b>Template Version</b> setting, click the bottom right menu and select <b>Settings</b> {getTooltipFromString(<><Image src={settingGuideImage} imageFit={ImageFit.contain} alt="" /></>)}
          </>}
          pubDescription={<>
            You're now in published mode. All icons are available for all users. Contact Rehngruppen to upload or publish icons.
          </>}
        />
        {!this.state.iconItems?.length && <Spinner className='spinner' size={SpinnerSize.large} label='Loading icons...' />}
        {['TEST', 'PUB'].map((version) => {
          if (this.state.iconItems?.length) {
            return (
              <ContentFrame key={version} hidden={version !== this.state.templateVersion}>
                <IconsCategories
                  changeCategory={this.changeCategory}
                  getIcons={this.getIcons}
                  iconItems={this.state.iconItems!}
                  settings={this.props.settings}
                  groupIds={this.props.groupIds}
                  selectedCategory={this.state.selectedCategory}
                  templateVersion={version}
                />
              </ContentFrame>
            )
          }
          else { return null }
        })}
        {this.state.categories && this.state.categories!.map((category: string) => {
          if (this.state.iconItems?.length) {
            return (
              <ContentFrame key={category} hidden={category !== this.state.selectedCategory}>
                <IconsDetails
                  iconItems={this.state.iconItems}
                  iconHeight={this.state.iconHeight!}
                  iconWidth={this.state.iconWidth!}
                  selectedCategory={category}
                  settings={this.props.settings}
                  groupIds={this.props.groupIds}
                />
              </ContentFrame>
            )
          }
          else { return null }
        })}
      </Stack>
    )
  }
}

import * as React from 'react';
import { CommandBar, DefaultButton, Dropdown, ICommandBarItemProps, IconButton, Image, Label, Pivot, PivotItem, Separator, Spinner, SpinnerSize, Stack, Text, TextField, Toggle, TooltipHost, } from '@fluentui/react';
import { AcademyItem, AcademyLearningLevel, AcademyLevel, AcademyPlatform } from '../../interfaces/AcademyItem';
import * as DOMPurify from 'dompurify';
import HTMLEditor from '../common/HTMLEditor';
import { AuthContext } from '../../contexts/AuthContext';
import { TenantContext } from '../../contexts/ContextWrapper';
import { deleteAcademyImage, uploadAcademyImage } from '../../helpers/PostToRestApi';
import { GetAcademyItem, GetGptToken } from '../../helpers/GetFromRestApi';
import { getGPTResponse } from '../../helpers/ChatGPT';
import { cloneObject } from '../../helpers/MiscFunctions';

interface AcademyLessonProps {
    selectedId: string
    learningLevels: AcademyLearningLevel[]
    platforms: AcademyPlatform[]
    levels: AcademyLevel[]
    updateItem: (item: AcademyItem, onDuplicateSlug: (message: string) => void) => void
    isEdited: boolean
    setIsEdited: (isEdited: boolean) => void
}

enum ContentType {
    'none' = 0,
    'arcade' = 1,
    'image' = 2,
    'youtube' = 3
}

interface SlugError {
    lang: string,
    message: string
}

const AcademyLesson: React.FC<AcademyLessonProps> = (props) => {

    const { accessToken } = React.useContext(AuthContext);
    const { tenantContext } = React.useContext(TenantContext);

    const [savedItem, setSavedItem] = React.useState<AcademyItem | null>(null);
    const [editedItem, setEditedItem] = React.useState<AcademyItem | null>(null);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const [selectedLang, setSelectedLang] = React.useState<string>('sv')
    const [mode, setMode] = React.useState<'preview' | 'code' | 'editor'>('preview')
    const [images, setImages] = React.useState<string[]>([])

    const [translating, setTranslating] = React.useState<boolean>(false)
    const [showInfo, setShowInfo] = React.useState<boolean>(true)

    const [slugError, setSlugError] = React.useState<SlugError | null>(null)

    React.useEffect(() => {
        setSlugError(null)
        if (props.selectedId === 'new') {
            const newItem: AcademyItem = {
                academyID: 'new',
                Info: [
                    { language: 'sv', Name: '', Description: '', Slug: '', SEO: { SEO_title: '', page_title: '', meta_description: '' }, contentTypeID: ContentType.none, ArcadeLink: '', ArcadeLength: 0, YoutubeLink: '', Image: '', Body: '', Tags: [] },
                    { language: 'en', Name: '', Description: '', Slug: '', SEO: { SEO_title: '', page_title: '', meta_description: '' }, contentTypeID: ContentType.none, ArcadeLink: '', ArcadeLength: 0, YoutubeLink: '', Image: '', Body: '', Tags: [] }
                ],
                Level: props.levels[0] || { LevelID: '', LevelName: '' },
                LearningLevel: props.learningLevels[0] || { LearningLevelID: '', LearningLevelName: '' },
                Platform: props.platforms[0] || { PlatformID: '', PlatformName: '' },
                ShowInAddIn: true,
            } as AcademyItem;
            setSavedItem(cloneObject(newItem))
            setEditedItem(cloneObject(newItem))
        }
        else if (props.selectedId) {
            getItemById(props.selectedId)
        }
    }, [props.selectedId])

    React.useEffect(() => {
        if (mode === 'code' && editedItem) {
            setEditedItem({
                ...editedItem,
                Info: editedItem.Info.map((info) => ({
                    ...info,
                    Body: info.Body?.replace(/></g, '>\n<') || null
                }))
            })
        }
    }, [mode])

    React.useEffect(() => {
        if (editedItem && savedItem) {
            props.setIsEdited(JSON.stringify(editedItem) !== JSON.stringify(savedItem));
        }
    }, [editedItem, savedItem]);



    const getCommandItems = (): ICommandBarItemProps[] =>
        [
            {
                key: 'save',
                text: 'Save',
                iconProps: { iconName: 'Save' },
                onClick: () => {
                    setSlugError(null)
                    props.updateItem(editedItem!, (message) => {
                        console.log(message)
                        const msg: string[] = message.split('|')
                        setSelectedLang('all')
                        setSlugError({ lang: msg[0], message: msg[1] }
                        )
                    })
                },
            },
            {
                key: 'cancel',
                text: 'Cancel',
                disabled: !props.isEdited,
                iconProps: { iconName: 'Cancel' },
                onClick: () => {
                    setEditedItem(cloneObject(savedItem))
                    setSlugError(null)
                    props.setIsEdited(false)
                },
            },
            {
                key: 'info',
                text: showInfo ? 'Hide Info' : 'Show Info',
                iconProps: { iconName: showInfo ? 'Hide' : 'RedEye' },
                onClick: () => { setShowInfo(!showInfo) },
            }
        ]

    const fieldStyle = { root: { width: '100%', maxWidth: '600px' } }

    const replacements: { [key: string]: string } = {
        'å': 'a',
        'ä': 'a',
        'ö': 'o',
        'é': 'e',
        'è': 'e',
        'ü': 'u',
        '*': '-',
    };

    const getItemById = async (academyID: string) => {
        setIsLoading(true);
        try {
            const response: AcademyItem = await GetAcademyItem(accessToken!, tenantContext.api, academyID);
            if (!response) { return }
            if (!response.Info[0].SEO) { response.Info[0].SEO = { SEO_title: response.Info[0].Name!, page_title: response.Info[0].Name ? `${response.Info[0].Name} - RehnAkademi` : '', meta_description: response.Info[0].Description! } }
            if (!response.Info[1].SEO) { response.Info[1].SEO = { SEO_title: response.Info[1].Name!, page_title: response.Info[1].Name ? `${response.Info[1].Name} - Rehn Academy` : '', meta_description: response.Info[1].Description! } }
            setSavedItem(cloneObject(response))
            setEditedItem(cloneObject(response))
            setIsLoading(false);
        } catch (error) {
            console.error("Failed to fetch academy item", error);
            setIsLoading(false);
        }
    }

    const handleFileChange = (event: any, index: number) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                images[index] = reader.result!?.toString() || ''
                setImages([...images])
                uploadAcademyImage(accessToken!, tenantContext.tenantName, tenantContext.api, editedItem!.academyID, editedItem!.Info[index].language, reader.result!?.toString())
            };
            reader.readAsDataURL(file); // Convert the image to Base64
        }
    };

    const uploadImageClick = (index: number) => {
        const fileInput = document.createElement('input');
        fileInput.type = 'file';
        fileInput.accept = 'image/jpeg, image/png, image/gif';
        fileInput.onchange = (event) => handleFileChange(event, index);
        fileInput.click();
    };

    const deleteImageClick = (index: number) => {
        images[index] = ''
        setImages([...images])
        deleteAcademyImage(accessToken!, tenantContext.tenantName, tenantContext.api, editedItem!.academyID, editedItem!.Info[index].language)
    };

    const setContentType = (contentType: ContentType, index: number) => {
        if (contentType === editedItem!.Info[index].contentTypeID) {
            contentType = ContentType.none
        }
        editedItem!.Info[index].contentTypeID = contentType
        setEditedItem({ ...editedItem! })
    }

    const onTranslateClick = async (indexFrom: number, indexTo: number) => {
        setTranslating(true)
        setMode('preview')
        const language = indexTo === 0 ? 'Swedish' : 'English'
        const token = JSON.parse(await GetGptToken(accessToken!))
        if (!token) {
            setTranslating(false)
            return
        }
        if (!editedItem!.Info[indexTo].SEO) { editedItem!.Info[indexTo].SEO = { SEO_title: '', page_title: '', meta_description: '' } }
        if (editedItem!.Info[indexFrom].Name) {
            const name = await getGPTResponse(token, `Translate message to ${language}`, editedItem!.Info[indexFrom].Name!)
            if (!editedItem!.Info[indexTo].Name) { editedItem!.Info[indexTo].Name = name }
            if (!editedItem!.Info[indexTo].Slug) { editedItem!.Info[indexTo].Slug = name.toLowerCase().replace(/ /g, '-').replace(/[åäö]/g, (char) => replacements[char]) }
            // if (!editedItem!.Info[indexTo].SEO!?.SEO_title) { editedItem!.Info[indexTo].SEO!.SEO_title = name }
            if (!editedItem!.Info[indexTo].SEO!?.page_title) { editedItem!.Info[indexTo].SEO!.page_title = `${name} - ${language === 'Swedish' ? 'RehnAkademi' : 'Rehn Academy'}` }
        }
        if (editedItem!.Info[indexFrom].Description) {
            const description = await getGPTResponse(token, `Translate message to ${language}`, editedItem!.Info[indexFrom].Description!)
            if (!editedItem!.Info[indexTo].Description) { editedItem!.Info[indexTo].Description = description }
            if (!editedItem!.Info[indexTo].SEO!.meta_description) { editedItem!.Info[indexTo].SEO!.meta_description = description }
        }
        if (editedItem!.Info[indexFrom].Tags.length > 0) {
            const tags = await getGPTResponse(token, `Translate json to ${language} and return in the same format`, JSON.stringify(editedItem!.Info[indexFrom].Tags!))
            if (!editedItem!.Info[indexTo].Tags) { editedItem!.Info[indexTo].Tags = JSON.parse(tags) || [] }
        }
        if (editedItem!.Info[indexFrom].Body) {
            editedItem!.Info[indexTo].Body = await getGPTResponse(token, `Translate html code to ${language} and return in the same html format. ${language === 'Swedish' ? 'All application menu commands should be in bold and have both English and Swedish captions in format [English]/[Swedish] with no spaces before or after the slash.' : ''}`, editedItem!.Info[indexFrom].Body!)
        }
        setEditedItem({ ...editedItem! })
        setTranslating(false)
    }

    if (!editedItem) { return null }

    if (isLoading) { return <Spinner label='Loading' size={SpinnerSize.large} styles={{ root: { padding: 20 } }} /> }

    return (
        <Stack styles={{ root: { width: '100%', padding: '0px 20px' } }}>
            <h3>Lesson</h3>
            <CommandBar
                items={getCommandItems()}
                ariaLabel="Use left and right arrow keys to navigate between commands"
            />
            <Stack tokens={{ childrenGap: 5 }}>
                {showInfo && <>
                    <Dropdown
                        styles={fieldStyle}
                        label="Platform"
                        options={props.platforms.map((platform) => {
                            return {
                                key: platform.PlatformID,
                                text: platform.PlatformID,
                            }
                        })}
                        selectedKey={editedItem.Platform?.PlatformID}
                        onChange={(_ev, option) => {
                            editedItem.Platform = props.platforms.find(p => p.PlatformID === option!.key)!
                            setEditedItem({ ...editedItem })
                        }}
                    />
                    <Dropdown
                        styles={fieldStyle}
                        label="Learning level"
                        options={props.learningLevels.map((level) => {
                            return {
                                key: level.learningLevelID,
                                text: level.labels.find(l => l.language.toLowerCase() === 'en')?.Name || level.Description,
                            }
                        })}
                        selectedKey={editedItem.LearningLevel?.learningLevelID}
                        onChange={(_ev, option) => {
                            editedItem.LearningLevel = props.learningLevels.find(l => l.learningLevelID === option!.key)!
                            setEditedItem({ ...editedItem })
                        }}
                    />
                    <Dropdown
                        styles={fieldStyle}
                        label="Level"
                        options={props.levels.map((level) => {
                            return {
                                key: level.levelID,
                                text: level.Name,
                            }
                        })}
                        selectedKey={editedItem.Level.levelID}
                        onChange={(_ev, option) => {
                            editedItem.Level = props.levels.find(l => l.levelID === option!.key)!
                            setEditedItem({ ...editedItem })
                        }}
                    />
                    <Toggle
                        label={'Show in add-in'}
                        // inlineLabel
                        checked={editedItem.ShowInAddIn}
                        onChange={(_ev, checked) => {
                            editedItem.ShowInAddIn = checked!
                            setEditedItem({ ...editedItem })
                        }}
                    />
                    <Separator />
                </>}
                <Pivot
                    selectedKey={selectedLang}
                    onLinkClick={(item) => { setSelectedLang(item!.props.itemKey!) }}
                >
                    {editedItem.Info.map((info) => {
                        return (
                            <PivotItem
                                key={info.language}
                                itemKey={info.language}
                                headerText={info.language === 'sv' ? 'Svenska' : 'English'}
                            />
                        )
                    })}
                    <PivotItem
                        itemKey='all'
                        headerText='All'
                    />
                </Pivot>
                <Stack horizontal verticalAlign='center'>
                    {selectedLang !== 'sv' && editedItem.Info[0].Body && !editedItem.Info[1].Body && (
                        <DefaultButton
                            iconProps={{ iconName: 'Translate' }}
                            disabled={translating}
                            styles={{ root: { maxWidth: '300px' } }}
                            onClick={() => { onTranslateClick(0, 1) }}
                        >Translate Body to English</DefaultButton>
                    )}
                    {selectedLang !== 'sv' && editedItem.Info[1].Body && !editedItem.Info[0].Body && (
                        <DefaultButton
                            iconProps={{ iconName: 'Translate' }}
                            disabled={translating}
                            styles={{ root: { maxWidth: '300px' } }}
                            onClick={() => { onTranslateClick(1, 0) }}
                        >Translate Body to Swedish</DefaultButton>
                    )}
                    {translating && <Spinner label='Translating' size={SpinnerSize.medium} styles={{ root: { marginLeft: 10 } }} />}
                </Stack>
                <Separator />

                <Stack horizontal horizontalAlign="space-between" tokens={{ childrenGap: '10px' }} styles={{ root: { marginTop: 5 } }}>

                    {editedItem.Info.map((info, index) => {
                        if (selectedLang !== info.language && selectedLang !== 'all') { return null }
                        // if (info.SEO === null) { info.SEO = { SEO_title: info.Name!, page_title: `${info.Name} - ${info.language === 'sv' ? 'RehnAkademi' : 'Rehn Academy'}`, meta_description: info.Description! } }
                        return (
                            <Stack.Item key={info.language} styles={{ root: { width: '100%' } }}>
                                {/* <Label>{info.language}</Label> */}
                                {showInfo && <>
                                    <TextField
                                        styles={fieldStyle}
                                        label='Heading'
                                        value={info.Name! || ''}
                                        onChange={(_ev, newValue) => {
                                            editedItem.Info[index].Name = newValue!
                                            editedItem.Info[index].SEO!.SEO_title = newValue!
                                            editedItem.Info[index].SEO!.page_title = `${newValue!} - ${info.language === 'sv' ? 'RehnAkademi' : 'Rehn Academy'}`
                                            editedItem.Info[index].Slug = newValue!.toLowerCase().replace(/ /g, '-').replace(/[åäö]/g, (char) => replacements[char])
                                            setEditedItem({ ...editedItem })
                                        }}
                                        // errorMessage={info.Name?.length! > 70 ? `Used ${info.Name?.length} characters. Recommended max 70.` : info.Name?.length! < 20 ? `Used ${info.Name?.length} characters. Recommended min 20.` : ''}
                                        // description={info.Name?.length! <= 70 && info.Name?.length! >= 20 ? `Used ${info.Name?.length} characters. Recommended 20-70.` : ''}
                                        onRenderDescription={(() => {
                                            if (info.Name?.length! > 70) { return <Text variant='small' style={{ color: '#986f0b' }}>Used {info.Name?.length} characters. Recommended max 70.</Text> }
                                            if (info.Name?.length! < 20) { return <Text variant='small' style={{ color: '#986f0b' }}>Used {info.Name?.length} characters. Recommended min 20.</Text> }
                                            return <Text variant='small' style={{ color: 'grey' }}>Used {info.Name?.length} characters. Recommended 20-70.</Text>
                                        })}
                                    />
                                    <TextField
                                        styles={fieldStyle}
                                        label='Slug'
                                        value={info.Slug! || ''}
                                        onChange={(_ev, newValue) => {
                                            editedItem.Info[index].Slug = newValue!
                                            setEditedItem({ ...editedItem })
                                        }}
                                        errorMessage={slugError?.lang === info.language ? slugError.message : ''}
                                    />
                                    <TextField
                                        styles={fieldStyle}
                                        label='Description'
                                        multiline
                                        rows={3}
                                        value={info.Description! || ''}
                                        onChange={(_ev, newValue) => {
                                            editedItem.Info[index].Description = newValue!
                                            editedItem.Info[index].SEO!.meta_description = newValue!
                                            setEditedItem({ ...editedItem })
                                        }}
                                    />
                                    <Separator />
                                    <Label>Search Engine Optimization</Label>
                                    {/* <TextField
                                    styles={fieldStyle}
                                    label='SEO Title'
                                    value={info.SEO!?.SEO_title! || ''}
                                    onChange={(_ev, newValue) => {
                                        editedItem.Info[index].SEO!.SEO_title = newValue!
                                        setEditedItem({ ...editedItem })
                                    }}
                                    errorMessage={info.SEO!?.SEO_title.length! > 60 ? `Used ${info.SEO!?.SEO_title.length} characters. Recommended max 60.` : info.SEO!?.SEO_title.length! < 50 ? `Used ${info.SEO!?.SEO_title.length} characters. Recommended min 50.` : ''}
                                    description={info.SEO!?.SEO_title.length!<= 60 &&  info.SEO!?.SEO_title.length! >= 50 ? `Used ${info.SEO!?.SEO_title.length} characters. Recommended 50-60.` : ''}
                                /> */}
                                    <TextField
                                        styles={fieldStyle}
                                        label='Page Title'
                                        value={info.SEO!?.page_title || ''}
                                        onChange={(_ev, newValue) => {
                                            editedItem.Info[index].SEO!.page_title = newValue!
                                            setEditedItem({ ...editedItem })
                                        }}
                                        // errorMessage={info.SEO!?.page_title!?.length! > 60 ? `Used ${info.SEO!?.page_title!?.length} characters. Recommended max 60.` : info.SEO!?.page_title!?.length! < 50 ? `Used ${info.SEO!?.page_title!?.length} characters. Recommended min 50.` : ''}
                                        // description={info.SEO!?.page_title!?.length! <= 60 && info.SEO!?.page_title!?.length! >= 50 ? `Used ${info.SEO!?.page_title!?.length} characters. Recommended 50-60.` : ''}
                                        onRenderDescription={(() => {
                                            if (info.SEO!?.page_title!?.length! > 60) { return <Text variant='small' style={{ color: '#986f0b' }}>Used {info.SEO!?.page_title!?.length} characters. Recommended max 60.</Text> }
                                            if (info.SEO!?.page_title!?.length! < 50) { return <Text variant='small' style={{ color: '#986f0b' }}>Used {info.SEO!?.page_title!?.length} characters. Recommended min 50.</Text> }
                                            return <Text variant='small' style={{ color: 'grey' }}>Used {info.SEO!?.page_title!?.length} characters. Recommended 50-60.</Text>
                                        })}
                                    />
                                    <TextField
                                        styles={fieldStyle}
                                        label='Meta Description'
                                        value={info.SEO!?.meta_description || ''}
                                        multiline
                                        rows={3}
                                        onChange={(_ev, newValue) => {
                                            editedItem.Info[index].SEO!.meta_description = newValue!
                                            setEditedItem({ ...editedItem })
                                        }}
                                        // errorMessage={info.SEO!?.meta_description!?.length! > 160 ? `Used ${info.SEO!?.meta_description!?.length} characters. Recommended max 160.` : info.SEO!?.meta_description!?.length! < 150 ? `Used ${info.SEO!?.meta_description!?.length} characters. Recommended min 150.` : ''}
                                        // description={info.SEO!?.meta_description!?.length! <= 160 && info.SEO!?.meta_description!?.length! >= 150 ? `Used ${info.SEO!?.meta_description!?.length} characters. Recommended 150-160.` : ''}
                                        onRenderDescription={(() => {
                                            if (info.SEO!?.meta_description!?.length! > 160) { return <Text variant='small' style={{ color: '#986f0b' }}>Used {info.SEO!?.meta_description!?.length} characters. Recommended max 160.</Text> }
                                            if (info.SEO!?.meta_description!?.length! < 150) { return <Text variant='small' style={{ color: '#986f0b' }}>Used {info.SEO!?.meta_description!?.length} characters. Recommended min 150.</Text> }
                                            return <Text variant='small' style={{ color: 'grey' }}>Used {info.SEO!?.meta_description!?.length} characters. Recommended 150-160.</Text>
                                        })}
                                    />
                                    <Separator />
                                </>}
                                <Stack horizontal tokens={{ childrenGap: '10px' }} styles={{ root: { marginBottom: '10px' } }}>
                                    <DefaultButton
                                        primary={info.contentTypeID === ContentType.arcade}
                                        onClick={() => setContentType(ContentType.arcade, index)}
                                    >Arcade</DefaultButton>
                                    <DefaultButton
                                        primary={info.contentTypeID === ContentType.image}
                                        onClick={() => setContentType(ContentType.image, index)}
                                    >Image</DefaultButton>
                                    <DefaultButton
                                        primary={info.contentTypeID === ContentType.youtube}
                                        onClick={() => setContentType(ContentType.youtube, index)}
                                    >Youtube</DefaultButton>
                                </Stack>
                                {info.contentTypeID === ContentType.arcade &&
                                    <Stack horizontal tokens={{ childrenGap: '10px' }} verticalAlign='end'>
                                        <TextField
                                            styles={fieldStyle}
                                            label='Arcade Link'
                                            value={info.ArcadeLink}
                                            onChange={(_ev, newValue) => {
                                                editedItem.Info[index].ArcadeLink = newValue!
                                                setEditedItem({ ...editedItem })
                                            }}
                                        />
                                        {info.ArcadeLink &&
                                            <TooltipHost
                                                content={
                                                    <iframe
                                                        src={info.ArcadeLink.replace("/share", "")}
                                                        style={{ width: '100%', height: '100%', border: 'none', aspectRatio: '16/10', boxSizing: 'border-box' }}
                                                    ></iframe>
                                                }
                                                closeDelay={500}
                                            >
                                                <IconButton
                                                    iconProps={{ iconName: 'OpenInNewWindow' }}
                                                    title='Open in new window'
                                                    onClick={() => window.open(info.ArcadeLink, '_blank')}
                                                />
                                            </TooltipHost>}
                                    </Stack>
                                }
                                {info.contentTypeID === ContentType.image &&
                                    <Stack>
                                        <Label>Image</Label>
                                        {info.Image && <Image
                                            styles={{ image: { maxWidth: 300, maxHeight: 300, marginBottom: 10, objectFit: 'contain' } }}
                                            src={editedItem.Info[index].Image}
                                        />}
                                        <Stack horizontal tokens={{ childrenGap: '10px' }}>
                                            <DefaultButton
                                                text='Upload Image'
                                                disabled={!props.selectedId}
                                                onClick={() => uploadImageClick(index)}
                                            />
                                            <DefaultButton
                                                text='Remove Image'
                                                disabled={!info.Image}
                                                onClick={() => deleteImageClick(index)}
                                            />
                                        </Stack>
                                    </Stack>
                                }
                                {info.contentTypeID === ContentType.youtube &&
                                    <Stack horizontal tokens={{ childrenGap: '10px' }} verticalAlign='end'>
                                        <TextField
                                            styles={fieldStyle}
                                            label='Youtube Link'
                                            value={info.YoutubeLink}
                                            onChange={(_ev, newValue) => {
                                                editedItem.Info[index].YoutubeLink = newValue!
                                                setEditedItem({ ...editedItem })
                                            }}
                                        />
                                        {info.YoutubeLink &&
                                            <TooltipHost
                                                content={
                                                    <iframe
                                                        src={info.YoutubeLink.replace("https://youtu.be/", "https://www.youtube.com/embed/").replace("https://www.youtube.com/watch?v=", "https://www.youtube.com/embed/")}
                                                        style={{ width: '100%', height: '100%', border: 'none', aspectRatio: '16/10', boxSizing: 'border-box' }}
                                                    ></iframe>
                                                }
                                                closeDelay={500}
                                            >
                                                <IconButton
                                                    iconProps={{ iconName: 'OpenInNewWindow' }}
                                                    title='Open in new window'
                                                    onClick={() => window.open(info.YoutubeLink, '_blank')}
                                                />
                                            </TooltipHost>}
                                    </Stack>
                                }
                                <Separator />
                                <Stack horizontal tokens={{ childrenGap: '10px' }} styles={{ root: { marginBottom: '10px' } }}>
                                    <DefaultButton
                                        primary={mode === 'preview'}
                                        onClick={() => setMode('preview')}
                                    >Preview</DefaultButton>
                                    <DefaultButton
                                        primary={mode === 'editor'}
                                        onClick={() => setMode('editor')}
                                    >Edit</DefaultButton>
                                    <DefaultButton
                                        primary={mode === 'code'}
                                        onClick={() => setMode('code')}
                                    >HTML</DefaultButton>
                                </Stack>
                                {mode === 'code' &&
                                    <TextField
                                        styles={{
                                            root: {
                                                width: '100%',
                                                selectors: {
                                                    'textarea': {
                                                        whiteSpace: 'pre-wrap',
                                                        fontFamily: 'monospace',
                                                        fontSize: '10px',

                                                    },

                                                }
                                            }
                                        }}
                                        aria-label='Body'
                                        value={info.Body! || ''}
                                        multiline
                                        rows={10}
                                        onChange={(_ev, newValue) => {
                                            editedItem.Info[index].Body = newValue!
                                            setEditedItem({ ...editedItem })
                                        }}
                                    />}
                                {mode === 'editor' &&
                                    <Stack.Item>
                                        <HTMLEditor
                                            preset='academy'
                                            html={info.Body!}
                                            setHtml={(html) => {
                                                editedItem.Info[index].Body = html
                                                setEditedItem({ ...editedItem })
                                            }}
                                        />
                                    </Stack.Item>
                                }
                                {mode === 'preview' &&
                                    <Stack.Item
                                        styles={{
                                            root: {
                                                backgroundColor: 'rgb(245 245 245)',
                                                borderRadius: '.75rem',
                                                padding: '2rem',
                                                selectors: {
                                                    'ol': {
                                                        backgroundColor: 'rgb(234, 228, 241)',
                                                        listStyleType: 'decimal',
                                                        marginTop: '2rem',
                                                        marginRight: '1rem',
                                                        marginLeft: '1rem',
                                                        paddingTop: '1rem',
                                                        paddingBottom: '1rem',
                                                        paddingLeft: '2.5rem',
                                                        paddingRight: '2.5rem',
                                                        borderLeft: '2px solid rgb(116, 56, 182)',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        gap: '1rem'
                                                    }
                                                }
                                            }
                                        }}
                                    >
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(info.Body!)
                                            }} />
                                    </Stack.Item>
                                }

                                <Separator />
                                <Label>Tags</Label>
                                <Stack.Item>
                                    <Stack tokens={{ childrenGap: '2px' }}>
                                        {info.Tags?.map((tag, tagIndex) => {
                                            return (
                                                <Stack.Item key={tagIndex} styles={{ root: { width: '100%' } }}>
                                                    <Stack horizontal horizontalAlign="space-between">
                                                        <TextField
                                                            styles={fieldStyle}
                                                            value={tag.Tag}
                                                            onChange={(_ev, newValue) => {
                                                                editedItem.Info[index].Tags![tagIndex].Tag = newValue!
                                                                setEditedItem({ ...editedItem })
                                                            }}
                                                        />
                                                        <IconButton
                                                            iconProps={{ iconName: 'Delete' }}
                                                            onClick={() => {
                                                                editedItem.Info[index].Tags!.splice(tagIndex, 1)
                                                                setEditedItem({ ...editedItem })
                                                            }}
                                                        />
                                                    </Stack>
                                                </Stack.Item>
                                            )
                                        })}
                                        <Stack.Item>
                                            <TextField
                                                styles={fieldStyle}
                                                placeholder='Add tag'
                                                onKeyPress={(ev) => {
                                                    if (ev.key === 'Enter') {
                                                        editedItem.Info[index].Tags!.push({ Tag: ev.currentTarget.value })
                                                        setEditedItem({ ...editedItem })
                                                        ev.currentTarget.value = ''
                                                    }
                                                }}
                                            />
                                        </Stack.Item>
                                    </Stack>
                                </Stack.Item>
                            </Stack.Item>
                        )
                    })}
                </Stack>


            </Stack>
        </Stack >
    )
}

export default AcademyLesson;